import React, { useEffect, useReducer, useState} from "react"
import { useDispatch, useSelector} from "react-redux";
import InputSelect from "../InputSelect";
import { cn, setLowerCase, uniqueArray } from "../../core/utilities";
import { setFilterOptionsFeature } from "../../features/settings/settingsSlice";
import DateTimeRangeSelect from "../dateTime/DateTimeRangeSelect";
import  DataCollection  from "data-collection";



const setupFieldsOptions = ({ fieldOptions, data, excludeFieldName, clearFieldValues, isRiiotUser}) => {

    const filteredDataDc = new DataCollection(data);
      
    const allFieldsOptions = fieldOptions ? fieldOptions.filter((filterOption) => {

        
        if (isRiiotUser === false && filterOption.isRiiotUseOnly) {
            return false;
        }
        else
        {
            return true;    
        }
    })
        .map(filterOption => {
            const {isBoolean} = filterOption;
//console.log({filterOption});
        
        if (!excludeFieldName || excludeFieldName !== filterOption.name) {

            if (isBoolean) {
                
                filterOption.selectionOptions = [{ value: true, label: "Yes" }, { value: false, label: "No" }];
            }
            else {
                const distinct = uniqueArray(filteredDataDc.query().distinct(filterOption.name).filter(item => item));

                if (distinct.length > 0) {
                 
                    const selectionOptions = distinct.map((option) => { return { value: setLowerCase(option), label: option } });
                    filterOption.selectionOptions = selectionOptions;
                }
                else {
                    filterOption.selectionOptions = [];
                }
            }
        }
        if (clearFieldValues) {
            filterOption.value = filterOption.allowMultiples ?  [] : null;
        }
        return filterOption;
    }) : [];
    
    
   return allFieldsOptions;
    
} 


function fieldsOptionsReducer(state, action) {
    switch (action.type) {
        case "update": {
      
            const fieldsOptions = setupFieldsOptions({ data: action.filteredData, fieldOptions: action.payload, excludeFieldName: action.fieldName });
            return fieldsOptions;
        }
        case "clear": {
            const fieldsOptions = setupFieldsOptions({ data: action.completeData, fieldOptions: state, clearFieldValues: true,calledBy:49 });
            return fieldsOptions;
        }
        default:
            throw new Error("Not implemented");
    }
}

export default function Filter({ data, filterOptions, handleFilterDataChange, enabled, feature,autoFilterValues, defaultFilterOptions }) {
    const user = useSelector((state) => state.auth.user);
    const [filteredData, setFilteredData] = useState(data);
    const [fieldsOptions, dispatchFieldsOptions] = useReducer(fieldsOptionsReducer, setupFieldsOptions({
        fieldOptions: filterOptions.filters||filterOptions, //TO DO remove .filters after migration to ts
        isRiiotUser : user.isRiiotUser,
        data: filteredData     }));
    const [filters, setFilters] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    useEffect(() => {
        if (autoFilterValues) {
            const { name, label, value } = autoFilterValues;
            
            handleFilterChange(name, [{ label, value }])
        }
    }, [autoFilterValues])
    

   useEffect(() => {
        if (defaultFilterOptions) {
            defaultFilterOptions.forEach((item) => handleFilterChange(item.name, item.value));
       }
       handleClearFilter();  
    // !!! NOTE : we need to run just once, so the dependencies are intentionally left blank below
    
    }, [])
     

useEffect(() => {
    if (feature) {
        const filterOptions = fieldsOptions.reduce((filtered, field) => {
            
            if (field.value && field.value.length > 0) {
                filtered.push({ name: field.name, value: field.value });
            }
            return filtered;
        },[]);
     
     dispatch(setFilterOptionsFeature({ name: feature, filterOptions }));
  }
}, [dispatch, feature, fieldsOptions])


 const handleFilterChange = (filterName, filterValues) => {
        // console.log({filterName,filterValues})
        let filterChangeData = data;
        let filterType = "equals";

        let allFilters = filters.filter((filter) => { return filter.name !== filterName });

        const allFieldsOptions = fieldsOptions.map(field => {
            const { isDateTime, isBoolean } = field;
            if (field.name === filterName) {
                if (isDateTime) {
                    field.startValue = new Date(filterValues[0]).getTime();
                    field.endValue = new Date(filterValues[1]).getTime();
                    filterType = "dateRange";
                }
                else if (isBoolean) {
                    field.value = filterValues;
                    filterType = "boolean";
                }
                else {
                    field.value = filterValues;
                }
            }
            return field;
        });




        //let allFieldsOptions = fieldsOptions.filter((filter) => {return filter.name !== filterName})
        if (filterValues && typeof filterValues === "object") 
        {
            if (filterType === "dateRange") {
                let filterOption = {};
                filterOption.startDate = new Date(filterValues[0]).getTime();
                filterOption.endDate = new Date(filterValues[1]).getTime();
                allFilters.push({ name: filterName, value: filterOption,filterType });
            
            }
            else if (filterType === "boolean") { 
                let filterOption = {};
                filterOption[`${filterName}__in`] = [filterValues.value];
                allFilters.push({ name: filterName, value: filterOption,filterType });
                
            }
        
            else if (filterValues && filterValues.length > 0) {
                let filterOption = {};
                filterOption[`${filterName}__in`] = filterValues.map(item => item.label);
                allFilters.push({ name: filterName, value: filterOption,filterType });
            
            }
            else if (filterValues.label && filterValues.value) {
                let filterOption = {};
                filterOption[`${filterName}__in`] = [filterValues.label];
                allFilters.push({ name: filterName, value: filterOption,filterType });
                
            }
            
            //console.log({ allFilters,filterChangeData });
            setFilters(allFilters);
        }
        //Get unfilteredData and use it to filter again,
        allFilters.forEach((optFilter) => {
            const dc = new DataCollection(filterChangeData);
            
            if (optFilter.filterType === "dateRange") {
                const { startDate, endDate } = optFilter.value;
                filterChangeData = filterChangeData.filter((changeData) => new Date(changeData[optFilter.name]).getTime() >= startDate && new Date(changeData[optFilter.name]).getTime() <= endDate);
                //console.log({ optFilter,startDate,endDate });
            }
            else if (optFilter.filterType === "boolean") {
                /*
                filterChangeData = filterChangeData.filter((changeData) => {
                    const aa = changeData[optFilter.name];
                    console.log({ aa, filterValues, optFilter });
                    return changeData[optFilter.name] === optFilter.value
                });
                */
                filterChangeData = dc.query().filter(optFilter.value).values();
            }
            else {
                filterChangeData = dc.query().filter(optFilter.value).values();
            }
        });
        setFilteredData(filterChangeData);
        dispatchFieldsOptions({ type: "update", payload: allFieldsOptions, fieldName: filterName, filteredData: filterChangeData });
        //console.log("Debug:",{ filterChangeData,allFilters,filterName,filterValues });
        handleFilterDataChange(filterChangeData);
    };
    
    const handleClearFilter = () => {
        setFilteredData(data);
        setFilters([]);
        dispatchFieldsOptions({type: "clear", completeData: data});
       
        handleFilterDataChange(data);
        
    };

    const enableClearButton = filters ? filters.length > 0 : false;
    
     //console.log({data, fieldsOptions,filteredData,filters,filterOptions });

  return (enabled ?
    <section className='py-4 section-container'>
        <form className='grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2.5 gap-y-1.5' >
            {
                  fieldsOptions && fieldsOptions.map(((filterOption, index) => {
                      const { isDateTime  } = filterOption;



                      return ( isDateTime ? <DisplayDateTimeRangeSelect  filterOption={filterOption} handleFilterChange={handleFilterChange} index={index} key={index} /> :
                        <DisplayInputSelect filterOption={filterOption} handleFilterChange={handleFilterChange} index={index} key={index} />
                        );
                }))
            }
       
              <button
                  type='button'
                  onClick={handleClearFilter}
                  className={cn("mt-6 inline-flex justify-center px-6 text-sm border rounded-md border-brand py-2 text-primary w-36 text-opacity-20 hover:text-brand",{
                        "btn-primary text-secondary hover:text-primary": enableClearButton
                  })}
                  disabled={!enableClearButton}
              >
                    Reset Filter
              </button>
                    
               
         
        </form>

    </section> : null)
}

function DisplayInputSelect({filterOption,handleFilterChange,index}) {

    return <div key={`${index}_${filterOption.name}_div`} className={"min-w-56 max-w-[19rem] group"}>
    <label key={`${index}_${filterOption.name}_label`} htmlFor={filterOption.name} className="block mb-1 text-sm font-medium text-primary group-hover:font-bold">{filterOption.label}</label>
    <InputSelect value={filterOption.value}
        selectionOptions={filterOption.selectionOptions}
        setSelectedOption={(e) => handleFilterChange(filterOption.name, e)}
        allowMultiples={filterOption.allowMultiples}
        placeholder={filterOption.placeholder}
        name={filterOption.name}
        id={filterOption.name}
        key={`${index}_${filterOption.name}_IS`}
        className="text-sm font-medium group-hover:shadow-md" />
</div>;
}

function DisplayDateTimeRangeSelect({filterOption,handleFilterChange,index}) {
    
    return <div key={`${index}_${filterOption.name}_div`} className={"min-w-56 max-w-64"}>
         <label htmlFor={filterOption.name} className="block mb-1 text-sm font-medium text-primary">{filterOption.label}</label>
        <DateTimeRangeSelect
            noFutureDates={true}
            hourAriaLabel={"Hour"}
            monthAriaLabel={"Month"}
            yearAriaLabel={"Year"}
            clearAriaLabel={"Clear"}
            onChange={(e) => handleFilterChange(filterOption.name, e)}
            value={filterOption.value}
            className={"text-sm font-medium"}
            key={`${index}_${filterOption.name}_DTRS`}
            calendarClassName={"z-120"} rangeDivider={"  to  "} format={"dd-MMM-y HH:mm:ss"} showLeadingZeros={true} />
    </div>;
}
