import {ArrowsUpDownIcon, BarsArrowDownIcon, BarsArrowUpIcon} from "@heroicons/react/24/solid";
import React from "react";

export type TableSortOrder = "asc" | "desc";

type TableSort = {
  key: string;
  order: TableSortOrder;
};

type TableHeaderSortProps = {
  isSortable: boolean;
  headerKey: string;
  onSort: (key: string) => void;
  sort?:TableSort;
  sortOrder?: string;
  sortKey: string;
};

type SortHeaderProps = {
  onSort: (key: string) => void;
  headerKey: string;
  isSortKey: boolean;
  sortOrder?: string;
};

function TableHeaderSort({isSortable , headerKey, onSort, sort}: TableHeaderSortProps) {
  const isSortKey = headerKey === sort?.key;

  return isSortable ? <SortHeader onSort={onSort} headerKey={headerKey} isSortKey={isSortKey} sortOrder={sort?.order} /> : null;
}

function SortHeader({onSort, headerKey, isSortKey, sortOrder}: SortHeaderProps) {
  return <button onClick={() => onSort(headerKey)}>{isSortKey ? sortOrder === "asc" ? <BarsArrowUpIcon className="w-5 h-5 ml-1 currentColor" /> : <BarsArrowDownIcon className="w-5 h-5 ml-1 currentColor" /> : <ArrowsUpDownIcon className="w-4 h-4 ml-1 text-gray-700" />}</button>;
}

export default TableHeaderSort;
