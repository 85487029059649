import {useEffect, useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {IconType} from "react-icons";
import {CgInternal} from "react-icons/cg";
import {FaExternalLinkSquareAlt} from "react-icons/fa";
import {LuClipboardCopy} from "react-icons/lu";
import {Link} from "react-router-dom";
import { cn } from "../core/utilities";

interface ILinkProps {
  to: string;
  title: string;
}

interface IHRefProps {
  url: string;
  title: string;
}

export interface ILabelValuePairProps {
  label: string;
  value: string | boolean;
  valueColour?: string;
  link?: ILinkProps; // Internal link
  href?: IHRefProps; // External link
  copyable?: boolean;
  valueActions?: {
    icon: IconType;
    title?: string;
    iconColour?: string;
    onClick: () => void;
  }[]; // Array of value actions and their callbacks
}

interface ILabelValueListProps {
  pairs: ILabelValuePairProps[];
}

interface ICardSectionProps {
  title: string;
  data: ILabelValuePairProps[];
  warning?: string;
}

function DataCardSection({title, data, warning}: ICardSectionProps) {
  return (
    <section className="flex flex-col w-full p-4 rounded-sm shadow-md bg-other">
      <h2 className="mb-2 font-bold text-primary">{title}</h2>
      {warning ? <p className="mb-2 text-delete">{warning}</p> : <LabelValueList pairs={data} />}
    </section>
  );
}

// LabelValueList Component
function LabelValueList({pairs}: ILabelValueListProps) {
  return (
    <div className="space-y-2">
      {pairs.map((pair, index) => (
        <LabelValuePair key={index} {...pair} />
      ))}
    </div>
  );
}

// LabelValuePair Component
function LabelValuePair({label, value, valueColour, link, href, copyable, valueActions}: ILabelValuePairProps) {
  const hasIcons = copyable || (valueActions && valueActions.length > 0) || link || href;
  
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => setCopied(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  if (value == null || value === "") {
    return null; // Do not render if value is null or undefined
  }


  const renderLink = () => {
    if (href) {
      return (
        <a href={href.url} className={cn("font-bold text-secondary",valueColour)} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      );
    } else if (typeof value === "boolean") {
      return (
        <label className="inline-flex items-center">
          <input type="checkbox" checked={value} className="form-checkbox" readOnly />
        </label>
      );
    } else {
      return <span className={cn("font-bold text-secondary text-sm",valueColour,{"hover:opacity-50": hasIcons})}>{value}</span>;
    }
  };


  return (
    <div className="flex items-center justify-between">
      <span className={cn("text-sm font-normal text-secondary",{
       
      })}>{parseLabel(label)}:</span>

      <div className="w-1/2">
        <div className={cn("relative flex-1 group", {"cursor-pointer": hasIcons})}>
          {renderLink()}

          {hasIcons &&
            <div className={"absolute px flex space-x-2 rounded-md bg-background p-1 px-2 opacity-0 transition-opacity group-hover:opacity-100 -top-2 right-0 border"}>
              {valueActions?.map((action) => <ActionComponent key={action.title} icon={action.icon} onClick={action.onClick} title={action.title || ""} iconColour={action.iconColour} />)}
              
              {copyable && typeof value !== "boolean" && (
                <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
                  <button title={`Copy ${label} to clipboard`} className="transition-transform duration-300 transform hover:scale-[1.1]">
                    <LuClipboardCopy className="text-secondary hover:text-brand" />
                  </button>
                </CopyToClipboard>
              )}
              
              {href &&
                <a href={href.url} className={cn("font-bold text-secondary", valueColour )} target="_blank" rel="noopener noreferrer">
                  <FaExternalLinkSquareAlt className="transition-transform duration-300 transform text-secondary hover:scale-[1.1] hover:text-brand" title={href?.title} />
                </a>}
                
              {link  && <Link to={link.to} className={cn("font-bold text-secondary", valueColour )}>
                  <CgInternal className="transition-transform duration-300 transform text-secondary hover:scale-[1.1] hover:text-brand" title={link?.title} />
                </Link>}
            </div>
          }
        {copied && <span className="absolute p-1 text-[10px] transition-transform duration-300 -left-12 rounded-xl bg-background text-brand">Copied</span>}
        </div>
        
      </div>
    </div>
  );
}

function ActionComponent({icon, onClick, title, iconColour}: {icon: IconType; onClick: () => void; title: string; iconColour?: string}) {
  const IconComponent = icon;

  return <IconComponent className={`transform cursor-pointer text-secondary transition-transform duration-300 hover:${iconColour || "text-brand"} hover:scale-[1.1]`} onClick={onClick} title={title} />;
}

function parseLabel(label: string) {
  const parts = label.split(/(<sup>|<\/sup>)/g);
  return parts
    .map((part, index) => {
      if (part === "<sup>") {
        return <sup key={index} />;
      } else if (part === "</sup>") {
        return null;
      } else {
        return <span key={index}>{part}</span>;
      }
    })
    .filter(Boolean);
}

export default DataCardSection;
