import React from "react";
import { Layout, NoRecords } from "../../components";
import { isValidArrayWithData } from "../../core/utilities";

export default function VehicleInspection() {
  const isLoading = false;
  const vehicleInspections = [];
  const vehicleInspectionsFound = isValidArrayWithData(vehicleInspections);

  return isLoading ? (
      <DisplayVehicleInspection vehicleInspections={[]} isLoading={isLoading} />
  ) : vehicleInspectionsFound ? (
    <DisplayVehicleInspection vehicleInspections={vehicleInspections} />
  ) : (
    <NoRecords title="Vehicle Inspections" message="No Vehicle Inspections found." />
  );
}

function DisplayVehicleInspection({ vehicleInspections, isLoading = false }) {
  
  console.log("vehicleInspections", vehicleInspections);
  
  return (
    <Layout isLoading={isLoading} loadingText="Loading Vehicle Inspections">
      <div className="rounded-sm shadow-lg bg-other col-span-full xl:col-span-8">
        <header className="px-5 py-4 ">
          <h2 className="font-semibold text-primary">Vehicle Inspection</h2>
        </header>
      </div>
    </Layout>
  );
}
