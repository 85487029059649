import {toBoolean} from "../core/utilities";
import clientConfig from "./client.config";

interface ILatLngCoOrdinates {
  lat: number;
  lng: number;
}

const systemConfiguration = () => {
  let defaultZoom = 11;
  let googleDefaultCenter: ILatLngCoOrdinates;
  if (clientConfig.googleMap?.defaultCenter) {
    const coOrdinates = clientConfig.googleMap.defaultCenter.split(",");
    if (coOrdinates?.length > 0) {
      googleDefaultCenter = {lat: Number(coOrdinates[0]), lng: Number(coOrdinates[1])};
    } else {
      googleDefaultCenter = {lat: -25.42695, lng: 27.91085};
      defaultZoom = 11;
    }
  } else {
    googleDefaultCenter = {lat: -25.42695, lng: 27.91085};
    defaultZoom = 11;
  }

  return {
    maps: {
      google: {
        defaultCenter: googleDefaultCenter,
        defaultZoom: defaultZoom,
        apiKey: clientConfig.googleMap.apiKey,
        libraries: ["marker","places"]
      }
    },
    entity: {
      acronym: clientConfig.customer.acronym,
      uuId: clientConfig.customer.uuId,
      name: clientConfig.customer.name,
      dummyEmail: clientConfig.customer.dummyEmail || "user@riiottech.co.za"
    },
    backend: {
      baseUrl: clientConfig.system.backEndUrl || "NOT.DEFINED.SET.RIIOT.ENV",
      apiRoot: clientConfig.system.apiRoot || "/api/v1.0"
    },
    features: {
      camerasInstalled: toBoolean(clientConfig.camerasInstalled)
    },
    logRocket: {appCode: clientConfig.logRocketAppCode || "2qwtrz/fleet-dashboard"}
  };
};

export default systemConfiguration();
