import React from "react";
import Map from "./Map";



 function GoogleMap({ vehicles }) {


    return <div className="overflow-hidden h-[70vh]"><Map vehicles={vehicles}/></div>;
}
  
export default React.memo(GoogleMap)