import React, { useEffect, useState } from "react";
import {  useParams  } from "react-router-dom";
import Api from "../../api";
import { AlertDialog, ConfirmationDialog, Layout, OnError } from "../../components";
import { LiveDataIcon, WearAndTearIcon } from "../../components/icons";
import VehicleLiveMovement from "../../components/vehicle/VehicleLiveMovement";
import VehicleOverview from "../../components/vehicle/VehicleOverview";
import VehicleWearTear from "../../components/vehicle/VehicleWearTear";
import UserRoleAccess from "../../constant/userRoleAccess";
import { getNameFromUrlPath } from "../../core/utilities";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import VehicleDetailsSkeleton from "../../skeletons/VehicleDetailsSkeleton";
import VehicleSmsTracking from "../../components/vehicle/VehicleSmsTracking";
import Breadcrumb from "../../components/Breadcrumb";
import InfoDialog from "../../components/modal/InfoDialog";
import { useSelector } from "react-redux";
import { selectPreviousPage } from "../../features/settings/settingsSlice";





const foregroundRunReset = () => {
  return { running: false }
}

const dialogReset = () => {
  return {show: false}
}

const confirmReset = () => {
  return {confirm: false}
}

export default function VehicleDetails() {
  // need to fetch data for this vehicle based on the id passed in the params
  const { vehicleId } = useParams();
  const { hasAccess: hasAccessToDevices } = useHasAccessToFeature(UserRoleAccess.deviceView);
  const { hasAccess: hasAccessToListSmsTrack } = useHasAccessToFeature(UserRoleAccess.vehicleSmsTrackList);
  const { hasAccess: hasAccessToCreateSmsTrack } = useHasAccessToFeature(UserRoleAccess.vehicleSmsTrackCreate);
  const { hasAccess: hasAccessToSetClearMobility } = useHasAccessToFeature(UserRoleAccess.vehicleMobilitySetClear);


  const { data: vehicle, isLoading, isError, error, refetch: refetchVehicle } = Api.vehicles.useGetVehicleQuery(vehicleId);
  const [triggerSmsTracking, vehicleSmsTracking] = Api.vehicles.useLazyGetVehicleSmsTrackingQuery();
  const navigationPath = useSelector(selectPreviousPage);
 

  useEffect(() => {
    if (hasAccessToListSmsTrack) {
      triggerSmsTracking(vehicleId);
    }
  }, [hasAccessToListSmsTrack, triggerSmsTracking, vehicleId]);

  const fromLocation = {
    path: navigationPath.previous || "/vehicles",
    name: getNameFromUrlPath(navigationPath.previous ) || "Vehicles"
  };


  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <VehicleDetailsSkeleton isLoading={isLoading} />
  ) : (
        <DisplayVehicleDetails vehicle={vehicle} hasAccessToCreateSmsTrack={hasAccessToCreateSmsTrack}
          hasAccessToListSmsTrack={hasAccessToListSmsTrack}
          hasAccessToSetClearMobility={hasAccessToSetClearMobility}
          hasAccessToDevices={hasAccessToDevices}
          vehicleSmsTracking={vehicleSmsTracking.data}
          triggerSmsTracking={triggerSmsTracking}
          refetchVehicle={refetchVehicle}
          fromLocation={fromLocation} />
  );
}

function DisplayVehicleDetails({ vehicle, hasAccessToCreateSmsTrack,hasAccessToListSmsTrack, hasAccessToSetClearMobility, hasAccessToDevices, triggerSmsTracking,vehicleSmsTracking,refetchVehicle, fromLocation }) {
  
  const [toggleLiveDataView, setToggleLiveDataView] = useState(false);
  const [toggleWearTearView, setToggleWearTearView] = useState(false);
  const [useDefaultView, setUseDefaultView] = useState(true);
  const [toggleOverview, setToggleOverview] = useState(vehicleSmsTracking && hasAccessToListSmsTrack ? false : true);
  const [toggleSmsTrackingView, setToggleSmsTrackingView] = useState(vehicleSmsTracking && hasAccessToListSmsTrack ? true : false);
  const [currentProcess, setCurrentProcess] = useState(null);
  const [processTrigger, setProcessTrigger] = useState({ name: "", initiate: false });
  const [commandConfirmation, setCommandConfirmation] = useState(confirmReset());
  const [commandConfirmCycle, setCommandConfirmCycle] = useState({});
  const [alert, setAlert] = useState(dialogReset());
  const [info, setInfo] = useState(dialogReset());
  const [fgRun, setFgRun] = useState(foregroundRunReset());
  const [apiCreateVehicleSmsTrack] = Api.vehicles.usePostCreateVehiclesSmsTrackingMutation();
  const [apiUpdateVehicleMobility] = Api.vehicles.usePostUpdateVehicleMobilityMutation();
  const vehicleName = `${vehicle?.make} ${vehicle?.model} ${vehicle?.veh_registration_no ? `(${vehicle?.veh_registration_no})` : ""}`.trim();

  useEffect(() => {
    async function startSmsTracking() {
      await createSmsTrack(vehicle.id);
    }
    async function startMobilityChange() {
      await changeVehicleMobility(vehicle.id, !vehicle.is_immobile);
    }
    const { confirmState, confirmId } = commandConfirmCycle;
    switch (confirmState) {
      case "ConfirmationRequired":
        if (confirmId === "vehicleSmsTracking") {
          setCommandConfirmation({ confirm: true, message: "Are you sure you want to initiate Vehicle Sms Tracking?" });
        }
        else {
          setCommandConfirmation({ confirm: true, message: `Are you sure you want to ${vehicle.is_immobile ? "enable": "disable"} vehicle?` });
        }
        setCommandConfirmCycle({confirmId,confirmState: "AwaitingConfirmation"});
        break;
      case "AwaitingConfirmation":
        break;

      case "NegativeConfirmation":
        setCommandConfirmCycle({});
        break;

      case "PositiveConfirmation":
        if (confirmId === "vehicleSmsTracking") {
          startSmsTracking();
        }
        else if (confirmId === "vehicleMobilitySetClear") {
          startMobilityChange();
        }
        break;

      default:
        break;
    }
  }, [commandConfirmCycle]);

  useEffect(() => {
    if (useDefaultView && toggleOverview && hasAccessToListSmsTrack && vehicleSmsTracking) {
      setToggleOverview(false);
      setToggleSmsTrackingView(true);
    }
    
  }, [hasAccessToListSmsTrack, toggleOverview, useDefaultView, vehicleSmsTracking])



  
  const handleProcessComplete = (success) => {
    setCurrentProcess(null); // Reset current process
    setProcessTrigger({ name: "", initiate: false }); // Reset trigger
    if (success) {
      refetchVehicle();
        console.log("Process completed successfully!");
    } else {
        console.log("Process failed or was not confirmed.");
    }
  };
  
  const initiateAsyncProcess = (process, name) => { 
      
    setProcessTrigger({ name, initiate: true, confirmationRequired:true });
    setCurrentProcess(()=> process);
    //setCurrentProcess(process);
  }

  const changeVehicleMobility = async (id,set) => {
    setFgRun({ running: true, message: "Changing Vehicle On the Road Flag" });
    handleUpdateVehicleMobilityChange(id,set)
      .then((results) => {
        console.log({results})
        setFgRun(foregroundRunReset());
        setInfo({ show: true, message: "On the Road Flag change has been initiated", initiatedFrom: "changeVehicleMobility" , id });
        
      })
      .catch((error) => {
        setFgRun(foregroundRunReset());
        setAlert({ show: true, message: error.message });
      });
  };

  const createSmsTrack = async (id) => {
    setFgRun({ running: true, message: "Initiating Vehicle Sms Tracking" });
    handleCreateSmsTrack(id)
      .then((results) => {
        console.log({results})
        setFgRun(foregroundRunReset());
        setInfo({ show: true, message: "Sms Vehicle Tracking has been initiated", initiatedFrom: "createSmsTrack" , id });
        
      })
      .catch((error) => {
        setFgRun(foregroundRunReset());
        setAlert({ show: true, message: error.message });
      });
  };

  const handleInfoDialogClose = () => {
    const { initiatedFrom, id } = info;
    
    if (initiatedFrom === "createSmsTrack") {
      triggerSmsTracking(id);
    }
    else if (initiatedFrom === "changeVehicleMobility") {
      refetchVehicle();
    }
    setInfo(dialogReset());
  }

  const handleVehicleMobilityChange = () => {
    setCommandConfirmCycle({ confirmState: "ConfirmationRequired",confirmId: "vehicleMobilitySetClear" });
}

  const handleCreateSmsTrackInitiate = () => {
    setCommandConfirmCycle({ confirmState: "ConfirmationRequired",confirmId: "vehicleSmsTracking" });
  };

  const handleUpdateVehicleMobilityChange = async (id,set) => {
    new Promise((resolve, reject) => {
      try {
        apiUpdateVehicleMobility({id,set})
          .unwrap()
          .then((apiResponse) => {
            resolve(apiResponse);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleCreateSmsTrack = async (id) => {
    new Promise((resolve, reject) => {
      try {
        apiCreateVehicleSmsTrack(id)
          .unwrap()
          .then((apiResponse) => {
            resolve(apiResponse);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };


  const handleNegativeConfirmation = () => {
    const { confirmState, confirmId } = commandConfirmCycle;
    setCommandConfirmation(confirmReset());
    if (confirmState === "AwaitingConfirmation") {
      setCommandConfirmCycle({confirmState: "NegativeConfirmation", confirmId});
    }
  };

  const handlePositiveConfirmation = () => {
    const { confirmState, confirmId } = commandConfirmCycle;
    setCommandConfirmation(confirmReset());
    if (confirmState === "AwaitingConfirmation") {
      setCommandConfirmCycle({confirmState: "PositiveConfirmation", confirmId});
    }
  };

  const breadcrumbItems = () => {
    const items = [];
    if (fromLocation && fromLocation.name) {
      items.push({ name: fromLocation.name, path: fromLocation.path }); 
      
    }
    else {
      items.push({ name: "Vehicles", path: "/vehicles" });
    }
    items.push({ name: `Vehicle ${vehicleName}`, path: `/vehicles/${vehicle.id}` });

    return items;
  }

  const togglePageView = (type) => {
    setUseDefaultView(false);
    if (type === "liveDataView") {
      setToggleLiveDataView(true);
      setToggleWearTearView(false);
      setToggleSmsTrackingView(false);
      setToggleOverview(false);
    } else if (type === "WearTearView") {
      setToggleWearTearView(true);
      setToggleLiveDataView(false);
      setToggleSmsTrackingView(false);
      setToggleOverview(false);
    } else if (type === "smsTrackingView") {
      setToggleSmsTrackingView(true);
      setToggleLiveDataView(false);
      setToggleWearTearView(false);
      setToggleOverview(false);
    } else {
      setToggleOverview(true);
      setToggleLiveDataView(false);
      setToggleWearTearView(false);
      setToggleSmsTrackingView(false);
    }
  };

  
  const displayMode = toggleSmsTrackingView ? "Sms Tracking" : toggleLiveDataView ? "Live Data" : toggleOverview ? "Overview" : "Wear And Tear";

  // console.log({ vehicleSmsTracking, toggleSmsTrackingView,toggleOverview });

  return (
    <Layout isForegroundProcess={fgRun.running} loadingText={fgRun.message}  processTrigger={processTrigger} onProcessComplete={handleProcessComplete} userAsyncProcess={currentProcess} >
      <header className="flex flex-row justify-between pr-4 rounded-sm shadow-lg bg-other col-span-full xl:col-span">
      <Breadcrumb items={breadcrumbItems()} />

      
      <div className="flex flex-row items-center gap-2 px-5 py-4 font-semibold text-primary">
              <p className="inline-flex items-center group">
                  Vehicle {displayMode} 
              </p>
            </div>
        <div className="flex flex-col justify-start gap-4 md:items-center md:flex-row">
          {hasAccessToCreateSmsTrack && (
            <div className="flex">
              <button
                type="button"
                onClick={handleCreateSmsTrackInitiate}
                className="inline-flex items-center gap-4 px-3 mx-3 text-xs border rounded-md border-warn text-primary hover:text-warn py-1.5"
              >
                Initiate Sms Track
              </button>
            </div>
          )}
          {hasAccessToSetClearMobility && (
            <div className="flex">
              <button
                type="button"
                onClick={handleVehicleMobilityChange}
                className="inline-flex items-center gap-4 px-3 mx-3 text-xs border rounded-md border-delete text-primary hover:text-delete py-1.5"
              >
                {vehicle.is_immobile ? "Enable" : "Disable"}
              </button>
            </div>
          )}
        </div>
        {/* buttons */}
        <div className="flex flex-col gap-4 md:items-center md:flex-row">
          {(toggleLiveDataView || toggleWearTearView || toggleSmsTrackingView) && (
            <div>
              <button onClick={() => togglePageView()} className="inline-flex items-center justify-center w-full gap-2 btn-primary">
                Vehicle Overview
              </button>
            </div>
          )}
          {!toggleLiveDataView ? (
            <div>
              <button type="button" onClick={() => togglePageView("liveDataView")} className="inline-flex items-center justify-center w-full gap-2 btn-primary">
                <LiveDataIcon classes="fill-current" /> Live Movement Data
              </button>
            </div>
          ) : null}
          {!toggleSmsTrackingView && vehicleSmsTracking && hasAccessToListSmsTrack ? (
            <div>
              <button type="button" onClick={() => togglePageView("smsTrackingView")} className="inline-flex items-center justify-center w-full gap-2 btn-primary">
                <LiveDataIcon classes="fill-current" /> Sms Tracking
              </button>
            </div>
          ) : null}
          {/* TODO  Once we have Wear & Tear data enable the button below  */}
          {false ? (
            <div>
              <button disabled type="button" onClick={() => togglePageView("WearTearView")} className="inline-flex items-center justify-center w-full gap-2 btn-primary">
                <WearAndTearIcon classes="fill-current" /> Wear And Tear
              </button>
            </div>
          ) : null}
        </div>
      </header>

      {vehicle && toggleOverview && !toggleLiveDataView && !toggleWearTearView ? <VehicleOverview vehicle={vehicle} onAsyncProcessStart={initiateAsyncProcess} hasAccessToDevices={hasAccessToDevices} /> : null}
      {toggleLiveDataView ? <VehicleLiveMovement /> : null}
      {toggleWearTearView ? <VehicleWearTear /> : null}
      {toggleSmsTrackingView ? <VehicleSmsTracking vehicle={vehicle}  vehicleSmsTracking={vehicleSmsTracking} hasAccessToDevices={hasAccessToDevices} /> : null}
      <ConfirmationDialog show={commandConfirmation.confirm} onNo={handleNegativeConfirmation} onYes={handlePositiveConfirmation} confirmationMessage={commandConfirmation.message} />
      <AlertDialog show={alert.show} alertMessage={alert.message} onClick={() => setAlert(dialogReset())} />
      <InfoDialog show={info.show} infoMessage={info.message} onClick={handleInfoDialogClose} />
    </Layout>
  );
}
