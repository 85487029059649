import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import { RBAC } from "rbac";
import { selectCurrentUser } from "../features/auth/authSlice";
import { RoleFeature } from "../models/access.model";
import { User } from "../models/user.model";


function isUserSignedIn(user:User) {
    const {isSignedIn,token } = user;
    return token && isSignedIn ? true : false;
  }

async function doesUserHaveAccess(user:User,roleFeature:RoleFeature) : Promise<boolean> {

  if (roleFeature && user.isSignedIn && user?.role) {
    

    const role = user.role;
      const { roles, permissions, grants } = role;
      const rbac = new RBAC({ roles, permissions, grants });
      await rbac.init();
      const userCan = await rbac.can(roles[0], roleFeature.action, roleFeature.name);
     //console.log({ role, roles, permissions, grants, roleFeature,userCan });

      return userCan;
    }
    else if (user && user.isSignedIn && !roleFeature) {
      return true;
  }
    else
    {

      return false;
    }
  }

function useHasAccessToFeature(roleFeature?: RoleFeature) {

  const user = useSelector(selectCurrentUser);
  const [isSignedIn] = useState(isUserSignedIn(user));
  // change the default to be null
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);
    
  useEffect(() => {
 
    if (isSignedIn) {
      if (roleFeature) {
        doesUserHaveAccess(user, roleFeature).then(haveAccess => {
          setHasAccess(haveAccess);
        });
      }
      else {
        setHasAccess(true);
      }
    
    }
  }, [roleFeature, isSignedIn, user]);
  if (roleFeature?.name === "device") {
   // console.log({ hasAccess, isSignedIn, roleFeature });
  }
  return { hasAccess, isSignedIn };
}

export default useHasAccessToFeature;