import React   from "react";
import DataCardSection, { ILabelValuePairProps } from "../../DataCardSection";

interface ISimCardManufactureDataCardSectionProps {
  active: boolean;
  id: string;
  networkStatus: string;
  dataBalance: string;
  airtimeBalance: string;
  smsBalance: string;
}

function SimCardManufactureDataCardSection({ id, networkStatus, airtimeBalance, dataBalance, smsBalance }: ISimCardManufactureDataCardSectionProps) {
  const title = "SIM Manufacture Information";
  console.log({ airtimeBalance: airtimeBalance, dataBalance: typeof dataBalance, smsBalance: typeof smsBalance });
  const sectionData: ILabelValuePairProps[] = [
    {
      label: "Id",
      value: id
    },
    {
      label: "Network Status",
      value: networkStatus,
      valueColour: networkStatus === "ACTIVE" ? "text-brand" : "text-delete"
    },
    {
      label: "Data Balance <sup>(MB)</sup>",
      value: dataBalance,
      valueColour: dataBalance === "0" ? "text-delete" : "text-brand"
    },
    {
      label: "SMS Balance",
      value: smsBalance,
      valueColour: smsBalance === "0" ? "text-delete" : "text-brand"
    },
    {
      label: "Airtime Balance",
      value: airtimeBalance,
      valueColour: airtimeBalance === "0" ? "text-delete" : "text-brand"
    }
  ];

  return (
    <div className="flex">
      <DataCardSection title={title} data={sectionData} />
    </div>
  );
}

export default SimCardManufactureDataCardSection;
