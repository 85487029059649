import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import React from "react";

import { createRoot } from "react-dom/client";
import {Provider} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PersistGate} from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import {persistor, store} from "./store/store";

/*
Bugsnag.start({
  apiKey: "59b4a11cda08885a289efcf9e5abd797",
  plugins: [new BugsnagPluginReact()],
  appVersion: "2.0.01",
  beforeSend: (report) => {
    report.updateMetaData("LogRocket", {
      sessionURL: LogRocket.sessionURL
    });
  }
});
*/

//BugsnagPerformance.start({ apiKey: "59b4a11cda08885a289efcf9e5abd797" });

//const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

Bugsnag.start({
  apiKey: "b17052cba8b89a4ac40a02278694453e",
  plugins: [new BugsnagPluginReact()]
});
BugsnagPerformance.start({ apiKey: "b17052cba8b89a4ac40a02278694453e" });


const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(<React.StrictMode>
  {/*  <ErrorBoundary> */}
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  {/*</ErrorBoundary> */}
</React.StrictMode>);


// render(
//   ,
//   document.getElementById("root")
// );


