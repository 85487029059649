import React, { useState } from "react";

function CsvExportButton({ exportData, filename }) {
  const [isExporting, setIsExporting] = useState(false);
  const [error, setError] = useState(null);

  const handleOnClick = async () => {
    if(!exportData || exportData?.length === 0) {
      setError('No data to export');
      setTimeout(() => setError(null), 3000);
      return;
    }
    setIsExporting(true);
    const csvData = await jsonToCSV(exportData);
    downloadCSV(csvData);
    setIsExporting(false);
  };

  const jsonToCSV = async (jsonData) => {
    const csvRows = [];
    const headers = Object.keys(jsonData?.[0]);
    csvRows.push(headers.join(','));

    for (const row of jsonData) {
      const values = headers?.map(header => `${('' + row[header]).replace(/"/g, '""')}`);
      csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
  };

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${filename}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex items-center justify-end p-2 group">
      {error && <span className="inline-flex pr-4 text-red-500">{error}</span>}
      {isExporting ? (
        <span className="inline-flex pr-4 text-brand">
          <svg
            className="mr-2 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 12a9 9 0 1 1-6.219-8.56" />
          </svg>
          Exporting CSV, please wait
        </span>
      ) : (
        <button
          onClick={handleOnClick}
          className="border btn-secondary group-hover:btn-primary"
          disabled={isExporting}
        >
          Generate CSV
        </button>
      )}
    </div>
  );
}

export default CsvExportButton;