
const SystemConstant = {
    Google: {
      MapApiKey:   "AIzaSyAlWS3Mpe63wFNn-oUqjspjNfyMhLejzaA"
    },
    Riiot: {
        Api: { Key: "C1E60740-54C4-4102-BC36-0B7A04BACE6B" }
    },
    CameraFeeds: { NoOfDaysToInclude: 20 },
     Dashboard: {
         Polling: { Interval: 15000 },
         Realtime: {
             MaximumDaysToInclude: 20,
             LiveOnlyDataHoursToInclude: 24
         },
         CameraFeeds: {MaximumDaysToInclude: 20}
     },
     Devices: {
         SmsAlerts: { Polling: { Interval: 15000 } },
         TripPoints: {NoOfDaysToInclude: 30}
     },
     Vehicles: {
         LiveMovement: { Polling: { Interval: 15000 } },
         
    },
    LogRocket: {
        AppCode: "2qwtrz/fleet-dashboard"
    },
}  

export default SystemConstant;
