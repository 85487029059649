import htmlParse from "html-react-parser";
import {Fragment} from "react";
import Api from "../../../api";
import DeviceConnectivityDataCardSection from "../../../components/device/dataCardSection/DeviceConnectivityDataCardSection";
import DeviceDataCardSection from "../../../components/device/dataCardSection/DeviceDataCardSection";
import BoxArchiveIcon from "../../../components/icons/BoxArchiveIcon";
import SimCardDataCardSection from "../../../components/simCard/dataCardSection/SimCardDataCardSection";
import Timeline from "../../../components/Timeline";
import VehicleDataCardSection from "../../../components/vehicle/dataCardSection/VehicleDataCardSection";
import {dateTimeDisplay} from "../../../core/utilities";

const DeviceOverview = ({device,  hasAccessToUnlinkVehicle}) => {
  const {data: deviceLogs = []} = Api.devices.useGetDeviceLogsQuery(device.id);
  const activeAlertsFound = false; // device.activeAlerts;

  const deviceLogsTimeLine = () => {
    return deviceLogs?.map(deviceLog => {
      const showOldValue = deviceLog.column_name === "primary_sim_iccid" || deviceLog.column_name === "primary_sim_imsi" ? true : false;
      const prefix = deviceLog.column_name === "primary_sim_iccid" ? "Iccid" : deviceLog.column_name === "primary_sim_imsi" ? "Imsi" : "";
      return {
        date: dateTimeDisplay(deviceLog.change_date),
        title: `${prefix} ${deviceLog.new_value}`.trim(),
        description: showOldValue ? `Old value was ${deviceLog.old_value}` : ""
      };
    });
  };


  //console.log({device,deviceNotInstalled: deviceInstalled})
  console.log({hasAccessToUnlinkVehicle, device});

  return (
    <Fragment>
      <div className="grid grid-cols-1 gap-4 p-1 md:grid-cols-2 2xl:grid-cols-4">
        <DeviceDataCardSection active={device.active} imei={device?.id} hardwareVersion={device?.device_type} firmwareName={device.firmware_name} serialNumber={device?.serial_number} isDeviceInstalled={true} />
        <SimCardDataCardSection active={device.primary_sim_card_active} apn={device.apn} iccid={device.primary_sim_iccid} imsi={device.primary_sim_imsi} msisdn={device.primary_sim_msisdn} id={device.primary_sim_external_id} isSimCardInstalled={device.primary_sim_iccid ? true : false} enableSimLink={true} />
        <DeviceConnectivityDataCardSection lastReported={device?.lastReported} dataTimestamp={device?.lastDataTimestamp} lastSeen={device?.lastSeen} manufactureStatus={device.manufacturer_api_activity_status} manufacturerApiLastOnline={device.manufacturerApiLastOnline} manufacturerApiLastSync={device.manufacturerApiLastSync} manufacturerApiFirstLogin={dateTimeDisplay(device.manufacturerApiFirstLogin.dateTime)} m />
        <VehicleDataCardSection id={device.vehicle_id} active={device.active} vehicleRegistrationNumber={device.vehicleRegistrationNumber} manufactureYear={device.vehicle_year} engineNumber={device.vehicle_engine_number} vehicleMakeAndModel={device.vehicleMakeAndModel} vin={device.vin} batteryVoltage={device.vehicle_battery_voltage} enableVehicleLink={true} canUnlinkDevice={hasAccessToUnlinkVehicle} />
      </div>
      <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
        {activeAlertsFound ? (
          <div className="section-container vertical-auto-scrollbar max-h-60 lg:col-span-3" tabIndex="3">
            <h2 className="font-semibold">Active Alerts</h2>
            <ul className="ml-8 list-disc">
              {device.activeAlerts?.map((alert, index) => (
                <li key={index}>{htmlParse(`${alert.description} on <i>${dateTimeDisplay(alert.data_timestamp)}</i>`)}</li>
              ))}
            </ul>
          </div>
        ) : null}

        <div className="section-container lg:col-span-3" tabIndex="4">
          <h2 className="font-semibold">Device Logs</h2>
          <Timeline items={deviceLogsTimeLine()} />

          {deviceLogs.length === 0 ? (
            <p className="inline-flex gap-2">
              {" "}
              <BoxArchiveIcon classes={"fill-current"} /> No Logs
            </p>
          ) : null}
        </div>
      </section>
    </Fragment>
  );
};

export default DeviceOverview;
