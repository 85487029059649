import React from "react";

import Layout from "./Layout";
import PrimaryHeader from "./header/PrimaryHeader";
import EmptyFolderIcon from "./icons/EmptyFolderIcon";

type NoRecordsProps = {
  title: string;
  message?: string;
  children?: React.ReactNode;
};

function NoRecords({ title, message, children }: NoRecordsProps) {
  const displayMessage = message ? message : "No records found";

  return (
    <Layout>
      <PrimaryHeader title={title}>{children}</PrimaryHeader>

      <section className="flex justify-center ">
        <div className="flex flex-col items-center p-4 mt-20 rounded-md w-96">
          <button onClick={() => window.location.reload()}>
            <EmptyFolderIcon classes="h-24 w-24 fill-primary" />
          </button>
          <span className="text-lg text-center capitalize text-primary">
            {displayMessage}
            <br></br>
            <button onClick={() => window.location.reload()}>Refresh</button> the page, We'll look again
          </span>
        </div>
      </section>
    </Layout>
  );
}

export default NoRecords;
