import React, { ReactNode } from "react"
import { Link } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import { BreadcrumbsItem } from "../../models/other.model";

type TitleProps = {
  title?: string,
  titleLinkTo?: string
}

type Props = {
  breadcrumbItems?:BreadcrumbsItem[]
  children: ReactNode,
  
} & (TitleProps)

export default function PrimaryHeader({ title, children, titleLinkTo, breadcrumbItems }:Props) {
    
  return (
    <header className="header-justify-between">
      <div className="inline-flex p-2">
        {breadcrumbItems ? <Breadcrumb items={breadcrumbItems} /> : null}
        <h2 className="font-semibold text-primary">
          {titleLinkTo ? <Link to={titleLinkTo}>{title}</Link> : title}
        </h2>
      </div>
      {children}
    </header>
  );
}
