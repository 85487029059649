import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {PageFilter, Pagination} from "../../../components";
import Filter from "../../../components/filter/Filter";
import {SystemTables, Table} from "../../../components/table";
import SystemFeatures from "../../../constant/systemFeatures";
import {getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature, setIsFilterFeature, setPerPageFeature} from "../../../features/settings/settingsSlice";
import FilterOptions from "../../../filter/FilterOptions";
import usePageFilter from "../../../hooks/usePageFilter";

const PROCESS_FEATURE = SystemFeatures.SIM_CARDS_DATA_DAILY_USAGE;

function SimCardDataDailyUsageView({dataDailyUsage}) {
  return <DisplaySimCardDataDailyUsage simCardUsageLogs={dataDailyUsage} />;
}

function DisplaySimCardDataDailyUsage({simCardUsageLogs}) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(simCardUsageLogs);
  const [isFilter] = useState(currentIsFilter);
  const [isFiltered, setIsFiltered] = useState(false);

  const {numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick} = usePageFilter({
    displayData,
    currentItemsPerPage
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setDisplayData(simCardUsageLogs);
  }, [simCardUsageLogs]);

  useEffect(() => {
    dispatch(setPerPageFeature({name: PROCESS_FEATURE, perPage: itemsPerPage}));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({name: PROCESS_FEATURE, isFilter}));
  }, [dispatch, isFilter]);

  const handleFilterDataChange = filteredData => {
    // console.log({ filteredData });
    setIsFiltered(filteredData.length !== simCardUsageLogs.length);
    if (filteredData.length > 0) {
      setDisplayData(filteredData);
    } else {
      setDisplayData(simCardUsageLogs);
    }
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getCameraFeedsOptions();
  };

  console.log("Debug:", {simCardUsageLogs});

  return (
    <React.Fragment>
      <Filter enabled={isFilter} data={simCardUsageLogs} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} feature={PROCESS_FEATURE} defaultFilterOptions={defaultFilterOptions} />

      <section className="flex flex-col gap-8 ">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage} recordsName={"Sim Cards Data"} isFiltered={isFiltered} totalNumberOfItems={totalNumberOfItems} />

        <Table data={displayData} pagingInfo={{dataPerPage: itemsPerPage, currentPage}} tableColumns={SystemTables.simCardDataDailyUsage} initialSort={{key: "timestamp", order: "desc"}} />
        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} currentPage={currentPage} />
      </section>
    </React.Fragment>
  );
}

export default SimCardDataDailyUsageView;
