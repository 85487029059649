import Api from "../../api";
import { Layout, PrimaryHeader, NoRecords, OnError } from "../../components";
import VehiclesSkeleton from "../../skeletons/VehiclesSkeleton";
import { isValidArrayWithData } from "../../core/utilities";
import { Top10LineChart,  Top10BarChart } from "../../components/dashboard/fleet-statistics";


function DashboardFleetStats() {
    const { data: stats, isLoading, isError, error } = Api.vehicles.useGetVehicleMakeYearMakeModelCountsQuery();
    const statsFound = stats?.vehicleMakes ? isValidArrayWithData(stats.vehicleMakes) : false;


    
    // console.log({stats, isLoading, isError, error, statsFound});
    return isError ? (
        <OnError error={error} />
    ) : isLoading ? (
        <VehiclesSkeleton isLoading={isLoading} vehicleCount={15} />
    ) : statsFound ? (
        <DisplayFleetStats stats={stats} />
    ) : (
        <NoRecords title="Vehicles" message="No Vehicles found." />
    );
}



function DisplayFleetStats({ stats }) {
    return (
        <Layout loadingText="Loading Fleet Statistics">
            <PrimaryHeader title="Fleet Statistics"></PrimaryHeader>


            <section className="detail-section">
                <div className="grid gap-2 xl:grid-cols-3">
                    <Top10BarChart
                        data={stats?.vehicleMakes.slice(0, 10)}
                        title="Top 10 Vehicle Makes"
                        subtitle="Fleet Make Composition"
                        yDataKey="make"
                        xDataKey="count"
                        barColor={"#2563eb"}
                    />

                    <Top10BarChart
                        data={stats?.vehicleMakeModels.slice(0, 10)}
                        title="Top 10 Vehicle Makes and Model"
                        subtitle="Fleet Make Model Composition"
                        yDataKey="makeAndModel"
                        xDataKey="count"
                        barColor={"#2563eb"}
                        leftMargin={100}
                        yTickWidth={210}
                    />

                    <Top10LineChart
                        data={stats?.vehicleYears.slice(-10)}
                        title="Top 10 Vehicle Manufacture Years"
                        subtitle="Fleet Age Composition"
                        xDataKey="year"
                        yDataKey="count"
                        lineColor={"#2563eb"}
                    />
                </div>

            </section>
        </Layout>
    );
}

export default DashboardFleetStats