import { CartesianGrid, Line, LineChart, XAxis,YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../UI/card";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent} from "../UI/chart";
import { cn } from "../../core/utilities";




// const LineGraph = ({ title, borderColor, graphData = {} }) => {




const LineGraph = ({angle, formatType, data, title, subtitle, xDataKey, yDataKey,lineColor, children,className,labelKey, multiValues=[]}) =>{
    const chartConfig = {
        [xDataKey]: {
          label: xDataKey,
          color: "#2563eb",
        }
      }; 

   const FormatAxis = (value) => {
    if(formatType != "date") {
      return value;
    }

    return new Date(value).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
   };
  return (
    <Card className={className}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{subtitle}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className={cn("flex flex-col w-full")}>
        
            <ChartContainer config={chartConfig} className="min-h-[200px] w-full">
              <LineChart
                accessibilityLayer
                data={data}
                margin={{
                  top: 20,
                  left: 0,
                  right: 12,
                }}
              >
                <CartesianGrid vertical={false} opacity={0.2} />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  tick={{ fill:"var(--color-primary)" }} 
                />
            <XAxis
                  dataKey={xDataKey}
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  tick={{ 
                    fill:"var(--color-primary)",
                    fontSize: "12px",
                    angle: angle,
                    textAnchor: "end",
                  }}
                  tickFormatter={FormatAxis}
                /> 
                <ChartTooltip
                  cursor={false}
                  content={<ChartTooltipContent indicator="line" labelKey={labelKey || yDataKey} nameKey={xDataKey}  />}
                />
                  <ChartLegend content={<ChartLegendContent />}  className="mt-4"/>
            
            {multiValues.length ==0  && <Line
                  dataKey={yDataKey}
                  type="natural"
                  stroke={lineColor}
                  strokeWidth={2}
                  dot={{fill: lineColor}}
                  activeDot={{r: 6}}
                >
                  {/* <LabelList
                    position="top"
                    offset={12}
                    className="fill-primary"
                    fontSize={12}
                  /> */}
                </Line> }

                {multiValues?.length >1  && <>
                
                {multiValues.map((value,key) => {
                  return <Line
                  dataKey={value.key}
                  type="natural"
                  label={value.label}
                  stroke={value.color}
                  strokeWidth={2}
                  dot={{fill: value.color}}
                    activeDot={{ r: 6 }}
                  key={key} />;
                
              })}
                </>
                }
              </LineChart>

            
            </ChartContainer>
      

        {children}

          
        </div>
      </CardContent>
    </Card>
  );
};

export default LineGraph;