import {z} from "zod";
import {UserAccess} from "../core/userAccess";
import {CustomerEntity} from "./entity.model";
import {FleetInfo} from "./fleet.model";

// Should be in-synch with backend
const userRole = z.object({
  id: z.string().uuid(),
  name: z.string(),
  description: z.string(),
  roles: z.array(z.string()),
  actions: z.array(z.string()),
  grants: z.object({
    roleName: z.array(z.string())
  }),
  permissions: z.object({
    permission: z.array(z.string())
  })
});

type NoSignedInUser = {
  token: undefined;
  isSignedIn: false;
};
type SignedInUser = {
  isSignedIn: true;
  id: number;
  emailAddress: string;
  fullName: string;
  designation: string;
  isRiiotAdmin: boolean;
  isRiiotUser: boolean;
  role: UserRole;
  access: UserAccess;
  entity: CustomerEntity;
  refreshTokenExpiry: Date;
  tokenExpiry: Date;
  token: string;
  signInDateTime: string;
  isRemoteLogEnabled: boolean;
  fleetInfo: FleetInfo;
};

export type User = {
  isSignedIn: boolean;
} & (SignedInUser | NoSignedInUser);

export type UserAuthentication = {
  signingInAttempts: UserSigningInAttempts | undefined;
  signOutMode: "none" | "user" | "system";
  refreshToken: {
    attempts: number;
    expired?: boolean;
    processStarted?: boolean;
    processCompleted?: boolean;
    processCompletedSuccessfully?: boolean;
  };
  tokenExpired: boolean;
  user: User;
};

export type UserSigningInAttempts = {
  emailAddress: string;
  failedSignInCount: number;
};

//has to match Backend Api Response
export type DbUser = {
  id: number;
  first_name: string;
  last_name: string;
  designation: string;
  email_address: string;
  last_dashboard_login?: string;
  last_app_login?: string;
  user_role_name?: string;
  user_role_display_name: string;
  fullName: string;
};

//has to match Backend Api Response
export type DbUserAuditTrail = {
  id: number;
  user_id: number;
  customer_id: number;
  url: string;
  method: string;
  params: string | null;
  query: string | null;
  body: string | null;
  browser_info: object;
  timestamp: string;
  strippedUrl: string;
  description: string;
  paramId: number;
  dashboardUrl: string;
};

export type UserRole = z.infer<typeof userRole>;
