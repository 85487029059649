import React, { useState } from "react";
import Api from "../../api";
import { useSynchronizeHubSpotTicketsMutation } from "../../api/rtk/hubspotTicketsApi";
import { AlertDialog, Filter, Layout, NoRecords, OnError, ServiceHeader } from "../../components";
import AccidentManagementCard from "../../components/cards/AccidentManagementCard";
import UserRoleAccess from "../../constant/userRoleAccess";
import { isValidArrayWithData } from "../../core/utilities";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import ServicesSkeleton from "../../skeletons/ServicesSkeleton";

export default function AccidentManagement() {
  const { data: tickets = [], isLoading, isError, error } = Api.hubspotTickets.useGetAllAccidentsTicketsQuery();
  const [synchronizeTicketsData] = useSynchronizeHubSpotTicketsMutation();
  const { hasAccess: hasAccessToSynchronize } = useHasAccessToFeature(UserRoleAccess.serviceHubspotSynchronize);
  const ticketsFound = isValidArrayWithData(tickets);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <ServicesSkeleton isLoading={isLoading} title="Vehicle Accident Management" />
  ) : ticketsFound ? (
    <DisplayTickets allTickets={tickets} hasAccessToSynchronize={hasAccessToSynchronize} synchronizeTicketsData={synchronizeTicketsData} />
  ) : (
    <NoRecords title="Vehicle Accident Management" message="No Vehicle Accident Management found." />
  );
}

function DisplayTickets({ allTickets, hasAccessToSynchronize, synchronizeTicketsData }) {
  const [tickets, setTickets] = useState(allTickets);
  const [displayData, setDisplayData] = useState(tickets);
  const [hubspotSynchAlert, setHubspotSynchAlert] = useState({ show: false });
  const updateTickets = (tickets) => {
    setTickets(tickets);
    setDisplayData(tickets);
  };

  const [loading, setLoading] = useState(false);
  const loadText = "Synchronizing Hubspots...";

  const handleTicketsSync = async () => {
    setLoading(true);
    try {
    const synchResponse = await synchronizeTicketsData().unwrap();
    if (synchResponse.success) {
      setHubspotSynchAlert({ show: true, message: "Hubspot and Dashboard have been synchronized." });
    } else {
      setHubspotSynchAlert({ show: true, message: "Hubspot and Dashboard could not be synchronized." });
    }
  } catch (error) {
    console.error("Error synchronizing tickets:", error); 
    setHubspotSynchAlert({ show: true, message: "An error occurred while synchronizing Hubspot." });
  } finally {
    setLoading(false);
  } 
  };

  const handleFilterDataChange = (filteredData) => {
    //setIsFiltered(filteredData.length !== tickets.length);
    setDisplayData(filteredData);
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getServicesAccidentManagement();
  };

  return (
    <Layout isForegroundProcess={loading} loadingText={loadText}>
      <ServiceHeader
        title="Accident Management"
        hasAccessToSynchronize={hasAccessToSynchronize}
        handleTicketsSync={handleTicketsSync}
        isFilter={false}
        setIsFilter={false}
        allTickets={allTickets}
        updateTickets={updateTickets}
      />
      <AlertDialog show={hubspotSynchAlert.show} alertMessage={hubspotSynchAlert.message} onClick={() => setHubspotSynchAlert({ show: false })} />
      <Filter enabled={false} data={tickets} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} />
      <section className="grid grid-cols-1 gap-4 mt-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {displayData?.map((breakdown) => (
          <AccidentManagementCard
            key={breakdown.id}
            name={breakdown.name}
            makeModel={breakdown.makeAndModel}
            status={breakdown.status}
            vehicleReg={breakdown.vehicle_registration}
            description={breakdown.hs_content}
            Reason={breakdown.alertCode}
          />
        ))}
      </section>
    </Layout>
  );
}
