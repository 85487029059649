import React from "react";
import {SystemTableIcons} from "../TableIcons";
import TableDataTypes, {TableDataType} from "./TableDataTypes";

export type SystemTableColumnAlign = "left" | "right" | "center";

export type SystemTableColumnDangerInfo = {
  key: string;
  value: boolean;
  message: string;
};

export type SystemTableColumnLink = {
  path: string;
  id: string;
};

export type SystemTableColumnClick = {
  id: string;
  data: string;
};

export type SystemTableColumnImageViewData = {
  url: string;
  alt: string;
  headerOne: string;
  headerTwo: string;
};

type SystemTableColumnBase = {
  key: string;
  isIdentifier?: boolean;
  canHide?: boolean;
  isShown?: boolean;
  link?: SystemTableColumnLink;
  icon?: SystemTableIcons;
  type?: TableDataType;
  align?: SystemTableColumnAlign;
  excludeOnActive?: boolean;
  click?: {id: string; data: string};
  dangerInfo?: SystemTableColumnDangerInfo;
  dataObject?: SystemTableColumnImageViewData;
  excludeHeader?: boolean;
  isHidden?: boolean;
  dataType?: TableDataType;
};

export type SystemTableColumn = SystemTableColumnBase & ({isShown: false; label?: ""} | {isShown?: true; label: string; isSortable: boolean});

// export type SystemTableColumn = SystemTableColumnBase & { isShown: boolean; label: string; dataType: string; isSortable: boolean };

export type SystemTable = {
  name: string;
  columnOptions: SystemTableColumn[];
};

export default class SystemTables {
  static dashboardCameraFeeds: SystemTableColumn[] = [
    {key: "makeAndModel", label: "Make Model", isSortable: true},
    {key: "device_id", label: "Device Id", isSortable: true},
    {key: "vehicleRegistrationNumber", label: "Vehicle Registration", isSortable: true},
    {
      key: "image_url",
      label: "Feed",
      dataType: TableDataTypes?.ImageVideoModal,
      isSortable: false,
      dataObject: {url: "image_url", alt: "cameraFeed", headerOne: "makeAndModel", headerTwo: "vehicleRegistrationNumber"}
    },
    {key: "last_camera_footage_at", label: "Feed DateTime", dataType: TableDataTypes?.DateTime, isSortable: true},
    {key: "last_reported_at", label: "Reported DateTime", dataType: TableDataTypes?.DateTime, isSortable: true}
  ];

  static dashboardLiveDataFeed: SystemTableColumn[] = [
    {key: "makeAndModel", label: "Make Model", isSortable: true},
    {key: "vehicleRegistrationNumber", label: "Vehicle Registration", isSortable: true, link: {path: "/vehicles/{id}", id: "id"}},
    {key: "gps_speed", label: "Speed", isSortable: true},
    {key: "vehicle_type", label: "Vehicle Type", isSortable: true},
    {key: "last_reported_at", label: "Reported DateTime", dataType: TableDataTypes?.DateTime, isSortable: true}
  ];

  static devices: SystemTable = {
    name: "devices",
    columnOptions: [
      {key: "id", label: "Id", isSortable: true, isIdentifier: true, canHide: true, link: {path: "/devices/{id}", id: "id"}, icon: "vehicleDeviceIoT"},
      {key: "primary_sim_iccid", label: "Prim Sim IccId", isSortable: true, canHide: true, dangerInfo: {key: "primary_sim_card_active", value: false, message: "Device Sim card is not ACTIVE"}, link: {path: "/sim-cards/{id}", id: "primary_sim_iccid"}, icon: "sim"},
      {key: "primary_sim_msisdn", label: "Prim Sim Msisdn", isSortable: true, canHide: true, link: {path: "/sim-cards/{id}", id: "primary_sim_iccid"}, icon: "sim"},
      {key: "vehicleRegistrationNumber", label: "Vehicle Reg", isSortable: true, canHide: true, link: {path: "/vehicles/{id}", id: "vehicle_id"}, icon: "car"},
      {key: "serial_number", label: "Serial Number", isSortable: true, canHide: true},
      {key: "firmware_name", label: "Firmware", isSortable: true, canHide: true},
      {key: "vehicle_battery_voltage", label: "Battery Voltage", align: "right", dataType: TableDataTypes?.Hundredth, isSortable: true, canHide: true},
      {key: "lastReportedOrMinimum", label: "Last Reported", dataType: TableDataTypes?.DateTimeOrMinimum, isSortable: true, canHide: true},
      {key: "lastDataTimestampOrMinimum", label: "Last Data Timestamp", dataType: TableDataTypes?.DateTimeOrMinimum, isSortable: true, canHide: true},
      {key: "dataTimestampDurationInSeconds", label: "Data Backlog Duration", dataType: TableDataTypes?.Duration, isSortable: true, canHide: true},
      {key: "lastSeenOrMinimum", label: "Last Seen", dataType: TableDataTypes?.DateTimeOrMinimum, isSortable: true, canHide: true},
      {key: "manufacturerApiLastOnlineOrMinimum", label: "Last Ext Api Online", dataType: TableDataTypes?.DateTimeOrMinimum, isSortable: true, canHide: true},
      {key: "vehicle_is_in_for_repair", label: "In For Repairs", dataType: TableDataTypes?.Boolean, isSortable: true, canHide: true}
    ]
  };

  static deviceEvents: SystemTable = {
    name: "deviceEvents",
    columnOptions: [
      {key: "event_id", label: "Id", isSortable: true, isIdentifier: true},
      {key: "vehicleRegistrationNumber", label: "Vehicle Reg", isSortable: true, link: {path: "/vehicles/{id}", id: "vehicle_id"}},
      {key: "make", label: "Make", isSortable: true},
      {key: "model", label: "Model", isSortable: true},
      {key: "device_type", label: "Device Type", isSortable: true},
      {key: "event_type", label: "Event Type", isSortable: true},
      {key: "event_code", label: "Code", isSortable: true},
      {key: "event_value", label: "Value", isSortable: true},
      {key: "description", label: "Description", isSortable: true},
      {key: "resolved", label: "Resolved", isSortable: true, dataType: TableDataTypes?.Boolean, excludeOnActive: true},
      {key: "override", label: "Overwritten", isSortable: true, dataType: TableDataTypes?.Boolean, excludeOnActive: true},
      {key: "data_timestamp", label: "Reported", dataType: TableDataTypes?.DateTime, isSortable: true}
    ]
  };

  static deviceSmsAlerts = [
    {key: "id", label: "Device Id", isSortable: true, dataIdentifier: true},
    {key: "vehicleRegistrationNumber", label: "Vehicle Reg", isSortable: true, link: {path: "/vehicles/{id}", id: "vehicle_id"}},
    {key: "make", label: "Make", isSortable: true},
    {key: "model", label: "Model", isSortable: true},
    {key: "device_type", label: "Device Type", isSortable: true},
    {key: "alert_type", label: "Alert Type", isSortable: true},
    {key: "alert_value", label: "Alert Code", isSortable: true},
    {key: "data_timestamp", label: "Reported", dataType: TableDataTypes?.DateTime, isSortable: true}
  ];

  static deviceCameraFeeds: SystemTableColumn[] = [
    {key: "camera_trigger", label: "Trigger", isSortable: true},
    {key: "image_description", label: "Description", isSortable: true},
    {
      key: "image_url",
      label: "Feed",
      dataType: TableDataTypes?.ImageVideoModal,
      isSortable: false,
      dataObject: {url: "image_url", alt: "camera_used", headerOne: "makeAndModel", headerTwo: "vehicleRegistrationNumber"}
    },
    {key: "timestamp", label: "Captured", dataType: TableDataTypes?.DateTime, isSortable: true},
    {key: "created_at", label: "Acquired at", dataType: TableDataTypes?.DateTime, isSortable: true}
  ];

  static deviceTripPoints: SystemTableColumn[] = [
    {key: "id", label: "Id", isSortable: true, isIdentifier: true, click: {id: "id", data: "json_avl_data"}},
    {key: "event_priority", label: "Priority", isSortable: true},
    {key: "event_type", label: "Event Type", isSortable: true},
    {key: "latitudeLongitude", label: "Latitude Longitude", isSortable: true},
    {key: "angle", label: "Angle", isSortable: true},
    {key: "altitude", label: "Altitude", isSortable: true},
    {key: "data_timestamp", label: "Data Timestamp", dataType: TableDataTypes?.DateTime, isSortable: true},
    {key: "timestamp", label: "Received Timestamp", dataType: TableDataTypes?.DateTime, isSortable: true},
    {key: "dataDuration", label: "Data Duration", isSortable: true},
    {key: "battery_voltage", label: "Battery", isSortable: true, dataType: TableDataTypes?.Hundredth},
    {key: "ignition_on", label: "Ignition", dataType: TableDataTypes?.Boolean, isSortable: true},
    {key: "movement", label: "Movement", dataType: TableDataTypes?.Boolean, isSortable: true},
    {key: "gps_speed", label: "Gps Speed", isSortable: true},
    {key: "gsm_rssi", label: "Gsm Signal", isSortable: true}
  ];

  static simCards: SystemTable = {
    name: "simCards",
    columnOptions: [
      {key: "iccid", label: "IccId", isSortable: true, isIdentifier: true, link: {path: "/sim-cards/{id}", id: "iccid"}},
      {key: "imsi", label: "Imsi", isSortable: true, link: {path: "/sim-cards/{id}", id: "iccid"}},
      {key: "msisdn", label: "Msisdn", isSortable: true, link: {path: "/sim-cards/{id}", id: "iccid"}},
      {key: "network_status", label: "Network Status", isSortable: true},
      {key: "data_balance_mb", label: "Data Balance MB", align: "right", dataType: TableDataTypes?.Hundredth, isSortable: true},
      {key: "airtime_balance", label: "Airtime Balance", align: "right", dataType: TableDataTypes?.Hundredth, isSortable: true},
      {key: "sms_balance", label: "SMS Balance", align: "right", dataType: TableDataTypes?.Hundredth, isSortable: true},
      {key: "ip_address", label: "IP Address", isSortable: true},
      {key: "synchronized_at", label: "Synch DateTime", dataType: TableDataTypes?.DateTime, isSortable: true}
    ]
  };

  static simCardUsageLogs: SystemTableColumn[] = [
    {key: "column_name", label: "Change", isSortable: true, isIdentifier: true},
    {key: "old_value", label: "Old Value", isSortable: true, dataType: TableDataTypes?.Hundredth},
    {key: "new_value", label: "New Value", isSortable: true, dataType: TableDataTypes?.Hundredth},
    {key: "used_mb", label: "Used Data(MB)", isSortable: true, dataType: TableDataTypes?.Hundredth},
    {key: "used_airtime", label: "Used Airtime", isSortable: true, dataType: TableDataTypes?.Hundredth},
    {key: "is_recharge", label: "Recharge", isSortable: true, dataType: TableDataTypes?.Boolean},
    {key: "is_zero_balance", label: "Zero Balance", align: "right", dataType: TableDataTypes?.Boolean, isSortable: true},
    {key: "is_negative_balance", label: "Negative Balance", align: "right", dataType: TableDataTypes?.Boolean, isSortable: true},
    {key: "change_date", label: "Change DateTime", dataType: TableDataTypes?.DateTime, isSortable: true}
  ];

  static simCardAirtimeDailyUsage: SystemTableColumn[] = [
    {key: "used_airtime", label: "Used Airtime", isSortable: true, isIdentifier: true, dataType: TableDataTypes?.Hundredth},
    {key: "day_date", label: TableDataTypes?.Date, isSortable: true, dataType: TableDataTypes?.Date}
  ];

  static simCardDataDailyUsage: SystemTableColumn[] = [
    {key: "used_airtime", label: "Used Data (MB)", isSortable: true, isIdentifier: true, dataType: TableDataTypes?.Hundredth},
    {key: "day_date", label: TableDataTypes?.Date, isSortable: true, dataType: TableDataTypes?.Date}
  ];

  static users: SystemTable = {
    name: "users",
    columnOptions: [
      {key: "id", isShown: false, isIdentifier: true},
      {key: "email_address", label: "Email Address", isSortable: true},
      {key: "first_name", label: "First Name", isSortable: true},
      {key: "last_name", label: "Last Name", isSortable: true},
      {key: "designation", label: "Designation", isSortable: true},
      {key: "user_role_display_name", label: "User Role", isSortable: true},
      {key: "last_dashboard_login", label: "Last Online", dataType: TableDataTypes?.DateTime, isSortable: true},
      {key: "enable_remote_log", label: "Remote Log Enabled", dataType: TableDataTypes?.EditableBoolean, isSortable: false}
    ]
  };

  static userRegistrations: SystemTableColumn[] = [
    {key: "email_address", label: "Email Address", isSortable: true, isIdentifier: true},
    {key: "first_name", label: "First Name", isSortable: true},
    {key: "last_name", label: "Last Name", isSortable: true},
    {key: "designation", label: "Designation", isSortable: true},
    {key: "created_at", label: "Date Created", dataType: TableDataTypes?.DateTime, isSortable: true},
    {key: "registration_key_expiry", label: "Expiry Date", dataType: TableDataTypes?.DateTime, isSortable: true},
    {key: "reset_expiry", label: "Reset Expiry", isSortable: false, dataType: TableDataTypes?.Button, excludeHeader: true},
    {key: "resend_email", label: "Resend Email", isSortable: false, dataType: TableDataTypes?.Button, excludeHeader: true}
  ];

  static userAuditTrails: SystemTableColumn[] = [
    {key: "id", isShown: false, isIdentifier: true},
    {key: "description", label: "Activity Description", isSortable: true},
    {key: "paramId", label: "Activity Record", isSortable: true},
    {key: "browserName", label: "Browser Used", isSortable: true},
    {key: "dashboardUrl", isShown: false},
    {key: "timestamp", label: "Activity DateTime", dataType: TableDataTypes?.DateTime, isSortable: true}
  ];

  static vehiclesBatteryInfo: SystemTableColumn[] = [
    {key: "id", label: "Id", isSortable: true, isIdentifier: true},
    {key: "make", label: "Make", isSortable: true},
    {key: "model", label: "Model", isSortable: true},
    {key: "veh_registration_no", label: "Vehicle Reg", isSortable: true, dataType: TableDataTypes?.VehicleRegistration, link: {path: "/vehicles/{id}", id: "id"}},
    {key: "battery_type", label: "Battery Type", isSortable: true},
    {key: "battery_voltage", label: "Battery Voltage", align: "right", dataType: TableDataTypes?.Hundredth, isSortable: true},
    {key: "battery_capacity", label: "Battery Capacity", align: "right", dataType: TableDataTypes?.Percentage, isSortable: true},
    {key: "last_reported_at", label: "Last Reported", dataType: TableDataTypes?.DateTime, isSortable: true}
  ];

  static vehicleFleets: SystemTable = {
    name: "vehicleFleets",
    columnOptions: [
      {key: "id", label: "Id", isSortable: true, isIdentifier: true},
      {key: "name", label: "Name", isSortable: true, link: {path: "/vehicles/vehicles-fleets/{id}", id: "id"}},
      {key: "description", label: "Description", isSortable: true},
      {key: "vehicle_count", label: "Number of Vehicles", isSortable: true}
    ]
  };
}
