import React, { useState } from "react";
import "./JsonGridTable.css";


const JsonGridTable = ({ data }) => {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (key) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [key]: !prevExpanded[key],
    }));
  };

  const renderValue = (value) => {
    return typeof value === "object" ? JSON.stringify(value) : value;
  };

  const renderArrayValue = (arrayValue, key) => {
    const keys = Object.keys(arrayValue[0] || {});

    return (
      <div>
        <button onClick={() => toggleExpand(key)}>
          {expanded[key] ? "[-]" : "[+]"}
          {key}
        </button>
        {expanded[key] && (
          <table className="json-grid-table">
            <thead>
              <tr>
                {keys.map((innerKey, index) => (
                  <th key={index}>{innerKey}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {arrayValue.map((item, index) => (
                <tr key={index}>
                  {keys.map((innerKey, columnIndex) => (
                    <td key={columnIndex}>{renderValue(item[innerKey])}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const renderObjectValue = (objectValue, key) => {
    if (!objectValue) {
      return null; 
    }

    const objectKeys = Object.keys(objectValue);
    return (
      <div>
        <button onClick={() => toggleExpand(key)}>
          {expanded[key] ? "[-]" : "[+]"}
          {key}
        </button>
        {expanded[key] && (
          <table className="json-grid-table">
            <tbody>
              {objectKeys.map((innerKey, index) => (
                <tr key={index}>
                  <th>{innerKey}</th>
                  <td>
                    {objectValue[innerKey] &&
                    Array.isArray(objectValue[innerKey])
                      ? renderArrayValue(objectValue[innerKey], innerKey)
                      : objectValue[innerKey] &&
                        typeof objectValue[innerKey] === "object"
                      ? renderObjectValue(objectValue[innerKey], innerKey)
                      : renderValue(objectValue[innerKey])}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  const renderTable = (data, headers) => {
    return (
      <table className="json-grid-table">
        <tbody>
          {headers.map((header, index) => (
            <tr key={index}>
              <th>{header}</th>
              <td>
                {Array.isArray(data[header])
                  ? renderArrayValue(data[header], header)
                  : typeof data[header] === "object"
                  ? renderObjectValue(data[header], header)
                  : renderValue(data[header])}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderJsonGrid = (jsonData) => {
    if (!jsonData || (Array.isArray(jsonData) && jsonData.length === 0)) {
      return <div>No data to display.</div>;
    }

    const isArrayData = Array.isArray(jsonData);

    return (
      <div className="json-grid-container">
        {isArrayData ? (
          jsonData.map((item, index) => (
            <React.Fragment key={index}>
              {renderTable(item, Object.keys(item))}
            </React.Fragment>
          ))
        ) : (
          renderTable(jsonData, Object.keys(jsonData))
        )}
      </div>
    );
  };

  return <div className="json-grid">{renderJsonGrid(data)}</div>;
};

export default JsonGridTable;
