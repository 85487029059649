import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Api from "../../api";
import {useDisableUserRemoteLogMutation, useEnableUserRemoteLogMutation} from "../../api/rtk/usersApi";
import {Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader} from "../../components";
import FilterButton from "../../components/filter/FilterButton";
import {UserProcessFlowInit} from "../../components/flow/ProcessFlowDialog";
import {SystemTables, Table, initializeTableColumns} from "../../components/table";
import {SystemTable} from "../../components/table/options/SystemTables";
import {TableColumn} from "../../components/table/types/tableTypes";
import {ProcessFlowStage, UserRoleAccess} from "../../constant";
import {isValidArrayWithData} from "../../core/utilities";
import {getTableHiddenOptionFeature, setTableHiddenOptionFeature} from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

export default function Users() {
  const {data: users = undefined, isLoading, isError, error, refetch: refetchUsers} = Api.users.useGetAllUsersQuery("");
  const usersFound = isValidArrayWithData(users);

  // console.log({ users });

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} title={"Users"} />
  ) : usersFound ? (
    <DisplayUsers users={users} refetchUsers={refetchUsers} />
  ) : (
    <NoRecords title="Users" message="No Users found.">
      {" "}
    </NoRecords>
  );
}

type DisplayUsersProps = {
  users: any[];
  refetchUsers: () => void;
};

function DisplayUsers({users, refetchUsers}: DisplayUsersProps) {
  const [tableInfo] = useState<SystemTable>(SystemTables.users);
  const currentHiddenColumns = useSelector(getTableHiddenOptionFeature(tableInfo.name));
  const [displayData, setDisplayData] = useState(users);
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [tableColumns, setTableColumns] = useState(initializeTableColumns(tableInfo?.columnOptions as TableColumn[], currentHiddenColumns as any)); // not sure about the type of currentHiddenColumns

  const {numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick} = usePageFilter({displayData});
  const navigate = useNavigate();
  const {hasAccess: hasAccessToViewUsers} = useHasAccessToFeature(UserRoleAccess.userView);
  const {hasAccess: hasAccessToChangeRemoteLog} = useHasAccessToFeature(UserRoleAccess.userManageRemoteLog);
  const [enableUserRemoteLog] = useEnableUserRemoteLogMutation();
  const [disableUserRemoteLog] = useDisableUserRemoteLogMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    setDisplayData(users);
  }, [users]);

  const handleFilterDataChange = (filteredData: any[]) => {
    setIsFiltered(filteredData.length !== users.length);
    setDisplayData(filteredData);
  };

  const handleTableDoubleClick = (event: any, identifier: string | number) => {
    if (hasAccessToViewUsers) {
      navigate(`/users/${identifier}`);
    }
  };

  const onColumnOptionsChange = (columnOptions: TableColumn[]) => {
    setTableColumns(columnOptions);
    const hidden = columnOptions.filter(column => column.isHidden).map(column => column.key);
    dispatch(setTableHiddenOptionFeature({name: tableInfo.name, hidden}));
  };

  const filterOptions = () => {
    const option = new FilterOptions();
    return option.getUsers();
  };

  const onUserProcessConfirm = async ({confirm, stage}: {confirm: any; stage: typeof ProcessFlowStage}) => {
    //TODO!!! Mohau fix below
    /*
    setUserProcessFlow({ ...userProcessFlow, stage });

    if (confirm.yes) {
      const { key, type, user } = {key:"",type:null,user:User}; //userProcessFlow.processData;

      if (key === "enable_remote_log" && user) {
        const processMessage = "Processing User Remote Logging";
        setUserProcessFlow({ stage: ProcessFlowStage.Processing, processMessage });
        const response = await enableDisableUserRemoteLog(user.email_address, user.entity_id, type === "Enable");

        if (response.success) {
          setUserProcessFlow({ stage: ProcessFlowStage.Info, infoMessage: `User Remote Logging ${type} successfully` });
        } else {
          setUserProcessFlow({ stage: ProcessFlowStage.Alert, alertMessage: `User Remote Logging ${type} could not be completed successfully` });
        }
      }
    }
    */
  };

  const handleTableEditableBooleanAccessCheck = ({key}: {key: string}): boolean => {
    if (key === "enable_remote_log") {
      return hasAccessToChangeRemoteLog || false;
    }
    return false;
  };
  const handleTableEditableBooleanChange = ({id, key, value}: {id: any; key: any; value: any}) => {
    //TODO!!! Mohau fix below
    /*
    if (key === "enable_remote_log") {
      const user = users.find((user) => user.id === id);
      const type = value ? "Disable" : "Enable";
      //const message = `Are you sure you want to ${type} Remote Logging for ${user.fullName}?`;
      // setCommandConfirmation({ confirm: true, message,key,type, user });

      const confirmMessage = `Are you sure you want to ${type} Remote Logging for ${user.fullName}?`;
      setUserProcessFlow({ stage: ProcessFlowStage.Confirm, confirmMessage, processData: { key, type, user }, processId: key });
    }
    */
  };

  const enableDisableUserRemoteLog = async (emailAddress: string, entityId: number, enable: boolean) => {
    return new Promise(resolve => {
      if (enable) {
        enableUserRemoteLog({emailAddress, entityId})
          .unwrap()
          .then(response => {
            refetchUsers();
            resolve({success: response.success});
          })
          .catch(error => {
            console.error(error);
            resolve({success: false});
          });
      } else {
        disableUserRemoteLog({emailAddress, entityId})
          .unwrap()
          .then(response => {
            refetchUsers();
            resolve({success: response.success});
          })
          .catch(error => {
            console.log({error});
            resolve({success: false});
          });
      }
    });
  };

  // console.log({currentHiddenColumns,tableColumns});
  return (
    <Layout>
      <PrimaryHeader title="Users">
        <div className="flex">
          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>
      {/**
      <ProcessFlowDialog
        processId={userProcessFlow.processId}
        processStage={userProcessFlow.stage}
        alertMessage={userProcessFlow.alertMessage}
        confirmMessage={userProcessFlow.confirmMessage}
        infoMessage={userProcessFlow.infoMessage}
        processingMessage={userProcessFlow.processMessage}
        onConfirm={onUserProcessConfirm}
      />
      <Filter enabled={isFilter} data={users} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} />
      */}
      <section className="detail-section">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage} recordsName={"Users"} isFiltered={isFiltered} totalNumberOfItems={totalNumberOfItems} />
        <Table data={displayData} pagingInfo={{dataPerPage: itemsPerPage, currentPage}} tableColumns={tableColumns} initialSort={{key: "email_address", order: "asc"}} onTableDataDoubleClick={handleTableDoubleClick} includeSequentialId={true} onEditableBooleanChange={handleTableEditableBooleanChange} onEditableBooleanAccessCheck={handleTableEditableBooleanAccessCheck} showTableColumnSelect={true} tableColumnSelectOptions={tableColumns} onColumnOptionsChange={onColumnOptionsChange} />

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} currentPage={currentPage} />
      </section>
    </Layout>
  );
}
