import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {PageFilter, Pagination} from "../../../components";
import Filter from "../../../components/filter/Filter";

import {SystemTables, Table} from "../../../components/table";
import SystemFeatures from "../../../constant/systemFeatures";
import {getFilterOptionsFeature, getPerPageFeature, setIsFilterFeature, setPerPageFeature} from "../../../features/settings/settingsSlice";
import FilterOptions from "../../../filter/FilterOptions";
import usePageFilter from "../../../hooks/usePageFilter";

const PROCESS_FEATURE = SystemFeatures.DEVICE_CAMERA_FEEDS;

function DeviceCameraFootagesView({cameraFeeds}) {
  return <DisplayCameraFeeds cameraFeeds={cameraFeeds} />;
}

function DisplayCameraFeeds({cameraFeeds}) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));

  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(cameraFeeds);

  const [isFiltered, setIsFiltered] = useState(false);

  const {numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick} = usePageFilter({
    displayData,
    currentItemsPerPage
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setDisplayData(cameraFeeds);
  }, [cameraFeeds]);

  useEffect(() => {
    dispatch(setPerPageFeature({name: PROCESS_FEATURE, perPage: itemsPerPage}));
  }, [dispatch, itemsPerPage]);

  const handleFilterDataChange = filteredData => {
    // console.log({ filteredData });
    setIsFiltered(filteredData.length !== cameraFeeds.length);
    if (filteredData.length > 0) {
      setDisplayData(filteredData);
    } else {
      setDisplayData(cameraFeeds);
    }
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getCameraFeedsOptions();
  };

  console.log("Debug:", {cameraFeeds, displayData});

  return (
    <React.Fragment>
      <Filter enabled={false} data={cameraFeeds} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} feature={PROCESS_FEATURE} defaultFilterOptions={defaultFilterOptions} />

      <section className="flex flex-col gap-8 ">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage} recordsName={"Camera Feeds"} isFiltered={isFiltered} totalNumberOfItems={totalNumberOfItems} />

        <Table data={displayData} pagingInfo={{dataPerPage: itemsPerPage, currentPage}} tableColumns={SystemTables.deviceCameraFeeds} initialSort={{key: "last_camera_footage", order: "desc"}} />
        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} currentPage={currentPage} />
      </section>
    </React.Fragment>
  );
}

export default DeviceCameraFootagesView;
