import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLogoutMutation } from "../api/auth/authApi";
import { clearSignInCredentials, selectCurrentEmailAddress, selectCurrentEntity } from "../features/auth/authSlice";

const Logout = () => {
  const emailAddress = useSelector(selectCurrentEmailAddress);
  const entity = useSelector(selectCurrentEntity);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logout] = useLogoutMutation();

  console.log("in logout");

  useEffect(() => {
    dispatch(clearSignInCredentials());
    logout({ emailAddress, entity }).then(() => {});

    navigate("/login");
  }, [dispatch, emailAddress, entity, logout, navigate]);

  return (
    <>
      <h3>Logging Out ...</h3>
    </>
  );
};

export default Logout;
