import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import {ReactNode, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import systemConfig from "../config/systemConfiguration";
import {selectCurrentUser} from "../features/auth/authSlice";
import {expandSidebar, minimizeSidebar, selectCurrentSidebar} from "../features/settings/settingsSlice";
import {User} from "../models/user.model";
import LayoutHeader from "./LayoutHeader";
import Loading from "./Loading";
import AlertDialog from "./modal/AlertDialog";
import ConfirmationDialog from "./modal/ConfirmationDialog";
import InfoDialog from "./modal/InfoDialog";
import Sidebar from "./Sidebar/Sidebar";
import { on } from "events";

type AsyncAlertMessageType = {
  show: boolean;
  message?: string;
};

type AsyncConfirmMessageType = {
  show: boolean;
  message?: string;
}

type AsyncInfoMessageType = {
  show: boolean;
  message?: string;
}



type UserAsyncProcessStage = "running" | "complete" | "confirmed" | "awaitingConfirmation" | "none";

type UserAsyncProcessProps = {
  stage: UserAsyncProcessStage;
  isRunning: boolean;
  running: {message: string};

};

type LoadingProps = {
  isLoading: boolean;
  loadingText: string;
  isForegroundProcess?: boolean;
  userAsyncProcess?: () => Promise<any>;
  processTrigger?: {name: string; initiate: boolean; confirmationRequired: boolean};
  onProcessComplete?: (success: boolean) => void;
};
type NoneLoadingProps = {
  isLoading?: undefined;
  loadingText?: undefined;
  isForegroundProcess?: undefined;
  userAsyncProcess?: () => Promise<void>;
  processTrigger?: {name: string; initiate: boolean; confirmationRequired: boolean};
  onProcessComplete?: (success: boolean) => void;
};

type Props = {
  children: ReactNode;
} & (LoadingProps | NoneLoadingProps);

const initializeUserAsyncProcess = (): UserAsyncProcessProps => {
  return {
    stage: "none",
    isRunning: false,
    running: {message: ""},
  };
};

const Layout = ({children, isLoading = false, loadingText = "", isForegroundProcess, processTrigger, onProcessComplete, userAsyncProcess}: Props) => {
  const user: User = useSelector(selectCurrentUser);
  const [asyncProcessConfirm, setAsyncProcessConfirm] = useState<AsyncConfirmMessageType>({ show: false });
  const [asyncProcessAlert, setAsyncProcessAlert] = useState<AsyncAlertMessageType>({ show: false });
  const [asyncProcessInfo, setAsyncProcessInfo] = useState<AsyncInfoMessageType>({ show: false });
  const [sidebar, setSidebar] = useState<{expanded: boolean}>(useSelector(selectCurrentSidebar) || {expanded: true});
  const [userAsyncProcessInfo, setUserAsyncProcessInfo] = useState<UserAsyncProcessProps>(initializeUserAsyncProcess());

  const sidebarExpanded = sidebar.expanded;
  const dispatch = useDispatch();
  const isLoadingState = isLoading || isForegroundProcess;
  const handleExpandSidebar = (expanded: boolean) => {
    setSidebar({expanded});
    if (expanded) {
      dispatch(expandSidebar());
    } else {
      dispatch(minimizeSidebar());
    }
  };

  useEffect(() => {
    if (user.isSignedIn && user.isRemoteLogEnabled) {
      LogRocket.init(systemConfig.logRocket.appCode);
      LogRocket.identify(user.id.toString(), {name: user.fullName, entityName: user.entity.uuId});

      setupLogRocketReact(LogRocket);
    }
  }, [user]);

  useEffect(() => {

    if (processTrigger?.initiate) {
      if (processTrigger?.confirmationRequired) {
        const message = `Are you sure you want to proceed with ${processTrigger.name}?`;
        setAsyncProcessConfirm({show: true, message});

      } else {
        
        setUserAsyncProcessInfo({...userAsyncProcessInfo, stage: "confirmed"});
        handleAsyncProcess();
        //deal with the loop issue
        
      }
      
    }
  }, [processTrigger?.initiate, processTrigger?.confirmationRequired, processTrigger?.name]);

  const handleAsyncProcess = async () => {
    
    if (userAsyncProcess && processTrigger)
    {
    setUserAsyncProcessInfo({ ...userAsyncProcessInfo, stage: "running", isRunning: true, running: { message: `Processing ${processTrigger.name}, please wait...` } });
    try {
      userAsyncProcess()
        .then(result => {
          if ("error" in result) {
            const errorMessage = result.error.message || `Error:[${result.error.status}], Process ${processTrigger.name} failed!`;
            
            setAsyncProcessAlert({ show: true, message: errorMessage });
            setUserAsyncProcessInfo({ ...userAsyncProcessInfo, stage: "complete", isRunning: false, running: { message: "" } });
            
          } else {
            setAsyncProcessInfo({ show: true, message: `Process ${processTrigger.name} completed successfully!` });
            setUserAsyncProcessInfo({ ...userAsyncProcessInfo, stage: "complete", isRunning: false, running: { message: "" } });
          }

          
          //onProcessComplete(true);
        })
        .catch(error => {
          setAsyncProcessAlert({ show: true, message: error.message });
          setUserAsyncProcessInfo({ ...userAsyncProcessInfo, stage: "complete", isRunning: false, running: { message: "" } });
          
          //onProcessComplete(false);
        });

      //  onProcessComplete(true);
    } catch (error) {
      setUserAsyncProcessInfo(initializeUserAsyncProcess());
      
      setUserAsyncProcessInfo({ ...userAsyncProcessInfo, stage: "complete", isRunning: false, running: { message: "" } });
      //alert('Process failed 134!');
      if (error instanceof Error) {
        setAsyncProcessAlert({ show: true, message: error.message });
        
      } else {
        setAsyncProcessAlert({ show: true, message: `Unknown error: ${error}` });
        
      }

      //onProcessComplete(false);
    } finally {
      //setUserAsyncProcessInfo(initializeUserAsyncProcess());
    }
  }
  };

  const handleNoConfirmation = () => {
    setAsyncProcessConfirm({ show: false });
    if (onProcessComplete) {
      onProcessComplete(false);
    }
    setUserAsyncProcessInfo(initializeUserAsyncProcess());
  };

  const handleYesConfirmation = () => {
    //set userAsyncProcessInfo to confirmed
    setAsyncProcessConfirm({show: false});
    handleAsyncProcess();
  };

  const handleInfoOnClick = () => {
    setAsyncProcessInfo({show: false});
    if (onProcessComplete) {
      onProcessComplete(true);
    }
  };
  const handleAlertOnClick = () => {
    setAsyncProcessAlert({show: false});
    if (onProcessComplete) {
      onProcessComplete(false);
    }
    setUserAsyncProcessInfo(initializeUserAsyncProcess());
  };

  
  return (
    <section className="relative">
      {/*  Site header */}
      <LayoutHeader sidebarExpanded={sidebarExpanded} setSidebarObject={setSidebar} />

      <div className="flex overflow-hidden">
        <aside className="fixed z-40 h-full mr-8">
          {/* Sidebar */}
          <div className={`relative h-full ${isLoadingState ? "blur-xs" : ""}`}>
            <Sidebar sidebarExpanded={sidebarExpanded}  />
            <div className="group">
              <button type="button" onClick={() => handleExpandSidebar(!sidebarExpanded)} className="absolute bg-white rounded-full shadow-md -right-2 bottom-40 group-hover:bg-brand">
                {sidebarExpanded ? (
                  <svg className="w-8 h-8 fill-secondary group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M8 12l6-6v12z" />
                  </svg>
                ) : (
                  <svg className="w-8 h-8 fill-secondary group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M16 12l-6 6V6z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </aside>

        {/* Content area */}
        <div className={`relative flex flex-1 flex-col ${sidebarExpanded ? "ml-60" : "ml-10"} overflow-hidden pr-0`}>
          {/*  Page header */}
          <main className={`my-w-9x mx-auto flex w-full flex-col gap-2 px-2 py-2 sm:px-2 lg:px-2 ${isLoadingState && isForegroundProcess ? "blur-sm brightness-50 contrast-50" : ""}`}>{children}</main>
        </div>
      </div>

      {isLoadingState ? <Loading isLoading={isLoading} loadingText={loadingText} isForegroundProcess={isForegroundProcess} /> : null}
      <Loading isLoading={userAsyncProcessInfo.isRunning} loadingText={userAsyncProcessInfo.running.message} isForegroundProcess={true} />
      <ConfirmationDialog show={asyncProcessConfirm.show} onNo={handleNoConfirmation} onYes={handleYesConfirmation} confirmationMessage={asyncProcessConfirm.show ? asyncProcessConfirm.message : ""} />
      <InfoDialog onClick={handleInfoOnClick} show={asyncProcessInfo.show} infoMessage={asyncProcessInfo.message} />
      <AlertDialog show={asyncProcessAlert.show} alertMessage={asyncProcessAlert.message} onClick={handleAlertOnClick} />
    </section>
  );
};

export default Layout;
