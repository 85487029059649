import { useState, useMemo } from "react";
import { Layout, NoRecords, OnError } from "../../components";
import GraphsSkeleton from "../../skeletons/GraphsSkeleton";
import { Link } from "react-router-dom";
import { BarGraphNew, LineGraph } from "../../components/charts";
import { isValidArrayWithData, stringToNumber, stringToCurrency, dateMonthYearDisplay } from "../../core/utilities";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import UserRoleAccess from "../../constant/userRoleAccess";

import Api from "../../api";

/* Sample Code Data
const data = {
  name: "Jonas",
  surname: "Mkansi",
  tel: "0123456789",
  driverId: "08",
  driving_style: {
    driver_score: "75%",
    harsh_braking_count: "80%",
    harsh_acceleration_count: "89%"
  },
  engine_load_time: {
    title: "Throttle Position Time Spend (%)",
    data: {
      "0 - 50": 52,
      "51 - 75": 21,
      "76 - 100": 27
    }
  },
  throttle_position_time: {
    title: "RPM Time Spend (%)",
    data: {
      "0 - 50": 90,
      "51 - 75": 10,
      "76 - 100": 0
    }
  },
  RPM_time_spend: {
    title: "Car Speed Time (%)",
    data: {
      "0 - 2000": 48,
      "2001 - 4000": 51,
      "4001 - 7000": 1
    }
  },
  car_speed_time: {
    title: "Car Speed Time (%)",
    data: {
      "more than 140": 2,
      "0 - 139": 98
    }
  }
};
*/
export default function FuelAnalysis() {

  const fuelAnalysis = [];

  const { hasAccess: hasAccessToImportStdAnalysis } = useHasAccessToFeature(UserRoleAccess.canImportStdFuelAnalysis);
  const { data: customerMonthlyAnalysis = [], isLoading, isError, error } = Api.fuelAnalytics.useGetCustomerLastTwelveMonthsFuelAnalyticsQuery();
  const customerMonthlyAnalysisFound = isValidArrayWithData(customerMonthlyAnalysis);

  // console.log({customerMonthlyAnalysis})
  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (

    <GraphsSkeleton isLoading={isLoading} loadingText="Loading Fuel Analysis" graphCount={3} />

  ) : customerMonthlyAnalysisFound ? (
    <DisplayFuelAnalysis fuelAnalysis={fuelAnalysis} customerMonthlyAnalysis={customerMonthlyAnalysis} canImportStdAnalysis={hasAccessToImportStdAnalysis} />
  ) : (
    <NoRecords title="Fuel Analysis" message="No Fuel Analysis found.">
      <ImportStdAnalysisData canImportStdAnalysis={hasAccessToImportStdAnalysis} />
    </NoRecords>
  );
}

function DisplayFuelAnalysis({ canImportStdAnalysis, customerMonthlyAnalysis }) {

  const [maxFuelCost] = useState({});
  
  const [averageKmsPerLiters] = useState();
  const [averageFuelCost] = useState();
  const [totalFuelCost] = useState();

  const processedData = useMemo(() => {
    const distance = [];
    const fuel = [];
    const costPerKilometer = [];

    let totalFuelCost = 0;
    let totalFuelQuantity = 0;
    let totalKilometers = 0;
    let maxFuelCost = 0;

    customerMonthlyAnalysis.forEach((analysis) => {
      const displayDate = dateMonthYearDisplay(analysis.monthly_date);
      const kmPerLiter = analysis.kilometers / analysis.fuel_quantity;

      // Populate arrays with label-value objects
      distance.push({ label: displayDate, value: Number(analysis.kilometers || 0) });
      fuel.push({ label: displayDate, value: Number(analysis.fuel_quantity || 0) });
      costPerKilometer.push({ label: displayDate, value: Number(kmPerLiter || 0) });

      // Aggregate totals
      totalFuelCost += analysis.total_fuel_cost;
      totalFuelQuantity += analysis.fuel_quantity;
      totalKilometers += analysis.kilometers;

      // Track maximum fuel cost
      if (analysis.maximum_fuel_cost > maxFuelCost) {
        maxFuelCost = analysis.maximum_fuel_cost;
      }
    });

    // Calculate averages
    const averageFuelCost = totalFuelCost / customerMonthlyAnalysis.length;
    const averageKmPerLiter = totalKilometers / totalFuelQuantity;

    return {
      distance,
      fuel,
      costPerKilometer,
      averageFuelCost,
      averageKmPerLiter,
      maxFuelCost
    };
  }, [customerMonthlyAnalysis]);

  return (
    <Layout>
      <header className="flex flex-row justify-between px-5 rounded-sm shadow-lg bg-other col-span-full xl:col-span ">
        {/* link to driver listing page and driver name */}
        <div className="flex flex-row items-center gap-2 py-4 font-semibold text-primary">
          Fuel Analysis
        </div>
        <div className="flex">
          <div className="flex flex-row items-center gap-2 py-4 font-semibold text-primary">
            <ImportStdAnalysisData canImportStdAnalysis={canImportStdAnalysis} />
          </div>
        </div>
        {/* //TODO: Removed by Mohau till a report exists */}
        {/* download button */}
        {/*
        <div className="flex items-center">
          <button className="inline-flex items-center justify-center w-full gap-1 text-other btn-primary">
            <DownloadIcon classes="h-4 w-4 fill-current" /> Download Report (PDF)
          </button>
        </div>
  */}
      </header>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        <LineGraph title={"KPL (SA)"}

          data={processedData.costPerKilometer}
          xDataKey={"label"}
          yDataKey={"value"}
          labelKey={"label"}
          lineColor={"#10b981"}
          // className={"h-[20rem]"}

        >
          <div className="flex flex-row justify-around w-full gap-8 pt-3 border-t mt-1 *:text-center">
            <h4>
              Avg Km/L <span className="text-brand">{stringToNumber(averageKmsPerLiters, 2)}</span>
            </h4>
            <h4>
              Avg Fuel <span className="text-brand">{stringToNumber(averageFuelCost, 2)}</span>
            </h4>
            <h4>
              Max Fuel Cost <span className="text-brand">{stringToCurrency(maxFuelCost, 2)}</span>
            </h4>
            <h4>
              Total Fuel Cost <span className="text-brand">{stringToCurrency(totalFuelCost, 2)}</span>
            </h4>
          </div>

        </LineGraph>


        <BarGraphNew
          title={"Litres Consumed"}
          data={processedData.fuel}
          xDataKey={"label"}
          labelKey={"label"}
          yDataKey={"value"}
          barColor={"#fde047"}
          className={"h-[20rem]"}

        />


        <BarGraphNew
          title={"Km Driven"}
          data={processedData.distance}
          xDataKey={"label"}
          labelKey={"label"}
          yDataKey={"value"}
          barColor={"#60a5fa"}
          className={"h-[20rem]"}

        />

      </div>
    </Layout>
  );
}

function ImportStdAnalysisData({ canImportStdAnalysis }) {

  return (
    <>
      {canImportStdAnalysis ?
        <Link to="/insights/fuelAnalysis/std-import-data">
          <button type='button' className='header-button'>
            Import Standard Analysis
          </button>
        </Link> : null}
    </>
  );
}
