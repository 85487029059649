import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout, PrimaryHeader } from "../components";
import FilterSkeleton from "./FilterSkeleton";

type TablesSkeletonProps = {
  title?: string;
  rowCount?: number;
  isLoading: boolean;
};

export default function TablesSkeleton({ isLoading, title, rowCount = 15 }: TablesSkeletonProps) {
  const loadingText:string = `Loading ${title || "records"}`;
  return (
    <Layout isLoading={isLoading} loadingText={loadingText}>
      <PrimaryHeader title={title}>
        <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <FilterSkeleton />
        <div className="p-3 overflow-x-auto section-container">
          <table className="detail-body">
            {/* Table header */}
            <thead>
              <tr>
                <td>
                  <Skeleton height={30} />
                </td>
              </tr>
            </thead>
            {/* Table body */}
            <tbody>
              {/* Row */}
              {Array(rowCount)
                .fill(0)
                .map((_, i) => {
                  return <RowSkeleton row={i} key={i} />;
                })}
            </tbody>
          </table>
        </div>
        </SkeletonTheme>
      </PrimaryHeader>
    </Layout>
  );
}

type RowSkeletonProps = {
  row: number;
};

function RowSkeleton({ row }: RowSkeletonProps) {
  return (
    <tr key={row}>
      <td key={row} className="p-2">
        <Skeleton height={20} key={row} />
      </td>
    </tr>
  );
}
