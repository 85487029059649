import React from "react";
import { useEffect, useState, useRef } from "react";
import PhoneInput from "react-phone-input-2"
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import systemConfig from "../config/systemConfiguration";
import { setCredentials } from "../features/auth/authSlice";

import { useRegisterMutation, useUserRegisterQuery } from "../api/rtk/baseApi";
import { useLoginMutation } from "../api/auth/authApi";
import PasswordStrengthMeter from "../components/PasswordStrengthMeter";
import EmptyFolderIcon from "../components/icons/EmptyFolderIcon";
import Loading from "../components/Loading";
import ToggleButton from "../components/toggle/ToggleButton";
import getEntityLogo from "../core/entityLogo";


import "react-phone-input-2/lib/style.css"

export default function Register() {
  const [searchParams] = useSearchParams();
  const registrationKey = searchParams.get("registrationKey");
  const emailAddress = searchParams.get("emailAddress");
  const { data: userRegistration, isLoading } = useUserRegisterQuery({ emailAddress, registrationKey });
  const isUserRegistrationFound = userRegistration ? true : false;

  console.log({ registrationKey, emailAddress, userRegistrations: userRegistration, isUserRegistrationFound });

  return isLoading ? <Loading isLoading={isLoading} loadingText="Loading Registration" /> : isUserRegistrationFound ? <DisplayRegisterPage user={userRegistration} /> : <RegistrationNotFound />;
}

function DisplayRegisterPage({ user }) {
  const [email] = useState(user.email_address);
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [mobileNumber, setMobileNumber] = useState(user.mobile_number);
  const [customerUuId] = useState(user.customer_uuid);
  const [customerId] = useState(user.customer_id);
  const [isRiiotUser] = useState(user.is_riiot_user);
  const [createdBy] = useState(user.created_by);
  const [createdAt] = useState(user.created_at);
  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState({});

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [registrationEnabled, setRegistrationEnabled] = useState(false);
  const [isSubmittingRegistration, setIsSubmittingRegistration] = useState(false)
  const [errorMsg, setErrorMsg] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const [login] = useLoginMutation();
  const [register] = useRegisterMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const passRef = useRef();
  const passVerifyRef = useRef();
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };


  useEffect(() => {
    if (passRef?.current) {
      passRef.current.focus();
    }
  }, [user]);

  const validatePasswordVerify = (e) => {
    const { value } = e.target;
    setRegistrationEnabled(value && value === password);

    if (value !== password) {
      setConfirmPasswordError("Verify Password does not match password.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const submitRegistration = async (e) => {
    // Prevent default behavior
    e.preventDefault();
    const { registration_key: registrationKey, registration_key_expiry: registrationKeyExpiry, designation, mobile_number: mobileNumber } = user;

    if (registrationEnabled) {
      setIsSubmittingRegistration(true);
      console.log({ emailAddress: email, password, firstName, lastName, designation, mobileNumber, registrationKey, isRiiotUser, entity: { id: customerId, uuId: customerUuId }, createdAt, createdBy })
      register({ emailAddress: email, password, firstName, lastName, designation, mobileNumber, registrationKey, registrationKeyExpiry, isRiiotUser, entity: { id: customerId, uuId: customerUuId }, createdAt, createdBy })
        .unwrap()
        .then((regResponse) => {
          if (!regResponse.success) {
            setIsSubmittingRegistration(false);
            setErrorMsg("Registration cannot be completed successfully.");
          } else {
            handleLogin(email, password);
          }
        })
        .catch((err) => {
          setIsSubmittingRegistration(false);
          console.log(err);
          if (err.status === 409) {
            setErrorMsg("Email address cannot be registered(AE).");
          }
        });
    } else {
      setIsSubmittingRegistration(false);
      setPasswordError("Password does not meet requirements.");
    }
  };

  // function to auth user
  const handleLogin = async (emailAddress, password) => {
    

    try {
      //const response = await login(email, password);
      const { entity } = systemConfig;
      // setIsLoading(true);
      const userData = await login({ emailAddress, password, entity: { uuId: customerUuId } }).unwrap();


      const accessToken = userData?.accessToken;
      const fullName = userData?.fullName;
      const role = userData?.role;
      const roleInfo = userData?.roleInfo;

      dispatch(setCredentials({ accessToken, emailAddress: emailAddress, fullName, role, roleInfo, entity }));

      navigate("/");
    } catch (err) {
      console.error(err);
    } finally {
      //constsetIsLoading(false);
      navigate("/login");
    }
  };

  return (isSubmittingRegistration ? <Loading isLoading={isSubmittingRegistration} loadingText="Submitting Registration Information" /> :
    <section className="flex items-center justify-center w-screen h-screen overflow-hidden bg-background">
      <form onSubmit={submitRegistration} className="shadow-xl section-container">
        <Link to="/">
          <img src={getEntityLogo(systemConfig.entity.acronym)} alt={"logo"} height={150} width={150} className="mx-auto" />
        </Link>
        <h1 className="font-bold text-center">User Registration</h1>

        <div>
          <label htmlFor="email" className="block mb-2 text-sm font-medium text-primary">
            Email
          </label>
          <input
            name="email"
            type="email"
            id="email"
            className="input-field"
            placeholder={systemConfig.entity.dummyEmail}
            defaultValue={email}
            disabled={true}
          />
          <span className="mt-2 text-sm text-red-500">{errorMsg}</span>
        </div>

        <div>
          <label htmlFor="firstName" className="block mb-2 text-sm font-medium text-primary">
            First Name(s)
          </label>
          <input name="firstName" onChange={(e) => setFirstName(e.target.value)} type="text" id="firstName" className="input-field" placeholder="First Name" defaultValue={firstName} required />
        </div>

        <div>
          <label htmlFor="lastName" className="block mb-2 text-sm font-medium text-primary">
            Last Name
          </label>
          <input
            name="lastName"

            onChange={(e) => setLastName(e.target.value)}
            type="text"
            id="lastName"
            className="input-field"
            placeholder="Last Name"
            defaultValue={lastName}
            required
          />
        </div>

        <div>
          <label htmlFor="mobileNumber" className="block mb-2 text-sm font-medium text-primary">
            Mobile Number
          </label>
          <PhoneInput
            country={"za"}
            preferredCountries={["za"]}
            international={true}
            name="mobileNumber"
            onChange={setMobileNumber}
            id="mobileNumber"
            placeholder="mobile number"
            value={mobileNumber}
            inputClass="input-field"

          />
        </div>

        <div>
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-primary">
            Password
          </label>
          <input
            name="password"
            ref={passRef}
            onChange={(e) => {
              setPasswordError("");
              setPassword(e.target.value);
            }}
            onPaste={(e) => {
              const pastedPassword = e.clipboardData.getData("Text");


              if (pastedPassword) {
                setPassword(pastedPassword);
                setPasswordError("");
              }
            }}
            type="password"
            id="password"
            className="input-field"
            required
            autoComplete="new-password"
            
          />
          {passwordError ? <span className="mt-2 text-sm text-red-500">{passwordError}</span> : null}
        </div>
        <PasswordStrengthMeter password={password} setStrength={setPasswordStrength} />

        <div>
          <label htmlFor="passwordVerify" className="block mb-2 text-sm font-medium text-primary">
            Password Verify
          </label>
          <input
            name="passwordVerify"
            ref={passVerifyRef}
            onBlur={validatePasswordVerify}
            onPaste={(e) => {
              e.preventDefault();

              return false;
            }}
            disabled={passwordStrength?.score < 3}
            type="password"
            id="passwordVerify"
            className="input-field"
            required
            autoComplete="off"

          />
          {confirmPasswordError ? <span className="mt-2 text-sm text-red-500">{confirmPasswordError}</span> : null}
        </div>

        <div className="flex items-start mt-6 mb-6">
          <ToggleButton onToggle={handleToggle} isChecked={isChecked} required />
          <label htmlFor="terms" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            I agree with the{" "}
            <a href="#" className="text-brand hover:underline dark:text-brand-dark">
              terms and conditions
            </a>
          </label>
        </div>

        <div className="flex flex-col gap-4 mt-4">
          <button type="submit" className="w-full btn-primary">
            Register
          </button>

          <p className="text-sm">
            Need some support? Contact us at <span className="text-brand">011-886-1737</span>{" "}
          </p>
        </div>
      </form>
    </section>
  );
}

function RegistrationNotFound() {
  return (
    <section className="flex justify-center ">
      <div className="flex flex-col items-center p-4 mt-20 rounded-md w-80">
        <EmptyFolderIcon classes="h-24 w-24 fill-primary" />
        <span className="text-lg text-center capitalize text-primary">
          Registration not found on the system.<br></br>
        </span>
      </div>
    </section>
  );
}