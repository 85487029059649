import {createSlice} from "@reduxjs/toolkit";
import {hasExpired, isValidNumber} from "../../core/utilities";
import {UserAuthentication} from "../../models/user.model";
import {RootState} from "../../store/store";

const initialState: UserAuthentication = {
  user: {isSignedIn: false, token: undefined},
  signOutMode: "none",
  signingInAttempts: undefined,
  tokenExpired: false,
  refreshToken: {
    expired: undefined,
    attempts: 0,
    processStarted: undefined,
    processCompleted: undefined,
    processCompletedSuccessfully: undefined
  }
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials: (state, action) => {
      const {accessToken, id, designation, emailAddress, fullName, isRiiotAdmin, isRiiotUser, role, access, entity, tokenExpiry, refreshTokenExpiry, isRemoteLogEnabled, fleetInfo} = action.payload;
      const isSignedIn = isValidNumber(id) && accessToken && emailAddress && emailAddress.length > 3;
      if (isSignedIn) {
        //const signInDateTime =  new Date().toISOString();
        // console.log("credentials set", { isSignedIn, tokenExpiry, refreshTokenExpiry });
        state.signingInAttempts = state.signingInAttempts?.emailAddress === emailAddress ? undefined : state.signingInAttempts;
        state.signOutMode = "none";
        state.refreshToken = {attempts: 0, expired: hasExpired(refreshTokenExpiry)};
        state.tokenExpired = hasExpired(tokenExpiry);
        state.user = {
          isSignedIn,
          id,
          emailAddress,
          fullName,
          designation,
          isRiiotAdmin,
          isRiiotUser,
          role,
          access,
          entity,
          refreshTokenExpiry,
          tokenExpiry,
          token: accessToken,
          signInDateTime: new Date().toISOString(),
          isRemoteLogEnabled,
          fleetInfo
        };
      }
    },
    setUserTokenFromRefresh: (state, action) => {
      const {accessToken, tokenExpiry, refreshTokenExpiry} = action.payload;
      const isSignedIn = state.user.token ? isValidNumber(state.user.id) && accessToken && state.user.emailAddress && state.user.emailAddress.length > 3 : false;
      // console.log("set UserToken", { isSignedIn, tokenExpiry, refreshTokenExpiry, accessToken });
      state.signingInAttempts = undefined;
      state.signOutMode = "none";
      state.tokenExpired = hasExpired(tokenExpiry);
      state.refreshToken = {expired: hasExpired(refreshTokenExpiry), attempts: 0, processCompleted: true, processCompletedSuccessfully: true};
      state.user = {...state.user, isSignedIn, refreshTokenExpiry, tokenExpiry, token: accessToken};
    },
    clearSignInCredentials: state => {
      // console.log("credentials cleared");
      state.user = {isSignedIn: false, token: undefined};
      state.signingInAttempts = undefined;
    },
    setSystemSignOut: (state) => {
      // const { status } = action.payload;
      state.signOutMode = "system";
    },
    setUserSignOut: state => {
      state.signOutMode = "user";
    },
    setSignInFail: (state, action) => {
      const {emailAddress} = action.payload;
      const failedSignIn = state.signingInAttempts?.emailAddress === emailAddress ? (state.signingInAttempts ? state.signingInAttempts.failedSignInCount + 1 : 1) : 1;
      state.signingInAttempts = {emailAddress: emailAddress, failedSignInCount: failedSignIn};
    },
    clearSignInFail: state => {
      state.signingInAttempts = undefined;
    },
    validateTokenExpiry: state => {
      if (state.user.isSignedIn && state.user.token) {
        const {refreshTokenExpiry, tokenExpiry} = state.user;
        state.refreshToken.expired = hasExpired(refreshTokenExpiry);
        state.tokenExpired = hasExpired(tokenExpiry);
      }
    },
    setRefreshTokenAttempt: (state, action) => {
      state.refreshToken.attempts = action.payload.refreshTokenAttempt;
    },
    setRefreshTokenProcessStart: state => {
      state.refreshToken.processStarted = true;
      state.refreshToken.processCompleted = false;
      state.refreshToken.processCompletedSuccessfully = undefined;
    },
    setRefreshTokenProcessCompleted: (state, action) => {
      state.refreshToken.processCompleted = true;
      state.refreshToken.processCompletedSuccessfully = action.payload;
    }
  }
});

export const {setCredentials, clearSignInCredentials, setSystemSignOut, setUserSignOut, setUserTokenFromRefresh, setSignInFail, setRefreshTokenAttempt, setRefreshTokenProcessStart, setRefreshTokenProcessCompleted, clearSignInFail, validateTokenExpiry} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCurrentEmailAddress = (state: RootState) => (state.auth.user.isSignedIn ? state.auth.user.emailAddress : undefined);
export const selectCurrentEntity = (state: RootState) => (state.auth.user.isSignedIn ? state.auth.user.entity : undefined);
export const selectIsCurrentTokenExpired = (state: RootState) => {
  if (state.auth.user.isSignedIn) {
    const {token, tokenExpiry} = state.auth.user;
    const tokenExpired = token && tokenExpiry ? hasExpired(tokenExpiry) : true;

    if (tokenExpired && !state.auth.tokenExpired) {
      state.auth.tokenExpired = tokenExpired;
    }

    return tokenExpired;
  }
  return undefined;
};
