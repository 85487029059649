import React, { useMemo, useRef, useState } from "react";
import Api from "../../api";
import { AlertDialog, InfoDialog, Layout, NoRecords, OnError, PrimaryHeader } from "../../components";
import DateRangeSelector from "../../components/date/DateRangePicker";
import InputSelect from "../../components/InputSelect";
import { dateTimeMidnight, isValidArrayWithData } from "../../core/utilities";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

export default function VehiclesTripsReport() {
  const { data: vehicles = [], isLoading, isError, error } = Api.vehicles.useGetAllVehiclesQuery();
  const vehiclesWithTrips = vehicles.length > 0 ? vehicles.filter((vehicle) => vehicle.numberOfTrips > 0) : null;
  const vehiclesFound = isValidArrayWithData(vehiclesWithTrips);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} title="Vehicle Trip Report" />
  ) : vehiclesFound ? (
    <DisplayVehiclesTripsReportCreate vehiclesWithTrips={vehiclesWithTrips} />
  ) : (
    <NoRecords title="Vehicle Trip Report" message="No Vehicles found, trip reports cannot be generated." />
  );
}

function DisplayVehiclesTripsReportCreate({ vehiclesWithTrips }) {
  const selectInputRef = useRef();
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
  const [selectedVehicles, setSelectedVehicles] = useState(null);
  const [alertInfo, setAlertInfo] = useState({ show: false });
  const [informationInfo,setInformationInfo] = useState({ show: false });
  const [isLoadingInfo, setIsLoadingInfo] = useState({ status: false });
  const [postTripReport] = Api.vehicles.usePostVehiclesTripsReportMutation();

  const vehicleList = useMemo(() => {
    return vehiclesWithTrips.map((vehicle) => ({ value: vehicle?.id, label: vehicle?.vehicleRegistrationNumber, id: vehicle.id, model: vehicle?.makeAndModel }));
  }, [vehiclesWithTrips]);

  const resetForm = () => {
    setDateRange({ startDate: "", endDate: "" });
    setSelectedVehicles(null);
    selectInputRef?.current?.select?.clearValue();
  };

  const handlePdfReport = async (e) => {
    e.preventDefault();
    const vehicles = selectedVehicles.map((vehicle) => {
      return { id: vehicle.id };
    });
    const { startDate, endDate } = dateRange;
    const reportInfo = {
      vehicles,
      dateRange: {
        startDate: dateTimeMidnight(startDate),
        endDate
      }
    };

    if (!isValidDateRange(new Date(startDate), new Date(endDate))) {
      setDateRange({ startDate: "", endDate: "" });
      setAlertInfo({ show: true, message: "Date range cannot be more than 1 year" });
      return;
    } else {
      try {
        setIsLoadingInfo({ status: true, text: "Processing Vehicle Trip Report" });
        const response = await postTripReport(reportInfo).unwrap();
        setIsLoadingInfo({ status: false });

        if (response.success) {
          resetForm();
          setInformationInfo({ show: true, message: "Trip report has been generated and will be emailed to you shortly" });
          
        } else {
          setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
        }
      } catch (error) {
        setIsLoadingInfo({ status: false });
        setAlertInfo({ show: true, message: "Report could not be generated, please try again" });
        if (error.status === 403) {
          window.location.reload();
        }
      }
    }
  };

  const isValidDateRange = (start, end) => start <= end && end - start <= 365 * 24 * 60 * 60 * 1000;

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleSelectVehicle = (data) => {
    setSelectedVehicles(data);
  };

  return (
    <Layout isLoading={isLoadingInfo.status} loadingText={isLoadingInfo.text}>
      <PrimaryHeader title="Vehicle Trip Report" />
      <AlertDialog show={alertInfo.show} alertMessage={alertInfo.message} onClick={() => setAlertInfo({ show: false })} />
      <InfoDialog show={informationInfo.show} infoMessage={informationInfo.message} onClick={() => setInformationInfo({ show: false })} />
      <section className="flex items-center justify-center px-10 pt-32">
        <form className="flex flex-col w-full gap-4 xl:w-2/3" onSubmit={handlePdfReport}>
          <div>
            <span className="text-primary">Select Vehicle</span>
            <InputSelect
              ref={selectInputRef}
              required={true}
              selectionOptions={vehicleList}
              setSelectedOption={handleSelectVehicle}
              allowMultiples={true}
              placeholder={"--Select vehicle(s) --"}
              name={"vehicle_id"}
              className="text-sm font-medium"
              allowSelectAll={true}
              itemName={"Vehicle"}
            />
          </div>
          <div>
            <DateRangeSelector onChange={handleDateRangeChange} maxDate={new Date()} />
          </div>
          <div className="flex justify-end">
            <button className="btn-primary" type="submit">
              Generate Report
            </button>
          </div>
        </form>
      </section>
    </Layout>
  );
}
