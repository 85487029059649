import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Api from "../../api";
import {Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader} from "../../components";

import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import {SystemTables, Table} from "../../components/table";
import SystemFeatures from "../../constant/systemFeatures";
import {isValidArrayWithData} from "../../core/utilities";
import {getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature} from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

const PROCESS_FEATURE = SystemFeatures.VEHICLES_BATTERY;

export default function VehiclesBatteries() {
  const {data: vehiclesBatteryStateInfo = [], isLoading, isError, error} = Api.vehicles.useGetAllVehiclesBatteryStateInfoQuery();
  const vehiclesBatteryStateInfoFound = isValidArrayWithData(vehiclesBatteryStateInfo);

  console.log({vehiclesBatteryStateInfo});

  return isError ? <OnError error={error} /> : isLoading ? <TablesSkeleton isLoading={isLoading} rowCount={10} title={"Vehicles Battery Information"} /> : vehiclesBatteryStateInfoFound ? <DisplayVehiclesBatteriesInfo vehiclesBatteryStateInfo={vehiclesBatteryStateInfo} /> : <NoRecords title="Vehicles Battery Information" message="No Vehicles Battery Information found." />;
}

function DisplayVehiclesBatteriesInfo({vehiclesBatteryStateInfo}) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(vehiclesBatteryStateInfo);
  const [isFilter, setIsFilter] = useState(currentIsFilter);
  const [isFiltered, setIsFiltered] = useState(false);

  const {numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick} = usePageFilter({displayData, currentItemsPerPage});

  useEffect(() => {
    setDisplayData(vehiclesBatteryStateInfo);
  }, [vehiclesBatteryStateInfo]);

  const filterOptions = () => {
    const option = new FilterOptions();
    return option.getVehicleBatteryInfoOptions();
  };

  const handleFilterDataChange = filteredData => {
    setIsFiltered(filteredData.length !== vehiclesBatteryStateInfo.length);
    setDisplayData(filteredData);
  };

  return (
    <Layout>
      <PrimaryHeader title="Vehicles Battery Information">
        <div className="flex">
          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>
      <Filter enabled={isFilter} data={vehiclesBatteryStateInfo} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} feature={PROCESS_FEATURE} defaultFilterOptions={defaultFilterOptions} />

      <section className="detail-section">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage} recordsName={"vehiclesBatteryInfo"} isFiltered={isFiltered} totalNumberOfItems={totalNumberOfItems} />
        <Table data={displayData} pagingInfo={{dataPerPage: itemsPerPage, currentPage}} tableColumns={SystemTables.vehiclesBatteryInfo} initialSort={{key: "battery_capacity", order: "asc"}} includeSequentialId={true} outOfRange={{key: "battery_capacity", belowLimit: 50, includeNull: true}} />
        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} currentPage={currentPage} />
      </section>
    </Layout>
  );
}
