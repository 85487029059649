import csv from "csvtojson";
import {CellParser, ColumnParam} from "csvtojson/v2/Parameters";

type ConvertCsvToJsonProps = {
  csvFile: Blob;
  matchHeadingTo?: string | null;
  colParser?: {
    [key: string]: string | CellParser | ColumnParam;
  };
  headers?: string[] | null;
  noHeaders?: boolean;
  columnsToIgnore?: string[];
};

export default async function convertCsvToJson({csvFile, matchHeadingTo = null, colParser, headers = null, noHeaders = false, columnsToIgnore }: ConvertCsvToJsonProps) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      const csvData = event.target?.result as string;
      const firstLine = csvData ? csvData.split(/\r?\n/)[0] : null;

      if (matchHeadingTo) {
        if (matchHeadingTo !== firstLine) {
          reject({errorType: "File Integrity", error: "CSV File Integrity failed on header"});
        }
      }

      if (colParser && headers) {
        Promise.resolve(csv({
          noheader: noHeaders,
          colParser,
          ignoreColumns : columnsToIgnore ? new RegExp(`(${columnsToIgnore.join("|")})`) : undefined,
          headers,
          checkType: true,
          checkColumn: true
        })
          .fromString(csvData))
          .then(jsonData => {
            resolve(jsonData);
          })
          .catch(error => {
            console.error({error});
            reject({errorType: "file Content", error});
          });
      } else {
        Promise.resolve(csv()
          .fromString(csvData))
          .then(jsonData => {
            resolve(jsonData);
          })
          .catch(error => {
            reject(error);
          });
      }
    };
    reader.onerror = error => {
      reject(error);
    };
    reader.readAsText(csvFile);
  });
}
