import React, { useState, useEffect } from "react";
import ConfirmationDialog from "../modal/ConfirmationDialog";
import InfoDialog from "../modal/InfoDialog";
import AlertDialog from "../modal/AlertDialog";
import { ProcessFlowStage } from "../../constant";
import Loading from "../Loading";


const UserProcessFlowInit = {
    processId: undefined,
    alertMessage:null,
    confirmMessage: null,
    infoMessage: null,
    processMessage: null,
    stage: ProcessFlowStage.NotStarted,
    processData: null
}


const InitialProcessFlowState = {
    processId: undefined,
    alert: { show: false, message: null },
    confirm: { show: false, message: null, yes: false, no: false },
    info: { show: false, message: null },
    stage: ProcessFlowStage.NotStarted,
    foregroundProcess: {show:false, message: null}
}


function ProcessFlowDialog({onConfirm, onAlert, onInfo,processStage = ProcessFlowStage.NotStarted ,processId,confirmMessage = null ,alertMessage = null ,infoMessage = null, processingMessage = null}) {
    const [processInfo, setProcessInfo] = useState(InitialProcessFlowState);

 
    useEffect(() => {

        const { alert, info, confirm,foregroundProcess } = processInfo;
        let { stage, processId: id  } = processInfo;
        let isChanged = false;

        // id
        if (id !== processId) {
            
            id = processId;
            isChanged = true;
        }


        // Processing Message

        if (processingMessage !== foregroundProcess.message) {
            
            foregroundProcess.message = processingMessage;
            isChanged = true;
           
        }

        //ProcessFlowStage
        if (processStage !== stage) {
            const { alert, info, confirm } = processInfo;
            //const stage = processStage;
            stage = processStage;

            switch (processStage) {
                case ProcessFlowStage.Alert:
                    alert.show = true;    
                    break;
                case ProcessFlowStage.Confirm:
                    confirm.show = true;
                    break;
                
                case ProcessFlowStage.Info:
                    info.show = true;
                    break;
                
                case ProcessFlowStage.Processing:
                    foregroundProcess.show = true;
                    break;
        
                default:
                    alert.show = false;
                    confirm.show = false;
                    info.show = false;
                    foregroundProcess.show = false;
                    break;
            }

            isChanged = true;
        }
        //Messages
        if (confirm.message !== confirmMessage) {

            confirm.message = confirmMessage;
            isChanged = true;
        }
        
        if (alert.message !== alertMessage) {

            alert.message = alertMessage;
            isChanged = true;
        }
        
        if (info.message !== infoMessage) {
            info.message = infoMessage;
            isChanged = true;
        }
        
        if (isChanged) {
            setProcessInfo({ alert, info, confirm, stage, foregroundProcess, processId: id });

        }

    }, [confirmMessage, alertMessage, infoMessage, processInfo, processStage, processingMessage,processId])
    
    const handleConfirmations = ({ no = false, yes = false }) => {
        
        const { confirm, processId } = processInfo;
        const stage = yes ? ProcessFlowStage.Confirmed : ProcessFlowStage.Cancel;
        confirm.show = false;
        confirm.no = no;
        confirm.yes = yes;
        setProcessInfo({ ...processInfo, confirm, stage });

        if (onConfirm) {
            onConfirm({confirm,stage, processId})
        }
       
    }
    
    const handleNoConfirmation = () => {
        handleConfirmations({ no: true });
    }

    const handleYesConfirmation = () => {
        handleConfirmations({ yes: true });
    }

    const handleAlert = () => {
        const { alert } = processInfo;
        alert.show = false;
        setProcessInfo({ ...processInfo, alert });
        if (onAlert) {
            onAlert();
        }
    }

    const handleInfo = () => {
        const { info } = processInfo;
        info.show = false;
        setProcessInfo({ ...processInfo, info });
        if (onInfo) {
            onInfo();
        }
    }
    const { alert, info, confirm, foregroundProcess, stage } = processInfo;
    const showConfirmDialog = confirm.show && stage === ProcessFlowStage.Confirm;
    const showAlertDialog = alert.show && stage === ProcessFlowStage.Alert;
    const showInfoDialog = info.show && stage === ProcessFlowStage.Info;
    const isForeGroundProcessing = foregroundProcess.show && stage === ProcessFlowStage.Processing;

    // console.log({isForeGroundProcessing,foregroundProcess,processInfo})
    
  return (
    <div>
        <ConfirmationDialog show={showConfirmDialog} onNo={handleNoConfirmation}   onYes={handleYesConfirmation}  confirmationMessage={confirm.message} />
        <InfoDialog onClick={handleInfo} show={showInfoDialog} infoMessage={info.message} />
        <AlertDialog show={showAlertDialog} alertMessage={alert.message} onClick={handleAlert} />
        <Loading isLoading={isForeGroundProcessing}  isForegroundProcess={isForeGroundProcessing}  loadingText={foregroundProcess.message} />
    </div>
  )
}

export default ProcessFlowDialog

export {UserProcessFlowInit}