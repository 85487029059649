import React from "react"
import Skeleton from "react-loading-skeleton";


export default function FilterSkeleton() {
  return (
    <div className="flex justify-between">
    <Skeleton width={300} height={25} className="my-4 rounded-lg shadow-md" />
    <Skeleton width={300} height={25} className="my-4 rounded-lg shadow-md" />
        </div>
  )
}
