import React, { useRef, useCallback, useMemo, useState  } from "react"
import { GoogleMap, InfoBox, MarkerF, MarkerClustererF, useLoadScript } from "@react-google-maps/api"
import { useSelector } from "react-redux"
import systemConfig from "../../../config/systemConfiguration"
import { selectCurrentTheme } from "../../../features/settings/settingsSlice"
import VehicleInfo from "./VehicleInfo"

const { defaultCenter, defaultZoom,apiKey:googleMapsApiKey,libraries } = systemConfig.maps.google
const defaultMapStyleId = "cf5b3082e4d380a4" // dark 55208ae8c875e959 ; light cf5b3082e4d380a4

const SOUTHERN_AFRICA_BOUNDS = {
  north: -14.00869,
  south: -37.9887,
  west: 8.351732,
  east: 40.4785087,
}

export default function Map({ vehicles }) {
  const mapRef = useRef(null)
  const theme = useSelector(selectCurrentTheme)
  const [map, setMap] = useState(null)
  const [activeMarker, setActiveMarker] = useState(null)
 
  const { isLoaded, loadError } = useLoadScript({googleMapsApiKey,libraries})

  const options = useMemo(() => ({
    clickableIcons: false,
    keyboardShortcuts: false,
    mapTypeControl: false,
    zoomControlOptions: { position: 3 },
    streetViewControl: false,
    restriction: {
      latLngBounds: SOUTHERN_AFRICA_BOUNDS,
      strictBounds: false,
    },
    mapId: theme === "dark-theme" ? "55208ae8c875e959" : defaultMapStyleId,
  }), [theme])

  const center = useMemo(() => {
    if (vehicles && vehicles.length === 1 && vehicles[0].position) {
      return vehicles[0].position
    }
    return defaultCenter
  }, [vehicles])

  const onLoad = useCallback((map) => {
    mapRef.current = map
    setMap(map)
  }, [])

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }

  const handleInfoCloseClick = () => {
    setActiveMarker(null)
  }

  const clusterOptions = useMemo(() => ({
    imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
    gridSize: 30,
    minimumClusterSize: 3,
    averageCenter: true,
  }), [])
 
  if (loadError) {
    return <div>Error loading maps</div>
  }

  if (!isLoaded) {
    return <div>Loading maps</div>
  }

  return (
    <GoogleMap
      zoom={defaultZoom}
      center={center}
      options={options}
      onLoad={onLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerClassName="map-container"
    >
      {map && vehicles && vehicles.length > 0 && (
        <MarkerClustererF 
        options={clusterOptions}
        >
          {(clusterer) =>
            vehicles.map((vehicle) => (
              <MarkerF
                key={vehicle.id}
                position={vehicle.position}
                title={vehicle.veh_registration_no}
                clusterer={clusterer}
                onClick={() => handleActiveMarker(vehicle.id)}
                icon={{
                  path: "M171.3 96H224v96H111.3l30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192V96h81.2c9.7 0 18.9 4.4 25 12l67.2 84H272zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36H171.3c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256V368c0 17.7 14.3 32 32 32H65.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H385.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H608c17.7 0 32-14.3 32-32V320c0-65.2-48.8-119-111.8-127zm-2.9 207c-6.6 18.6-24.4 32-45.3 32s-38.7-13.4-45.3-32c-1.8-5-2.7-10.4-2.7-16c0-26.5 21.5-48 48-48s48 21.5 48 48c0 5.6-1 11-2.7 16zM160 432c-20.9 0-38.7-13.4-45.3-32c-1.8-5-2.7-10.4-2.7-16c0-26.5 21.5-48 48-48s48 21.5 48 48c0 5.6-1 11-2.7 16c-6.6 18.6-24.4 32-45.3 32z",
                  fillColor: vehicle.reported.color,
                  fillOpacity: 1,
                  scale: 0.06,
                  strokeColor: "gray",
                  strokeWeight: 2,
                }}
              >
                {activeMarker === vehicle.id && (
                  <InfoBox
                    options={{
                      closeBoxURL: "",
                      pixelOffset: new window.google.maps.Size(-140, -240),
                    }}
                    onCloseClick={handleInfoCloseClick}
                  >
                    <VehicleInfo
                      deviceId={vehicle.device_id}
                      registration={vehicle.vehicleRegistrationNumber}
                      vin={vehicle.vin}
                      makeAndModel={vehicle.makeAndModel}
                      speed={vehicle.speed}
                      reported={vehicle.reported.hours}
                      vehicleId={vehicle.id}
                    />
                  </InfoBox>
                )}
              </MarkerF>
            ))
          }
        </MarkerClustererF>
      )}
    </GoogleMap>
  )
}