import React, { useCallback, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { cn, dateDisplay, dateTimeDisplay, dateTimeOrMinimumDisplay, durationDisplay, isValidNumber, numberFormat, splitAlphaNumeric } from "../../core/utilities";
import { CameraIcon, VideoIcon } from "../icons";
import MediaModal from "../modal/MediaModal";
import TableHeaderSort, { TableSortOrder } from "./TableHeaderSort";
import TableDataTypes from "./options/TableDataTypes";
import TableColumnSelectableOptions from "./TableColumnSelectableOptions";
import Tooltip from "../Tooltip";
import { pick, first, orderBy } from "lodash";

import { DisplayBooleanRowProps, DisplayButtonRowProps, DisplayEditableBooleanRowProps, DisplayImageVideoModalRowProps, DisplayTableRowProps, PagingInfo, RowInfo, TableColumn, TableProps, TableRowProps, } from "./types/tableTypes";
import TableIcons from "./TableIcons";




function setupRowDangerInfo(rowInfo: RowInfo, row: any): { show: boolean; message: string } | undefined {
  if (rowInfo.dataDangerInfo) {
    const key = rowInfo.dataDangerInfo.key;
    const sourceValue = row[key];
    const targetValue = rowInfo.dataDangerInfo.value;
    const show = sourceValue === targetValue;
    const message = rowInfo.dataDangerInfo.message;
    return show ? { show, message } : undefined;
  }
  return undefined;
}

function getAllColumnsForRowDisplayInfo(
  row: any,
  rowTypesInfo: RowInfo[],
  identifier: string,
  onEditableBooleanAccessCheck?: (params: { key: string }) => boolean
): RowInfo[] {
  const rowData = pick(
    row,
    rowTypesInfo.map((row) => row.key)
  );
  const allRowsDisplayInfo: RowInfo[] = [];



  // console.log({ rowTypesInfo })
  for (const key in rowData) {
    const rowInfo = first(rowTypesInfo.filter((rt) => rt.key === key));
    if (!rowInfo) continue;

    const rawValue = rowData[key];
    const valueType = rowInfo.dataType;
    const isShown = rowInfo.isShown === undefined ? true : rowInfo.isShown;
    const isHidden = rowInfo.isHidden === undefined ? false : rowInfo.isHidden;
    const align = rowInfo.dataAlign ? rowInfo.dataAlign : isValidNumber(rawValue) ? "right" : "left";
    const link = rowInfo.dataLink && rowInfo.dataLink.id && row[rowInfo.dataLink.id] ? rowInfo.dataLink.path.replace("{id}", row[rowInfo.dataLink.id]) : "";
    const click = rowInfo.dataClick ? { id: row[rowInfo.dataClick.id], data: row[rowInfo.dataClick.data] } : undefined;
    const dangerInfo = setupRowDangerInfo(rowInfo, row);
    const editAllowed = checkEditableBooleanUserAccess(key);

    const rowDisplayInfo: RowInfo = {
      ...rowInfo,
      align,
      link,
      click,
      valueType,
      isHidden,
      isShown,
      editAllowed,
      identifier,
      dangerInfo,
      value: rawValue
    };

    switch (rowInfo.dataType) {
      case TableDataTypes.Boolean:
        rowDisplayInfo.value = !rawValue ? "" : typeof rawValue === "object" ? JSON.stringify(rawValue) : rawValue || "";
        break;

      case TableDataTypes.Button:
        rowDisplayInfo.value = identifier;
        rowDisplayInfo.valueObject = rowInfo.imageObject;
        rowDisplayInfo.valueKey = rowInfo.key;
        break;
      case TableDataTypes.DateTime:
        rowDisplayInfo.value = dateTimeDisplay(rawValue);
        break;
      case TableDataTypes.DateTimeOrMinimum:
        rowDisplayInfo.value = dateTimeOrMinimumDisplay(rawValue);
        break;
      case TableDataTypes.Date:
        rowDisplayInfo.value = dateDisplay(rawValue);
        break;

      case TableDataTypes.EditableBoolean:
        rowDisplayInfo.editAllowed = checkEditableBooleanUserAccess(key);
        rowDisplayInfo.value = !rawValue ? false : typeof rawValue === "object" ? JSON.stringify(rawValue) : rawValue || false;
        break;

      case TableDataTypes.Hundredth:
        rowDisplayInfo.value = numberFormat(rawValue, 2, 0);
        break;

      case TableDataTypes.Duration:
        rowDisplayInfo.value = durationDisplay(rawValue, true);
        break;

      case TableDataTypes.ImageVideoModal:
        rowDisplayInfo.rowLinkData = {
          url: row[rowInfo.imageObject.url],
          alt: row[rowInfo.imageObject.alt],
          headerOne: row[rowInfo.imageObject.headerOne],
          headerTwo: row[rowInfo.imageObject.headerTwo]
        };
        break;
      case TableDataTypes.Percentage:
        rowDisplayInfo.value = isValidNumber(rawValue) ? `${rawValue}%` : !rawValue ? "" : typeof rawValue === "object" ? JSON.stringify(rawValue) : "";
        break;

      case TableDataTypes.VehicleRegistration:
        rowDisplayInfo.value = splitAlphaNumeric(rawValue);
        break;

      default:
        rowDisplayInfo.value = !rawValue ? "" : typeof rawValue === "object" ? JSON.stringify(rawValue) : rawValue || "";
        break;
    }

    allRowsDisplayInfo.push(rowDisplayInfo);
  }

  return allRowsDisplayInfo;

  function checkEditableBooleanUserAccess(key: string): boolean {
    if (onEditableBooleanAccessCheck) {
      return onEditableBooleanAccessCheck({ key });
    } else {
      return false;
    }
  }
}

function mergeNoneDataKeysToData(tableData: any[], tableColumns: TableColumn[]): any[] {
  if (!tableData || tableData.length === 0) {
    return tableData;
  }

  const rowKeys = Object.keys(tableData[0]);
  const noneColumnKeys = tableColumns
    .map((column) => {
      return { key: column.key, dataType: column.dataType, imageObject: column.imageObject, dataLabel: column.dataLabel };
    })
    .filter((column) => {
      return !rowKeys.includes(column.key);
    });
  const mergedData = tableData.map((data) => {
    let mergedData = { ...data };
    noneColumnKeys.forEach((column) => {
      let columnData: { [key: string]: any } = {};
      columnData[column.key] = { dataType: column.dataType, imageObject: column.imageObject, dataLabel: column.dataLabel };
      // console.log({ columnData, column });
      mergedData = { ...mergedData, ...columnData };
    });
    return mergedData;
  });

  return mergedData;
}

function sequencedAndOrderedData({ tableData, sortKey, sortOrder, includeSequentialId, tableColumns }: { tableData: any[]; sortKey: string; sortOrder: "asc" | "desc"; includeSequentialId?: boolean; tableColumns: TableColumn[] }): any[] {
  const mergedData = mergeNoneDataKeysToData(tableData, tableColumns);
  const sortedData = orderBy(mergedData, [sortKey], [sortOrder]);
  if (includeSequentialId) {
    return sortedData.map((data, index) => ({
      ...data,
      __table_seq_id: index + 1
    }));
  }
  return sortedData;
}

function sortData({ tableData, sortKey, sortOrder, pagingInfo, includeSequentialId, tableColumns }: { tableData: any[]; sortKey: string; sortOrder: "asc" | "desc"; pagingInfo: PagingInfo; includeSequentialId?: boolean; tableColumns: TableColumn[] }): any[] {
  if (!sortKey) return tableData;

  const sortedData = sequencedAndOrderedData({ tableData, sortKey, sortOrder, includeSequentialId, tableColumns });
  const dataOffset = (pagingInfo.currentPage - 1) * pagingInfo.dataPerPage;

  const endOffset = dataOffset + pagingInfo.dataPerPage;
  const pagedData = sortedData?.slice(dataOffset, endOffset);

  return pagedData;
}

function setupHeader(tableColumns: TableColumn[]): { key: string; label: string; headerSort: boolean; excludeHeader: boolean; hideColumn: boolean; canHide: boolean }[] {
  if (!tableColumns) {
    return [];
  }

  const headerInfo = tableColumns.map((column) => {
    const isShown = column.isShown === undefined ? true : column.isShown;
    const isHidden = column.isHidden === undefined ? false : column.isHidden;
    return {
      key: column.key,
      label: column.label||"",
      headerSort: column.isSortable || false,
      excludeHeader: column.excludeHeader || false,
      hideColumn: isShown === false || isHidden,
      canHide: column.canHide || false
    };
  });

  return headerInfo.filter((header) => header.hideColumn === false);
}

function Table({
  tableColumns,
  data,
  pagingInfo,
  initialSort,
  onContextMenu,
  onTableRowClick,
  onTableRowDoubleClick,
  onTableDataDoubleClick,
  firstColumnCheckBox,
  lastColumnCheckBox,
  includeSequentialId,
  outOfRange,
  onSelectedChanged,
  onButtonClick,
  onEditableBooleanChange,
  onEditableBooleanAccessCheck,
  showTableColumnSelect,
  tableColumnSelectOptions,
  onColumnOptionsChange
}: TableProps) {
  const [sortKey, setSortKey] = useState(initialSort?.key || "");
  const [sortOrder, setSortOrder] = useState<TableSortOrder>(initialSort?.order || "asc");
  const [tableData, setTableData] = useState<any[]>();
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const [identifierColumn] = useState(tableColumns.find(column => column.dataIdentifier));
  const headers = setupHeader(tableColumns);

  //console.log(tableColumns);
  // console.log(tableColumns)

  const rowTypesInfo = tableColumns.map(column => ({
    key: column.key,
    dataType: column?.dataType,
    dataAlign: column.dataAlign,
    imageObject: column.imageObject,
    dataClick: column.click,
    dataLink: column.link,
    dataIcon: column.icon,
    dataLabel: column.label,
    dataDangerInfo: column.dangerInfo,
    isShown: column.isShown === undefined ? true : column.isShown,
    isHidden: column.isHidden === undefined ? false : column.isHidden,
    value: undefined,
    identifier: "",
    editAllowed: false,
    label: column.label,
  }));

  const sortedData = useCallback(() => sortData({
    tableData: data, sortKey, sortOrder, pagingInfo, includeSequentialId,
    tableColumns: rowTypesInfo
  }), [data, sortKey, sortOrder, pagingInfo, includeSequentialId, rowTypesInfo]);

  const onSort = (selectedSortKey: string) => {
    if (sortKey === selectedSortKey) {
      const order = sortOrder === "asc" ? "desc" : "asc";
      setSortOrder(order);
    } else {
      setSortKey(selectedSortKey);
      setSortOrder("asc");
    }
  };

  const onRowSelectedChange = (selected: string) => {
    let selection: string[] = [];

    if (selectedRows.includes(selected)) {
      selection = selectedRows.filter(row => row !== selected);
    } else {
      selection = [...selectedRows, selected];
    }

    setSelectedRows(selection);
    if (onSelectedChanged) {
      onSelectedChanged(selection);
    }
  };

  useEffect(() => {
    setTableData(sortedData());
  }, [data,pagingInfo,sortKey,sortOrder]);

  return (
    <div className="p-3 overflow-x-auto  section-container min-h-[60dvh]">
      <table className="detail-table">
        <thead className="relative group">
          <tr key={"thead--row"}>
            {includeSequentialId && <th>#</th>}
            {firstColumnCheckBox && <th></th>}
            {headers.map(row => (
              <th scope="col" className="py-3 px--6" key={row.key}>
                <div className="flex items-center">
                  {!row.excludeHeader && row.label}
                  <TableHeaderSort isSortable={row.headerSort} headerKey={row.key} onSort={onSort} sortOrder={sortOrder} sortKey={sortKey} />
                </div>
              </th>
            ))}

            {showTableColumnSelect && (
              <td>
                <div className="absolute right-0 z-50 invisible text-gray-600 whitespace-normal group-hover:visible -top-3">
                <TableColumnSelectableOptions columnOptions={tableColumnSelectOptions} onColumnOptionsChange={onColumnOptionsChange} />
                </div>
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((row, index) => {
            const identifier = identifierColumn?.key ? row[identifierColumn.key] : undefined;
            const isRowSelected = selectedRows && identifier ? selectedRows.includes(identifier) : false;
            const isOddRow = index % 2 === 1;

            return (
              <TableRow
                row={row}
                tableData={tableData}
                rowTypesInfo={rowTypesInfo}
                key={index}
                rowIndex={index}
                identifier={identifier}
                onContextMenu={onContextMenu}
                onTableDataDoubleClick={onTableDataDoubleClick}
                onTableRowDoubleClick={onTableRowDoubleClick}
                onTableRowClick={onTableRowClick}
                onButtonClick={onButtonClick}
                onEditableBooleanChange={onEditableBooleanChange}
                onEditableBooleanAccessCheck={onEditableBooleanAccessCheck}
                firstColumnCheckBox={firstColumnCheckBox}
                lastColumnCheckBox={lastColumnCheckBox}
                isOddRow={isOddRow}
                isRowSelected={isRowSelected}
                includeSequentialId={includeSequentialId}
                outOfRange={outOfRange}
                onRowSelectedChange={onRowSelectedChange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}



function TableRow({
  tableData,
  row,
  rowTypesInfo,
  rowIndex,
  identifier,
  onContextMenu,
  onTableDataDoubleClick,
  onTableRowDoubleClick,
  onTableRowClick,
  firstColumnCheckBox,
  lastColumnCheckBox,
  isRowSelected,
  includeSequentialId,
  outOfRange,
  onRowSelectedChange,
  onButtonClick,
  onEditableBooleanChange,
  onEditableBooleanAccessCheck,
  isOddRow
}: TableRowProps) {
  const location = useLocation();
  const onRowContextMenu = (event: React.MouseEvent) => {
    if (onContextMenu && identifier) {
      onContextMenu(event, identifier, row);
    }
  };

  const onTableDataDoubleClickEvent = (rowInfo: RowInfo) => {
    if (onTableDataDoubleClick && identifier) {
      onTableDataDoubleClick(rowInfo, identifier);
    }
  };

  const onTableRowDoubleClickEvent = () => {
    if (onTableRowDoubleClick && identifier) {
      onTableRowDoubleClick(row, identifier);
    }
  };

  const onTableRowClickEvent = (rowInfo: RowInfo) => {
    if (onTableRowClick && rowInfo.click && identifier) {
      onTableRowClick(rowInfo.click, identifier);
    }
  };

  const checkIfOutOfRange = (row: any): boolean => {
    if (!outOfRange) {
      return false;
    }

    if (outOfRange.includeNull && row[outOfRange.key] === null) {
      return true;
    }

    if (outOfRange.belowLimit !== undefined) {
      return outOfRange.belowLimit > row[outOfRange.key];
    }

    if (outOfRange.aboveLimit !== undefined) {
      return outOfRange.aboveLimit < row[outOfRange.key];
    }
    return false;
  };

  const isOutOfRange = checkIfOutOfRange(row);


  return (
    <tr
      key={identifier}
      className={cn("table-row hover:bg-brand text-primary hover:text-other", { "bg-muted": isOddRow })}
      onContextMenu={onRowContextMenu}
      onDoubleClick={onTableRowDoubleClickEvent}
    >
      {includeSequentialId &&
        <td key={row["__table_seq_id"]} className="p-2">
          <div className="float-right">{row["__table_seq_id"]}</div>
        </td>
      }
      {firstColumnCheckBox &&
        <td className="w-4 p-4" key={identifier}>
          <div className="flex items-center">
            <input
              id="checkbox-table-option"
              type="checkbox"
              checked={isRowSelected}
              onChange={() => onRowSelectedChange(identifier || "")}
              className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="checkbox-table-option" className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      }

      {getAllColumnsForRowDisplayInfo(row, rowTypesInfo, identifier || "", onEditableBooleanAccessCheck)
        .filter((rowInfo) => rowInfo.isHidden === false && rowInfo.isShown)
        .map((rowInfo, columnIndex) => (
          <td key={columnIndex} className="p-2 group" data-row-identifier={identifier} onDoubleClick={() => onTableDataDoubleClickEvent(rowInfo)}>
            {rowInfo.link && rowInfo.value ? (
              <div className="relative flex items-center justify-center gap-1">
                  <DisplayTableRow tableData={tableData} rowTypesInfo={rowTypesInfo} rowInfo={rowInfo} isOutOfRange={isOutOfRange} rowIndex={rowIndex} columnIndex={columnIndex} />
                <Link to={rowInfo.link} state={{ from: location }}>                  
                
                  <div className="absolute invisible p-1 rounded-full group-hover:visible -right-4 -top-2 bg-background">
                    <span className="text-primary bg-tertiary">
                      <TableIcons icon={rowInfo.dataIcon} />
                    </span>
                  </div>
                  </Link>
                </div>
            ) : (
              <DisplayTableRow
                tableData={tableData}
                rowTypesInfo={rowTypesInfo}
                rowInfo={rowInfo}
                isOutOfRange={isOutOfRange}
                rowIndex={rowIndex}
                columnIndex={columnIndex}
                onButtonClick={onButtonClick}
                onEditableBooleanChange={onEditableBooleanChange}
                onClickEvent={() => onTableRowClickEvent(rowInfo)}
              />
            )}
          </td>
        ))}
      {!firstColumnCheckBox && lastColumnCheckBox &&
        <td className="w-4 p-4" key={"last-column-checkbox"}>
          <div className="flex items-center">
            <input
              id="checkbox-table-option"
              type="checkbox"
              checked={isRowSelected}
              onChange={() => onRowSelectedChange(identifier || "")}
              className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="checkbox-table-option" className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      }
    </tr>
  );
}



function DisplayTableRow({ tableData, rowTypesInfo, rowInfo, isOutOfRange, onButtonClick, onClickEvent, onEditableBooleanChange, rowIndex, columnIndex }: DisplayTableRowProps) {
  const { dataType: valueType, rowLinkData, dataAlign: align, click, value } = rowInfo;
  const isDangerRow = rowInfo?.dangerInfo && rowInfo.dangerInfo.show ? true : false;
  const dangerMessage = rowInfo?.dangerInfo && isDangerRow ? rowInfo.dangerInfo.message : null;

  return (
    <div>
      {valueType === TableDataTypes.ImageVideoModal ? (
        <DisplayImageVideoModalRow tableData={tableData} rowTypesInfo={rowTypesInfo} rowLinkData={rowLinkData} rowIndex={rowIndex} columnIndex={columnIndex} />
      ) : valueType === TableDataTypes.Boolean ? (
        <DisplayBooleanRow rowInfo={value as boolean} />
      ) : valueType === TableDataTypes.EditableBoolean ? (
        <DisplayEditableBooleanRow rowInfo={rowInfo} onEditableBooleanChange={onEditableBooleanChange} />
      ) : valueType === TableDataTypes.Button ? (
        <DisplayButtonRow rowInfo={rowInfo} onButtonClick={onButtonClick} />
      ) : (
        <Tooltip message={dangerMessage||""} enabled={isDangerRow && dangerMessage!== null} position="top" icon="warning" >
          <div onClick={onClickEvent} className={cn({
            "cursor-pointer": click,
            "text-red-500 font-semibold hover:text-other": isOutOfRange || isDangerRow,
            "float-right": align === "right",
            "float-left": align === "left"
          })}>
            {value}
          </div>
        </Tooltip>
      )}
    </div>
  );
}



function DisplayButtonRow({ rowInfo, onButtonClick }: DisplayButtonRowProps) {
  const { dataLabel: label, key: buttonKey, value: id } = rowInfo;

  return (
    <button className="border-1 btn border-brand hover:opacity-80" onClick={() => onButtonClick && onButtonClick({ buttonKey, id: id as string })}>
      {label}
    </button>
  );
}



function DisplayImageVideoModalRow({ tableData, rowTypesInfo, rowLinkData, rowIndex, columnIndex }: DisplayImageVideoModalRowProps) {
  const [modalRowIndex, setModalRowIndex] = useState(rowIndex);
  const [modalRowLinkData, setModalRowLinkData] = useState(rowLinkData);
  const [modalVisible, setModalVisible] = useState(false);
  const [previousNextInfo, setPreviousNextInfo] = useState({ previousMediaExists: rowIndex > 0, nextMediaExists: rowIndex < tableData.length - 1 });

  const changeModalRowIndex = (rowIndex: number) => {
    const tableRow = tableData[rowIndex];
    const rows = getAllColumnsForRowDisplayInfo(tableRow, rowTypesInfo, "");
    const rowLinkData = rows[columnIndex].rowLinkData;

    setModalRowIndex(rowIndex);
    setModalRowLinkData(rowLinkData);
    setPreviousNextInfo({ previousMediaExists: rowIndex > 0, nextMediaExists: rowIndex < tableData.length - 1 });
  };

  const handleMediaModalPreviousClick = () => {
    const rowIndex = modalRowIndex > 0 ? modalRowIndex - 1 : 0;

    if (rowIndex !== modalRowIndex) {
      changeModalRowIndex(rowIndex);
    }
  };

  const handleMediaModalNextClick = () => {
    const rowIndex = modalRowIndex < tableData.length - 1 ? modalRowIndex + 1 : modalRowIndex;

    if (rowIndex !== modalRowIndex) {
      changeModalRowIndex(rowIndex);
    }
  };

  const handleDivClick = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const getFileExtension = (url: string): string => {
    return url.split(".").pop()?.toLowerCase() || "";
  };

  const isImage = (url: string): boolean => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const extension = getFileExtension(url);
    return imageExtensions.includes(extension);
  };

  const isVideo = (url: string): boolean => {
    const videoExtensions = ["mp4", "avi", "mov", "mkv", "wmv"];
    const extension = getFileExtension(url);
    return videoExtensions.includes(extension);
  };

  return (
    <>
      {rowLinkData ? (
        <div className="flex items-center space-x-2" onClick={handleDivClick}>
          {isImage(rowLinkData.url) ? <CameraIcon color="var(--color-primary)" /> : null}
          {isVideo(rowLinkData.url) ? <VideoIcon color="var(--color-primary)" /> : null}
          <span>{rowLinkData.alt}</span>
        </div>
      ) : (
        <div></div>
      )}

      {modalVisible && modalRowLinkData && (
        <MediaModal
          closeModal={closeModal}
          rowLinkData={modalRowLinkData}
          onPreviousClick={handleMediaModalPreviousClick}
          onNextClick={handleMediaModalNextClick}
          previousNextInfo={previousNextInfo}
        />
      )}
    </>
  );
}



function DisplayBooleanRow({ rowInfo }: DisplayBooleanRowProps) {
  return (
    <div className="flex items-center">
      <input
        id="checkbox-table-option"
        type="checkbox"
        checked={rowInfo}
        readOnly={true}
        className="w-4 h-4 rounded text-secondary bg-disabled border-disabled focus:ring-brand focus:ring-2"
      />
      <label htmlFor="checkbox-table-option" className="sr-only">
        checkbox
      </label>
    </div>
  );
}



function DisplayEditableBooleanRow({ rowInfo, onEditableBooleanChange }: DisplayEditableBooleanRowProps) {
  const { key, value, identifier, editAllowed } = rowInfo;

  const handleValueChange = () => {
    if (onEditableBooleanChange && editAllowed) {
      onEditableBooleanChange({ id: identifier, key, value: !value });
    }
  };

  return (
    <div className="flex items-center row-checkbox">
      <input
        id={`checkbox-${identifier}-${key}`}
        type="checkbox"
        checked={value as boolean}
        readOnly={!editAllowed}
        onChange={handleValueChange}
        className={cn(
          "w-4 h-4 rounded",
          editAllowed
            ? "text-brand focus:ring-brand focus:ring-2"
            : "text-secondary bg-disabled border-disabled focus:ring-2 focus:ring-disabled"
        )}
      />
      <label htmlFor={`checkbox-${identifier}-${key}`} className="sr-only">
        {key}
      </label>
    </div>
  );
}

export function initializeTableColumns(columns: TableColumn[], currentHiddenColumns?: string[]): TableColumn[] {
  const currentColumnsValid = currentHiddenColumns && Array.isArray(currentHiddenColumns);

  const tableColumns = columns.map((column) => ({
    ...column,
    isShown: column.isShown === undefined ? true : column.isShown,
    isHidden: column.isHidden === undefined
      ? (currentColumnsValid ? currentHiddenColumns.includes(column.key) : false)
      : column.isHidden
  }));

  return tableColumns;
}

export default Table;