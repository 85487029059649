import systemConfig from "../config/systemConfiguration";

const API_KEY = systemConfig.maps.google.apiKey;

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export async function getLocationName(coordinates:Coordinates) : Promise<GeocodeResponse> {
  return new Promise((resolve, reject) => {
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.latitude},${coordinates.longitude}&key=${API_KEY}`)
      .then(response => {
        response.json().then(data => {
          if (data.status === "OK" && data.results.length > 0) {
            resolve(data.results);
          } else {
            reject("Location details not found");
          }
        });
      })
      .catch(error => {
        reject(error);
      });
  });
}

interface GeocodeResponse {
  results: GeocodeResult[];
  status: string;
}

interface GeocodeResult {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: Geometry;
  place_id: string;
  types: string[];
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface Geometry {
  location: Location;
  location_type: string;
  viewport: Viewport;
}

interface Location {
  lat: number;
  lng: number;
}

interface Viewport {
  northeast: Location;
  southwest: Location;
}
