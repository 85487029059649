import React,{useEffect, useState} from "react"
import { SystemTheme } from "../models/other.model";
import getEntityLogo from "../core/entityLogo";
import systemConfig from "../config/systemConfiguration";

type LayoutHeaderImageProps = {
    theme: SystemTheme,
    entityAcronym: string
}

function LayoutHeaderImage({ theme, entityAcronym }: LayoutHeaderImageProps) {
    const [logoImage, setLogoImage] = useState(getEntityLogo(systemConfig.entity.acronym, theme));
    const largerImage = entityAcronym === "PLKM";

    /*
    const logoImage = useMemo(() => { 
        console.log({ entityAcronym, theme });
        console.log({ theme, image: getEntityLogo(systemConfig.entity.acronym, theme) });
        return getEntityLogo(systemConfig.entity.acronym,theme);
    }, [theme]);
    */
    
    useEffect(() => {

        setLogoImage(getEntityLogo(systemConfig.entity.acronym, theme));
     }, [theme, entityAcronym]);
    
  return (
    <img src={logoImage} alt="logo" className={`${largerImage ? "m-2 h-16 w-32" : "h-12 w-12 mt-2"}`} />
  )
}

export default LayoutHeaderImage