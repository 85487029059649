import { useState, useEffect } from "react";


type PageFilterProps = {
  displayData: any[];
  currentItemsPerPage?: number;
};

function usePageFilter({ displayData,currentItemsPerPage = 10}: PageFilterProps) {
    const [itemsPerPage, setItemsPerPage] = useState(currentItemsPerPage);
    const [itemsOnCurrentPage, setItemsOnCurrentPage] = useState<any[]>([]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfItemsOnCurrentPage, setNumberOfItemsOnCurrentPage] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [totalNumberOfItems, setTotalNumberOfItems] = useState(displayData?.length);

  // used with pagination
  useEffect(() => {
    setItemsPerPage(currentItemsPerPage||10);
  }, [currentItemsPerPage, displayData]);
    
  useEffect(() => {

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = displayData?.slice(itemOffset, endOffset);
    setItemsOnCurrentPage(currentItems);
    setNumberOfPages(Math.ceil(displayData?.length / itemsPerPage));
    setTotalNumberOfItems(displayData?.length);
    setNumberOfItemsOnCurrentPage(currentItems?.length);
    // console.log({endOffset,currentItems,itemsOnCurrentPage})
  }, [itemOffset, itemsPerPage, displayData]);    
    

  const resetPageOffsets = (pageOffset: number, itemsPerPage: number) => {
    const newOffset = (pageOffset * itemsPerPage) % displayData?.length;
    setCurrentPage(pageOffset + 1);
    setItemOffset(newOffset);
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event:any) => {
    /*    const newOffset = (event.selected * itemsPerPage) % displayData?.length;
    setCurrentPage(event.selected + 1);
    setItemOffset(newOffset);
    */
    resetPageOffsets(event.selected, itemsPerPage);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = displayData?.slice(itemOffset, endOffset);
    setItemsOnCurrentPage(currentItems);
    console.log({currentItems});

  };

  const handleItemsPerPageChange = (selectedItemsPerPage: number) => {
    resetPageOffsets(0, selectedItemsPerPage);
    setItemsPerPage(selectedItemsPerPage);
  };
  
    
  return {
    itemsOnCurrentPage, numberOfItemsOnCurrentPage, itemsPerPage, totalNumberOfItems, currentPage, numberOfPages,
    handleItemsPerPageChange, handlePageClick
  };
}

export default usePageFilter;