import {api} from "./api";

export const userRegistrationsApi = api.injectEndpoints({
  endpoints: builder => ({
    getAllUserRegistrations: builder.query({
      query: () => "/userRegistrations",
      keepUnusedDataFor: 5
    }),
    getUserRegistration: builder.query({
      query: id => `/userRegistrations/${id}`
    }),
    getForRegistrationAllExistingEmailAddresses: builder.query({
      query: () => "/userRegistrations/existingEmailAddresses"
    }),
    getForRegistrationDoesEmailAddressExist: builder.query({
      query: emailAddress => `/userRegistrations/userExists?emailAddress=${emailAddress}`
    }),
    resetUserRegistrationKeyExpiry: builder.mutation({
      query: emailAddress => ({
        url: "/userRegistrations/resetKeyExpiry",
        method: "PUT",
        body: {emailAddress}
      })
    }),
    resendUserRegistrationEmail: builder.mutation({
      query: emailAddress => ({
        url: "/userRegistrations/resendRegistrationEmail",
        method: "POST",
        body: {emailAddress}
      })
    })
  })
});

export const {useGetAllUserRegistrationsQuery, useGetUserRegistrationQuery, useGetForRegistrationAllExistingEmailAddressesQuery, useResetUserRegistrationKeyExpiryMutation, useResendUserRegistrationEmailMutation, useLazyGetForRegistrationDoesEmailAddressExistQuery, useGetForRegistrationDoesEmailAddressExistQuery} = userRegistrationsApi;
