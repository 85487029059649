import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Api from "../../api";
import {Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader, Vehicle} from "../../components";

import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import SystemFeatures from "../../constant/systemFeatures";
import UserRoleAccess from "../../constant/userRoleAccess";
import {isValidArrayWithData} from "../../core/utilities";
import {getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature, setIsFilterFeature, setPerPageFeature} from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import usePageFilter from "../../hooks/usePageFilter";
import VehiclesSkeleton from "../../skeletons/VehiclesSkeleton";

const PROCESS_FEATURE = SystemFeatures.VEHICLES;

export default function Vehicles() {
  const {data: vehicles = [], isLoading, isError, error} = Api.vehicles.useGetAllVehiclesQuery();
  const vehiclesFound = isValidArrayWithData(vehicles);
  const {hasAccess: hasAccessToAddVehicle} = useHasAccessToFeature(UserRoleAccess.vehicleAdd);

  console.log({vehicles, hasAccessToAddVehicle});

  return isError ? <OnError error={error} /> : isLoading ? <VehiclesSkeleton isLoading={isLoading} vehicleCount={15} /> : vehiclesFound ? <DisplayVehicles vehicles={vehicles} canAddVehicle={hasAccessToAddVehicle} /> : <NoRecords title="Vehicles" message="No Vehicles found." />;
}

function DisplayVehicles({vehicles, canAddVehicle}) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(vehicles);
  const [isFilter, setIsFilter] = useState(currentIsFilter);
  const [isFiltered, setIsFiltered] = useState(false);
  const {itemsOnCurrentPage, itemsPerPage, numberOfPages, totalNumberOfItems, currentPage,  handleItemsPerPageChange, handlePageClick} = usePageFilter({
    displayData,
    currentItemsPerPage
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPerPageFeature({name: PROCESS_FEATURE, perPage: itemsPerPage}));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({name: PROCESS_FEATURE, isFilter}));
  }, [dispatch, isFilter]);

  const handleFilterDataChange = filteredData => {
    setIsFiltered(filteredData.length !== vehicles.length);
    setDisplayData(filteredData);
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getVehicleOptions(defaultFilterOptions);
  };

  console.log({vehicles, itemsOnCurrentPage, displayData});

  return (
    <Layout loadingText="Loading Vehicles">
      <PrimaryHeader title="Vehicles">
        <div className="flex flex-row">
          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
          <AddVehicleButton canAddVehicle={canAddVehicle} />
        </div>
      </PrimaryHeader>
      <Filter enabled={isFilter} data={vehicles} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} feature={PROCESS_FEATURE} defaultFilterOptions={defaultFilterOptions} />

      <section className="detail-section">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} isFiltered={isFiltered} recordsName={"Vehicles"} totalNumberOfItems={totalNumberOfItems} />

        <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5">
          {itemsOnCurrentPage.map(vehicle => (
            <Vehicle key={vehicle?.id} id={vehicle?.id} vehicle={vehicle} />
          ))}
        </div>

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} currentPage={currentPage} />
      </section>
    </Layout>
  );
}

function AddVehicleButton({canAddVehicle}) {
  return (
    <>
      {canAddVehicle ? (
        <Link to="/vehicles/new-vehicle">
          <button type="button" className="header-button">
            Add Vehicle
          </button>
        </Link>
      ) : null}
    </>
  );
}
