import {useState } from "react";
import {  useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../api";
import { AlertDialog, ConfirmationDialog, Layout, OnError } from "../../components";
import UserRoleAccess from "../../constant/userRoleAccess";
import { getNameFromUrlPath } from "../../core/utilities";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import VehicleDetailsSkeleton from "../../skeletons/VehicleDetailsSkeleton";
import Breadcrumb from "../../components/Breadcrumb";
import InfoDialog from "../../components/modal/InfoDialog";
import { selectPreviousPage } from "../../features/settings/settingsSlice";



const foregroundRunReset = () => {
  return { running: false }
}

const dialogReset = () => {
  return {show: false}
}

const confirmReset = () => {
  return {confirm: false}
}

export default function VehicleFleetDetails() {
  // need to fetch data for this vehicle based on the id passed in the params
  const { vehicleFleetId } = useParams();
  
  
  const { hasAccess: hasAccessToUpdateVehicleFleet } = useHasAccessToFeature(UserRoleAccess.vehicleFleetUpdate);
  const { hasAccess: hasAccessToCreateFleet } = useHasAccessToFeature(UserRoleAccess.vehicleFleetCreate);
  

  const { data: vehicleFleet, isLoading, isError, error } = Api.vehicles.useGetVehicleFleetQuery(vehicleFleetId);
  const navigationPath = useSelector(selectPreviousPage);


  const fromLocation = {
    path: navigationPath.previous  || "/vehicles-fleets",
    name: getNameFromUrlPath(navigationPath.previous ) || "Vehicle Fleets"
  };

console.log({vehicleFleet,isLoading,isError,error,hasAccessToUpdateVehicleFleet,hasAccessToCreateFleet,fromLocation})  

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <VehicleDetailsSkeleton isLoading={isLoading} />
  ) : (
        <DisplayVehicleFleetDetails vehicleFleet={vehicleFleet} hasAccessToCreateFleet={hasAccessToCreateFleet}
         hasAccessToUpdateVehicleFleet={hasAccessToUpdateVehicleFleet}
          fromLocation={fromLocation} />
  );
}

function DisplayVehicleFleetDetails({ vehicleFleet, hasAccessToUpdateVehicleFleet,hasAccessToCreateVehicleFleet, fromLocation }) {
  
  
  const [commandConfirmation, setCommandConfirmation] = useState(confirmReset());
  const [commandConfirmCycle, setCommandConfirmCycle] = useState({});
  const [alert, setAlert] = useState(dialogReset());
  const [info, setInfo] = useState(dialogReset());
  const fgRun  = foregroundRunReset();
  
  const fleetName = vehicleFleet.name

  

  const handleInfoDialogClose = () => {
    setInfo(dialogReset());
  }





  const handleNegativeConfirmation = () => {
    const { confirmState, confirmId } = commandConfirmCycle;
    setCommandConfirmation(confirmReset());
    if (confirmState === "AwaitingConfirmation") {
      setCommandConfirmCycle({confirmState: "NegativeConfirmation", confirmId});
    }
  };

  const handlePositiveConfirmation = () => {
    const { confirmState, confirmId } = commandConfirmCycle;
    setCommandConfirmation(confirmReset());
    if (confirmState === "AwaitingConfirmation") {
      setCommandConfirmCycle({confirmState: "PositiveConfirmation", confirmId});
    }
  };

  const breadcrumbItems = () => {
    const items = [];
    if (fromLocation && fromLocation.name) {
      items.push({ name: fromLocation.name, path: fromLocation.path }); 
      
    }
    else {
      items.push({ name: "Vehicle Fleets", path: "/vehicles/vehicle-fleets" });
    }
    items.push({ name: `Fleet ${fleetName}`, path: `/vehicles/${vehicleFleet.id}` });

    return items;
  }


  
  

  // console.log({ vehicleSmsTracking, toggleSmsTrackingView,toggleOverview });

  return (
    <Layout isForegroundProcess={fgRun.running} loadingText={fgRun.message}>
      <header className="flex flex-row justify-between pr-4 rounded-sm shadow-lg bg-other col-span-full xl:col-span">
      <Breadcrumb items={breadcrumbItems()} />
      <div className="flex flex-row items-center gap-2 px-5 py-4 font-semibold text-primary">
              <p className="inline-flex items-center group">
                  Vehicle Fleet  
              </p>
            </div>
        <div className="flex flex-col justify-start gap-4 md:items-center md:flex-row">
          {hasAccessToUpdateVehicleFleet && (
            <div className="flex">
              <button
                type="button"
                onClick={()=> console.log("Update clicked")}
                className="inline-flex items-center gap-4 px-3 mx-3 text-xs border rounded-md border-warn text-primary hover:text-warn py-1.5"
              >
                Update
              </button>
            </div>
          )}
          {hasAccessToCreateVehicleFleet && (
            <div className="flex">
              <button
                type="button"
                onClick={() => console.log("Create clicked")}
                className="inline-flex items-center gap-4 px-3 mx-3 text-xs border rounded-md border-delete text-primary hover:text-delete py-1.5"
              >
                {vehicleFleet.is_immobile ? "Enable" : "Disable"}
              </button>
            </div>
          )}
        </div>
        {/* buttons */}
        <div className="flex flex-col gap-4 md:items-center md:flex-row">

        </div>
      </header>
<div>Fleet info</div>
      
      <ConfirmationDialog show={commandConfirmation.confirm} onNo={handleNegativeConfirmation} onYes={handlePositiveConfirmation} confirmationMessage={commandConfirmation.message} />
      <AlertDialog show={alert.show} alertMessage={alert.message} onClick={() => setAlert(dialogReset())} />
      <InfoDialog show={info.show} infoMessage={info.message} onClick={handleInfoDialogClose} />
    </Layout>
  );
}
