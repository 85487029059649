import {useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {Layout, OnError, PageFilter, Pagination, PrimaryHeader} from "../../components";
import {getFieldFromObject, getNameFromUrlPath} from "../../core/utilities";

import Api from "../../api";
import FilterButton from "../../components/filter/FilterButton";
import {SystemTables, Table} from "../../components/table";

import {selectPreviousPage} from "../../features/settings/settingsSlice";
import usePageFilter from "../../hooks/usePageFilter";
import {DbUser, DbUserAuditTrail} from "../../models/user.model";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

function UserDetails() {
  const {userId} = useParams();
  const {data: userInfo, isLoading, isError, error} = Api.users.useGetUserQuery(userId);
  const navigationPath = useSelector(selectPreviousPage);

  const fromLocation = {
    path: navigationPath.previous || "/users",
    name: getNameFromUrlPath(navigationPath.previous) || "Users"
  };
  //console.log({device});

  return isError ? <OnError error={error} /> : isLoading ? <TablesSkeleton title={"Error"} /> : <DisplayUserDetails userInfo={userInfo} fromLocation={fromLocation} />;
}

/*
type DisplayUserDetailsProps = {
  userInfo: { user: DbUser, auditTrails: DbUserAuditTrail[] },
  fromLocation: { path: string, name: string }
}
*/

function DisplayUserDetails({userInfo, fromLocation}) {
  const [isFilter, setIsFilter] = useState(false);

  const {numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick} = usePageFilter({displayData: userInfo});
  const navigate = useNavigate();
  const {userId} = useParams();
  const fullName = userInfo?.user?.fullname;

  const handleTableDoubleClick = (row, identifier) => {
    console.log({identifier, row});
    const dashboardUrl = getFieldFromObject(row, "dashboardUrl");
    const paramId = getFieldFromObject(row, "paramId");

    if (dashboardUrl) {
      const url = paramId ? `${dashboardUrl}/${paramId}` : dashboardUrl;
      navigate(url);
    }
  };

  const breadcrumbItems = () => {
    const items = [];
    if (fromLocation && fromLocation.name) {
      items.push({name: fromLocation.name, path: fromLocation.path});
    } else {
      items.push({name: "Users", path: "/users"});
    }
    items.push({name: `${fullName}`, path: `/users/${userId}`});

    return items;
  };

  console.log({at: userInfo.auditTrails, user: userInfo.user});

  return (
    <Layout>
      <PrimaryHeader breadcrumbItems={breadcrumbItems()}>
        <div className="flex">
          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>
      {/** !!!TODO  Mohau you need to enable filter,  remove the nulls below (autoFilterValues and defaultFilterOptions)*/}
      {/** 
      <Filter enabled={false}
        data={userInfo.auditTrails}
        feature={"UserAuditTrails"}
        filterOptions={filterOptions()}
        handleFilterDataChange={handleFilterDataChange}
        autoFilterValues={null}
        defaultFilterOptions= {null}
      />*/}

      <section className="detail-section">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage} recordsName={"User Audit Trails"} isFiltered={false} totalNumberOfItems={totalNumberOfItems} />
        {/** !!!TODO  Mohau you need to remove the nulls below (onContextMenu, onTableRowClick, onTableDataDoubleClick, firstColumnCheckBox,lastColumnCheckBox, outOfRange, onSelectedChanged, onButtonClick,onEditableBooleanChange, onEditableBooleanAccessCheck) */}
        <Table data={userInfo} pagingInfo={{dataPerPage: itemsPerPage, currentPage}} tableColumns={SystemTables.userAuditTrails} initialSort={{key: "timestamp", order: "desc"}} onTableRowDoubleClick={handleTableDoubleClick} includeSequentialId={true} onContextMenu={null} onTableRowClick={null} onTableDataDoubleClick={null} firstColumnCheckBox={null} lastColumnCheckBox={null} outOfRange={null} onSelectedChanged={null} onButtonClick={null} onEditableBooleanChange={null} onEditableBooleanAccessCheck={null} />

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} currentPage={currentPage} />
      </section>
    </Layout>
  );
}

export default UserDetails;
