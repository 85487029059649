import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "../../react-date-range-theme.css"; // theme css file
import { getDateYearsFromNow } from "../../core/utilities";

type DateRangeInputProps = {
    onChange?: (date: { startDate: Date; endDate: Date }) => void;
    maxDate?: Date;
};

type DateRanges = {
    range1?: {
        startDate: Date;
        endDate: Date};
    selection?: {
        startDate: Date;
        endDate: Date};
};

type DateSelectionRange = {
    startDate: Date;
    endDate: Date;
    key?: string;
 
}

const DateRangeInput = ({ onChange, maxDate = getDateYearsFromNow(5) }: DateRangeInputProps) => {
    const [selectionRange, setSelectionRange] = useState<DateSelectionRange>({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    const [showPicker, setShowPicker] = useState(false);

    const handleSelect = (ranges:DateRanges) => {

        if (ranges.selection) {
            const startDate = new Date(ranges.selection.startDate);
            const endDate = maxDate < new Date(ranges.selection.endDate) ? maxDate : new Date(ranges.selection.endDate);  
            setSelectionRange({
                startDate,
                endDate
            });
        
            if (onChange) {
                onChange({
                    startDate,
                    endDate
                });
            }
        }
        else if (ranges.range1) {
            const startDate = new Date(ranges.range1.startDate);
            const endDate = maxDate < new Date(ranges.range1.endDate) ? maxDate : new Date(ranges.range1.endDate);  
            setSelectionRange({
                startDate,
                endDate
            });
        
            if (onChange) {
                onChange({
                    startDate,
                    endDate
                });
            }
        }
        else
        {
            
            setSelectionRange({
                startDate: new Date(),
                endDate: new Date()
            });
            }
        

    };

    //console.log({selectionRange});

    return (
        <div className="relative">
            <h1 className="text-primary">Select a Date</h1>
            <input
                type="text"
                value={selectionRange ? `${selectionRange.startDate.toDateString()} - ${selectionRange.endDate.toDateString()}` : `${new Date().toDateString()} - ${new Date().toDateString()}` }
                onClick={() => setShowPicker(true)} // Open the date picker when the input is clicked
                readOnly
                className="w-full px-4 py-2 bg-transparent border rounded text-primary border-secondary hover:cursor-pointer focus:outline-brand focus:border-brand focus:ring-0"
            />
            {showPicker && (
                <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-800 bg-opacity-50">
                    <div className="p-6 bg-white rounded-lg">
                        <DateRangePicker
                            ranges={selectionRange ? [selectionRange] : []}
                            onChange={handleSelect}
                            maxDate={maxDate}
                            className="w-full"
                        />
                              
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setShowPicker(false)}
                                className="px-3 py-1 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateRangeInput;
