import React from "react";

import CarIcon from "../icons/CarIcon";
import SimIcon from "../icons/SimIcon";
import VehicleDeviceIoT from "../icons/CarDevice";

export type SystemTableIcons = "sim" | "vehicleDeviceIoT" | "car";

type TableIconsProps = {
    icon?: SystemTableIcons,
    classes?: string
 };

function TableIcons({ icon, classes }: TableIconsProps) {
    return (
        icon === "sim" ? <SimIcon /> :
        icon === "vehicleDeviceIoT" ? <VehicleDeviceIoT classes={classes ? classes :""} /> :
            icon === "car" ? <CarIcon /> : null
        );
}

export default TableIcons;