import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from "redux-persist";
import storage from "redux-persist/lib/storage";

import {api} from "../api/rtk/api";
import {baseApi} from "../api/rtk/baseApi";
import authReducer from "../features/auth/authSlice";
import settingsReducer from "../features/settings/settingsSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage
};

const rootReducer = combineReducers({
  settings: persistReducer({key: "settings", storage}, settingsReducer),
  auth: persistReducer(persistConfig, authReducer),
  api: api.reducer,
  authApi: baseApi.reducer
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
      .concat(api.middleware)
      .concat(baseApi.middleware),
  devTools: process.env.NODE_ENV !== "production"
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
