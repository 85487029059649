import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ChartIcon, Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader} from "../../components";
import Filter from "../../components/filter/Filter";
import {SystemTables, Table, initializeTableColumns} from "../../components/table";
import SystemFeatures from "../../constant/systemFeatures";

import {isValidArrayWithData, objectPick} from "../../core/utilities";

import Api from "../../api";
import CsvExportButton from "../../components/CsvExportButton";
import FilterButton from "../../components/filter/FilterButton";
import ExportFields from "../../constant/exportFields";

import {getFilterOptionsFeature, getIsFilterFeature, getIsShowChartsFeature, getPerPageFeature, getTableHiddenOptionFeature, setIsFilterFeature, setIsShowChartsFeature, setPerPageFeature, setTableHiddenOptionFeature} from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

const PROCESS_FEATURE = SystemFeatures.VEHICLES_FLEET;
export default function VehicleFleets() {
  const {data: vehicleFleets = [], isLoading, isError, error, status} = Api.vehicles.useGetAllVehicleFleetsQuery();
  const devicesFound = isValidArrayWithData(vehicleFleets);

  // console.log({count:vehicleFleets.length,vehicleFleets,isLoading,isError,error})
  //console.log({ daysToSameDateLastMonth: daysToSameDateLastMonth() });

  console.log({error, isError, status});

  return isError ? <OnError error={error} /> : isLoading ? <TablesSkeleton isLoading={isLoading} rowCount={15} title={"Vehicle Fleets"} /> : devicesFound ? <DisplayVehicleFleets vehicleFleets={vehicleFleets} /> : <NoRecords title="Vehicle Fleets" message="No Vehicle Fleets found." />;
}

function DisplayVehicleFleets({vehicleFleets}) {
  const [tableInfo] = useState(SystemTables.vehicleFleets);
  const currentHiddenColumns = useSelector(getTableHiddenOptionFeature(tableInfo.name));
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const currentIsShowCharts = useSelector(getIsShowChartsFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [shownDevices, setShownDevices] = useState(vehicleFleets);
  const [exportData, setExportData] = useState(vehicleFleets);
  const [isFilter, setIsFilter] = useState(currentIsFilter);
  const [isHideCharts, setIsHideCharts] = useState(!currentIsShowCharts);
  const [isFiltered, setIsFiltered] = useState(false);

  console.log({tableInfo});
  const [deviceCommandInfo, setDeviceCommandInfo] = useState({});

  const [tableColumns, setTableColumns] = useState(initializeTableColumns(tableInfo.columnOptions, currentHiddenColumns));

  const {numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick} = usePageFilter({
    displayData: shownDevices,
    currentItemsPerPage
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPerPageFeature({name: PROCESS_FEATURE, perPage: itemsPerPage}));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({name: PROCESS_FEATURE, isFilter}));
  }, [dispatch, isFilter]);

  useEffect(() => {
    dispatch(setIsShowChartsFeature({name: PROCESS_FEATURE, isShowCharts: !isHideCharts}));
  }, [dispatch, isHideCharts]);

  useEffect(() => {
    const shouldClear = deviceCommandInfo.showToast || deviceCommandInfo.sendSuccess;
    const timer = setTimeout(() => {
      if (shouldClear) {
        setDeviceCommandInfo({});
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [deviceCommandInfo]);

  const onColumnOptionsChange = columnOptions => {
    setTableColumns(columnOptions);
    const hidden = columnOptions.filter(column => column.isHidden).map(column => column.key);
    dispatch(setTableHiddenOptionFeature({name: tableInfo.name, hidden}));
  };

  const handleFilterDataChange = filteredData => {
    setIsFiltered(filteredData.length !== vehicleFleets.length);
    setShownDevices(filteredData);
    setExportData(
      filteredData.map(device => {
        return objectPick(device, ExportFields.DEVICES);
      })
    );
  };

  const filterOptions = () => {
    const option = new FilterOptions();
    return option.getDeviceOptions();
  };

  const onSelectedTableRowChanged = () => {
    //const rows = vehicleFleets.filter((device) => selected.includes(device.id));
    // console.log({ rows, selected });
  };

  // console.log({ vehicleFleets, displayData, commandConfirmation, commandConfirmState, contextMenuOptions });

  // console.log({ vehicleFleets,devicesOnTheRoad, devicesDailyConnectStatistics,lastReportedInfo });
  return (
    <Layout>
      <PrimaryHeader title="Vehicle Fleets">
        <div className="flex">
          <CsvExportButton exportData={exportData} filename={"VehicleFleets"} />
          <button type="button" onClick={() => setIsHideCharts(hideChart => !hideChart)} className="inline-flex items-center gap-4 mx-4 btn text-primary group hover:text-brand">
            <ChartIcon />
            <span className="inline-flex items-center gap-1">{isHideCharts ? "Show Charts" : "Hide Charts"}</span>
          </button>

          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>
      <Filter enabled={isFilter} data={vehicleFleets} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} feature={PROCESS_FEATURE} defaultFilterOptions={defaultFilterOptions} style={{display: "flex", flexDirection: "row"}} />

      <section className="detail-section">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage} recordsName={"VehicleFleets"} isFiltered={isFiltered} totalNumberOfItems={totalNumberOfItems} tableColumnSelectOptions={tableColumns} onColumnOptionsChange={onColumnOptionsChange} />

        <Table data={shownDevices} pagingInfo={{dataPerPage: itemsPerPage, currentPage}} tableColumns={tableInfo.columnOptions} initialSort={{key: "id", order: "asc"}} firstColumnCheckBox={false} showTableColumnSelect={true} tableColumnSelectOptions={tableColumns} onColumnOptionsChange={onColumnOptionsChange} onSelectedChanged={onSelectedTableRowChanged} includeSequentialId={true} outOfRange={{key: "last_reported_days", aboveLimit: 5, includeNull: true}} />
        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} currentPage={currentPage} />
      </section>
    </Layout>
  );
}
