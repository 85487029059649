import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../api";
import {useSendSingleSmsMutation} from "../../api/rtk/smsApi";
import {ConfirmationDialog, Layout, NoRecords, OnError, PageFilter, Pagination} from "../../components";
import CsvExportButton from "../../components/CsvExportButton";
import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import {SystemTables, Table, initializeTableColumns} from "../../components/table";
import Toast from "../../components/toast/Toast";
import ExportFields from "../../constant/exportFields";
import SystemFeatures from "../../constant/systemFeatures";
import TeltonikaSmsCommands from "../../core/TeltonikaSmsCommands";
import {arrayObjectsPick, isValidArrayWithData} from "../../core/utilities";
import {getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature, getTableHiddenOptionFeature, setIsFilterFeature, setPerPageFeature, setTableHiddenOptionFeature} from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

const PROCESS_FEATURE = SystemFeatures.DEVICE_EVENTS;

export default function DeviceEvents() {
  const {data: deviceEvents = [], isLoading, isError, error} = Api.devices.useGetAllDevicesEventsQuery();
  const deviceEventsFound = isValidArrayWithData(deviceEvents);

  console.log({deviceEvents, deviceEventsFound});
  return isError ? <OnError error={error} /> : isLoading ? <TablesSkeleton isLoading={isLoading} rowCount={15} title={"Device Events"} /> : deviceEventsFound ? <DisplayDeviceEvents deviceEvents={deviceEvents} /> : <NoRecords title="Device Events" message="No Device Events found." />;
}

function DisplayDeviceEvents({deviceEvents, isLoading = false}) {
  const [tableInfo] = useState(SystemTables.deviceEvents);
  const currentHiddenColumns = useSelector(getTableHiddenOptionFeature(tableInfo.name));
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(deviceEvents);
  const [exportData, setExportData] = useState(arrayObjectsPick(deviceEvents, ExportFields.deviceEvents));
  const [displayActiveOnlyData, setDisplayActiveOnlyData] = useState(false);
  const [dynamicTableColumn, setDynamicTableColumn] = useState(useState(initializeTableColumns(tableInfo.columnOptions, currentHiddenColumns)));
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(currentIsFilter);
  const [commandConfirmation, setCommandConfirmation] = useState({confirm: false});
  const [commandConfirmState, setCommandConfirmState] = useState(undefined);
  const [tableColumns, setTableColumns] = useState(initializeTableColumns(tableInfo.columnOptions, currentHiddenColumns));
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [deviceCommandInfo, setDeviceCommandInfo] = useState({});
  const [sendSingleSms] = useSendSingleSmsMutation();
  const {numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick} = usePageFilter({
    displayData,
    currentItemsPerPage
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPerPageFeature({name: PROCESS_FEATURE, perPage: itemsPerPage}));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({name: PROCESS_FEATURE, isFilter}));
  }, [dispatch, isFilter]);

  useEffect(() => {
    let dataToDisplay = deviceEvents;
    if (displayActiveOnlyData) {
      dataToDisplay = deviceEvents.filter(deviceEventData => {
        return deviceEventData.override === false && deviceEventData.resolved === false;
      });
      setDynamicTableColumn(
        tableInfo.columnOptions.filter(tableColumn => {
          return !tableColumn.excludeOnActive;
        })
      );
    } else {
      setDynamicTableColumn(tableInfo.columnOptions);
    }
    setDisplayData(dataToDisplay);
    setExportData(arrayObjectsPick(dataToDisplay, ExportFields.deviceEvents));
    console.log({dataToDisplay, got: arrayObjectsPick(dataToDisplay, ExportFields.deviceEvents)});
  }, [deviceEvents, displayActiveOnlyData]);

  useEffect(() => {
    const shouldClear = deviceCommandInfo.showToast || deviceCommandInfo.sendSuccess;
    const timer = setTimeout(() => {
      if (shouldClear) {
        setDeviceCommandInfo({});
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [deviceCommandInfo]);

  const handleFilterDataChange = filteredData => {
    setIsFiltered(filteredData.length !== deviceEvents.length);
    setDisplayData(filteredData);
  };

  const handleNegativeConfirmation = () => {
    setCommandConfirmation({confirm: false});
    if (commandConfirmState === "AwaitingConfirmation") {
      setCommandConfirmState("NegativeConfirmation");
    }
  };

  const handlePositiveConfirmation = () => {
    setCommandConfirmation({confirm: false});
    if (commandConfirmState === "AwaitingConfirmation") {
      setCommandConfirmState("PositiveConfirmation");
    }
  };

  const onColumnOptionsChange = columnOptions => {
    setTableColumns(columnOptions);
    const hidden = columnOptions.filter(column => column.isHidden).map(column => column.key);
    dispatch(setTableHiddenOptionFeature({name: tableInfo.name, hidden}));
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getDeviceEventOptions();
  };

  const onSelectedTableRowChanged = selected => {
    const rows = deviceEvents.filter(device => selected.includes(device.id));
    // console.log({ rows, selected });
    setSelectedDevices(rows);
  };

  useEffect(() => {
    async function sendSms() {
      try {
        const {msisdn, smsMessage, reference} = deviceCommandInfo;

        if (selectedDevices && selectedDevices.length > 0) {
          const multipleMsisdn = selectedDevices.map(device => device.sim_msisdn);
          const smsSendInfo = await sendSingleSms({msisdns: multipleMsisdn, smsMessage: TeltonikaSmsCommands.fullCommand(smsMessage), reference}).unwrap();
          setCommandConfirmState("SmsInitiated");
          if (smsSendInfo.smsResponse) {
            setCommandConfirmState("SmsSent");
            setDeviceCommandInfo({msisdn, smsMessage, reference, sendSuccess: true, showToast: true});
          } else {
            setDeviceCommandInfo({msisdn, smsMessage, reference, sendSuccess: false, showToast: true});
          }
        } else if (msisdn) {
          const smsSendInfo = await sendSingleSms({msisdn, smsMessage: `  ${smsMessage}`, reference}).unwrap();
          setCommandConfirmState("SmsInitiated");
          if (smsSendInfo.smsResponse) {
            setCommandConfirmState("SmsSent");
            setDeviceCommandInfo({msisdn, smsMessage, reference, sendSuccess: true, showToast: true});
          } else {
            setDeviceCommandInfo({msisdn, smsMessage, reference, sendSuccess: false, showToast: true});
          }
          console.log({smsSendInfo});
        } else {
          // console.log("This does not have a msisdn", { deviceCommandInfo });
        }

        setCommandConfirmState(undefined);
      } catch (error) {
        if (error.status === 403) {
          window.location.reload();
        }
      }
    }

    switch (commandConfirmState) {
      case "ConfirmationNotRequired":
        sendSms();
        break;

      case "ConfirmationRequired":
        const {smsMessage} = deviceCommandInfo;
        setCommandConfirmation({confirm: true, message: `Are you sure you want to send sms message: ${smsMessage} to devices?`});
        setCommandConfirmState("AwaitingConfirmation");
        break;
      case "AwaitingConfirmation":
        break;

      case "NegativeConfirmation":
        setCommandConfirmState(undefined);
        break;

      case "PositiveConfirmation":
        sendSms();
        break;

      default:
        break;
    }
  }, [deviceCommandInfo, commandConfirmState, sendSingleSms]);

  // console.log({  deviceEvents, displayData, commandConfirmation, commandConfirmState, contextMenuOptions });
  console.log({exportData, displayData});
  return (
    <Layout isLoading={isLoading} loadingText="Loading Device Events">
      <header className="header-justify-between">
        <div className="inline-flex px-5 py-4 ">
          <h2 className="font-semibold text-primary">Device Events</h2>
        </div>

        <div>
          <input
            className="mt-[0.3rem] mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 dark:bg-neutral-600 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 dark:after:bg-neutral-400 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary dark:checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary dark:checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
            type="checkbox"
            role="switch"
            id="flexSwitchChecked"
            onChange={() => setDisplayActiveOnlyData(!displayActiveOnlyData)}
          />
          <label className="inline-block pl-[0.15rem] hover:cursor-pointer text-primary" htmlFor="flexSwitchChecked">
            Display Active Only
          </label>
        </div>

        <CsvExportButton exportData={exportData} filename={"DeviceEvents"} />

        <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
      </header>

      <Filter enabled={isFilter} data={deviceEvents} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} feature={PROCESS_FEATURE} defaultFilterOptions={defaultFilterOptions} />

      <section className="detail-section">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage} recordsName={"Device Events"} isFiltered={isFiltered} totalNumberOfItems={totalNumberOfItems} showTableColumnSelect={true} tableColumnSelectOptions={tableColumns} onColumnOptionsChange={onColumnOptionsChange} />
        <Toast success={deviceCommandInfo.sendSuccess} show={deviceCommandInfo.showToast} message={"Sms sent to device"} />
        <ConfirmationDialog show={commandConfirmation.confirm} onNo={handleNegativeConfirmation} onYes={handlePositiveConfirmation} confirmationMessage={commandConfirmation.message} />
        <Table data={displayData} pagingInfo={{dataPerPage: itemsPerPage, currentPage}} tableColumns={dynamicTableColumn} initialSort={{key: "data_timestamp", order: "desc"}} onSelectedChanged={onSelectedTableRowChanged} includeSequentialId={true} showTableColumnSelect={true} tableColumnSelectOptions={tableColumns} onColumnOptionsChange={onColumnOptionsChange} />
        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} currentPage={currentPage} />
      </section>
    </Layout>
  );
}
