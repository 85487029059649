import React from "react";
import FilterIcon from "../icons/FilterIcon";
import { DropDownArrow } from "../icons/SidebarIcons";

type FilterButtonProps = {
  isFilter: boolean,
  setIsFilter: (isFilter:boolean) => void
}

export default function FilterButton({ isFilter, setIsFilter }: FilterButtonProps) {
  
  const onFilterChange = () => {
    setIsFilter(!isFilter);
  };
  return (
    <div className="p-2">
      <button type="button" onClick={onFilterChange} className="inline-flex items-center gap-2 border btn-secondary group-hover:btn-primary hover:text-brand">
      <FilterIcon />
      <span className="inline-flex items-center gap-1">
          {isFilter ? "Hide Filters" : "Filter"}
        <DropDownArrow classes={`fill-current h-2 w-2 ${isFilter && "rotate-180"}`} />
      </span>
    </button>
    </div>
  );
}
