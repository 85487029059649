import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ParentLayout from "./components/ParentLayout";
import UserRoleAccess from "./constant/userRoleAccess";
import RequireAuth from "./features/auth/RequireAuth";

// Import pages

//dashboard
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardCameraFeeds from "./pages/dashboard/DashboardCameraFeeds";
import DashboardLiveDataFeed from "./pages/dashboard/DashboardLiveDataFeed";
import VehicleHealthIssues from "./pages/dashboard/VehicleHealthIssues";
//scheduling and alert

import Scheduling from "./pages/Scheduling";
// devices, DeviceEvents and DeviceSmsAlerts
import { Devices, DeviceEvents, DeviceSmsAlerts, DeviceDetails } from "./pages/device";
// driver
import DriverProfile from "./pages/driver/DriverProfile";
import Drivers from "./pages/driver/Drivers";
// vehicle
import {
  ApprovedHelp, VehicleAdd, VehicleDetails, Vehicles, VehiclesBatteries, VehiclesLocationsTrackingReport,
  VehiclesTripsReport, VehiclesEventsReport, VehicleTrip
} from "./pages/vehicle";
// insights

import FuelAnalysis from "./pages/insights/FuelAnalysis";
import ImportStdFuelAnalysis from "./pages/insights/ImportStdFuelAnalysis";
import VehicleInspection from "./pages/insights/VehicleInspection";
// settings
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register";
import { AccidentManagement, LicensingFineManagement, RepairsMaintenance, RoadsideAssistance, ScheduledServices, VehicleInspections } from "./pages/services";
import TicketOverview from "./pages/services/TicketOverview";
//simCards
import {SimCards,SimCardDetails} from "./pages/simCard";
//users
import ScrollTo from "./components/ScrollTo";
import {Users, UserDetails, UserRegistration, UserRegistrations } from "./pages/user";

import ResetPassword from "./pages/ResetPassword";
import MicrosoftCallbackPage from "./pages/MicrosoftCallback";
import VehicleFleets from "./pages/vehicle/VehicleFleets";
import VehicleFleetDetails from "./pages/vehicle/VehicleFleetDetails";
import DashboardFleetStats from "./pages/dashboard/DashboardFleetStats";

function App() {
  const location = useLocation();
  

  useEffect(() => {
    const html = document.querySelector("html") as HTMLElement | null;
    if (html) {
      html.style.scrollBehavior = "auto";
      window.scroll({ top: 0 });
      html.style.scrollBehavior = "";
    }
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <ScrollTo />
      {/* protected routes need to be created after connecting the login with the API */}
      <Routes>
        <Route path="/" element={<ParentLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/microsoft/callback" element={<MicrosoftCallbackPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<RequireAuth  />}>
            <Route path="*" element={<Navigate to="/" />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard/camera-feeds" element={<DashboardCameraFeeds />} />
            <Route path="/dashboard/live-data-feed" element={<DashboardLiveDataFeed />} />
            <Route path="/dashboard/vehicle-health-issues" element={<VehicleHealthIssues />} />
            <Route path="/dashboard/fleet-statistics" element={<DashboardFleetStats />} />

            <Route path="/scheduling" element={<Scheduling />} />
            

            <Route path="/insights" element={<FuelAnalysis />} />
            
            {/* Removed, will be added later in future releases.
            <Route path="/insights/driver-ratings" element={<DriverRatings />} /> 
            */}
            <Route path="/insights/vehicle-inspection" element={<VehicleInspection />} />
            <Route element={<RequireAuth roleFeature={UserRoleAccess.canImportStdFuelAnalysis} />}>
              <Route path="/insights/fuelAnalysis/std-import-data" element={<ImportStdFuelAnalysis />} />
            </Route>
          </Route>

          <Route element={<RequireAuth roleFeature={UserRoleAccess.deviceList} />}>
            <Route path="/devices" element={<Devices />} />
            <Route path="/devices/:deviceId" element={<DeviceDetails />} />
            <Route path="/devices/events" element={<DeviceEvents />} />
            <Route path="/devices/sms-alerts" element={<DeviceSmsAlerts />} />
          </Route>

          <Route element={<RequireAuth roleFeature={UserRoleAccess.simCardList} />}>
            <Route path="/sim-cards" element={<SimCards />} />
            <Route path="/sim-cards/:iccId" element={<SimCardDetails />} />
          </Route>
          <Route element={<RequireAuth  />}>
            <Route path="/drivers" element={<Drivers />} />
            <Route path="/drivers/:driverId" element={<DriverProfile />} />

            <Route path="/vehicles" element={<Vehicles />} />
            <Route path="/vehicles/:vehicleId" element={<VehicleDetails />} />
            <Route path="/vehicles/battery-state" element={<VehiclesBatteries />} />
            <Route path="/vehicles/approved-help-options" element={<ApprovedHelp />} />
            <Route element={<RequireAuth roleFeature={UserRoleAccess.serviceOverviewList}  />}>
              <Route path="/vehicles/new-vehicle" element={<VehicleAdd />} />
            </Route>
            <Route path="/vehicles/vehicle-trip/:vehicleId/:tripId" element={<VehicleTrip />} />
            <Route path="/vehicles/vehicles-events-report" element={<VehiclesEventsReport />} />
            <Route path="/vehicles/vehicles-trips-report" element={<VehiclesTripsReport />} />
            <Route path="/vehicles/vehicles-tracking-location-report" element={<VehiclesLocationsTrackingReport />} />
            <Route element={<RequireAuth roleFeature={UserRoleAccess.vehicleFleetList}  />}>
              <Route path="/vehicles/vehicles-fleets" element={<VehicleFleets />} />
            </Route>
            <Route element={<RequireAuth roleFeature={UserRoleAccess.vehicleFleetView} />}>
              
              <Route path="/vehicles/vehicles-fleets/:vehicleFleetId" element={<VehicleFleetDetails />} />
            </Route>
          </Route>
          <Route element={<RequireAuth roleFeature={UserRoleAccess.serviceOverviewList}  />}>
            <Route path="/services/tickets-overview" element={<TicketOverview />} />
          </Route>
          <Route element={<RequireAuth  />}>
            <Route path="/services/accident-management" element={<AccidentManagement />} />
            <Route path="/services/repairs-maintenance" element={<RepairsMaintenance />} />
            <Route path="/services/licensing-fine-management" element={<LicensingFineManagement />} />
            <Route path="/services/roadside-assistance" element={<RoadsideAssistance />} />
            <Route path="/services/scheduled-services" element={<ScheduledServices />} />
            <Route path="/services/vehicle-inspections" element={<VehicleInspections />} />
          </Route>
          <Route element={<RequireAuth roleFeature={UserRoleAccess.userList}  />}>
            <Route path="/users" element={<Users />} />
            <Route element={<RequireAuth roleFeature={UserRoleAccess.userView}  />}>
              <Route path="/users/:userId" element={<UserDetails />} />
            </Route>
            <Route path="/users/registrations" element={<UserRegistrations />} />
            <Route path="/users/registrations/new-user" element={<UserRegistration />} />
          </Route>

          <Route path="/health/check" element={<div></div>} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
