import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setDarkTheme, setLightTheme, selectCurrentTheme } from "../../features/settings/settingsSlice";

import { SunIcon, MoonIcon } from "../icons";
import { SystemThemeSetting } from "../../models/other.model";
import { defaultBrowserTheme } from "../../core/utilities";


const SwitchTheme = () => {
  const [theme, setTheme] = useState(useSelector(selectCurrentTheme)||defaultBrowserTheme());
  //const settings = useSelector((state => state.settings));
  const dispatch = useDispatch();

  const toggleTheme = () => {
    
    if (theme === SystemThemeSetting.LIghtMode) {
      dispatch(setDarkTheme());
      setTheme(SystemThemeSetting.DarkMode);
    } else {
      dispatch(setLightTheme());
      setTheme(SystemThemeSetting.LIghtMode);
    }
    if (window.location.pathname === "/" || window.location.pathname.startsWith("/vehicles/vehicle-trip/")) {
      
      window.location.reload();
    }
  };

  useEffect(() => {
    //dispatch(setTheme({theme}));
    document.body.className = theme;
  }, [theme, dispatch]);

  // console.log("Switch theme", { theme });

  return (
    <>
      <button onClick={toggleTheme} type="button" className="px-4 hover:text-brand">
        {theme === SystemThemeSetting.LIghtMode  ? <MoonIcon /> : <SunIcon />}
      </button>
    </>
  );
};

export default SwitchTheme;
