import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useGetMicrosoftCallbackQuery } from "../api/auth/authApi";
import { Loading } from "../components";
import { setupUserAccess } from "../core/userAccess";
import { setCredentials } from "../features/auth/authSlice";

export default function MicrosoftCallbackPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const callbackUrl = `${window.origin}/microsoft/callback`;

  const location = useLocation();

  const targetUrl = location?.state?.from?.pathname || "/";

  const { data,  error } = useGetMicrosoftCallbackQuery({ code, callbackUrl });

  const handleAuth = async () => {
    try {
      const access = await setupUserAccess(data?.role);
      const credentials = { ...data, access };
      dispatch(setCredentials(credentials));
      navigate(credentials?.landingPage || targetUrl);
    } catch (error: Error | any) {
      navigate(`/login?error=ms-failed&errorNumber=${error?.status}`);
    }
  };

  useEffect(() => {
    if (data) {
      handleAuth();
    }

    if (error) {
      const msg = (error as { data: any; message: string })?.data?.message;

      if (msg.includes("invalid_grant")) {
        navigate("/login?error=ms-failed");
      }

      if (msg.includes("Could not find user")) {
        navigate("/login?error=ms-invalid");
      } else {
        navigate("/login?error=ms-failed");
      }
    }
  }, [data, error]);

  return <Loading isLoading={true} loadingText="Getting everything ready for you—just a moment!" isForegroundProcess={true} isLogin={true} />;
}
