import React, {useState} from "react";
import {  useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {Layout, OnError, Tabs} from "../../components";

import {getNameFromUrlPath} from "../../core/utilities";
import SimCardDetailsSkeleton from "../../skeletons/SimCardDetailsSkeleton";

import Api from "../../api";
import Breadcrumb from "../../components/Breadcrumb";
import {TabIconTypes} from "../../components/Tabs";

import { SimCardAirtimeDailyUsageView, SimCardDataDailyUsageView, SimCardOverview, SimCardUsageView } from "./views";
import { selectPreviousPage } from "../../features/settings/settingsSlice";

export const SimCardTabs = Object.freeze({
  Overview: "Overview",
  UsageView: "Usage",
  DataDailyUsageView: "Data Daily Usage",
  AirtimeDailyUsageView: "Airtime Daily Usage"
});

function SimCardDetails() {
  const {iccId} = useParams();
    const {data: simCard, isLoading, isError, error} = Api.simCards.useGetSimCardQuery(iccId);
  const navigationPath = useSelector(selectPreviousPage);

  const fromLocation = {
    path: navigationPath.previous || "/sim-cards",
    name: getNameFromUrlPath(navigationPath.previous ) || "Sim Cards"
  };

  return isError ? <OnError error={error} /> : isLoading ? <SimCardDetailsSkeleton isLoading={isLoading} /> : <DisplaySimCardDetails simCard={simCard} fromLocation={fromLocation} />;
}

function DisplaySimCardDetails({simCard, fromLocation}) {
  const {data: simCardUsageLogs = []} = Api.simCards.useGetSimCardUsageLogsQuery(simCard.iccid);
  const {data: simCardAirtimeDailyUsage = []} = Api.simCards.useGetSimCardAirtimeDailyUsageQuery(simCard.iccid);
  const {data: simCardDataDailyUsage = []} = Api.simCards.useGetSimCardDataDailyUsageQuery(simCard.iccid);
  const [currentTab, setCurrentTab] = useState(SimCardTabs.Overview);
  const simCardId = simCard.iccid;

  const usageLogsAvailable = simCardUsageLogs && simCardUsageLogs.length > 0;
  const airtimeDailyUsageAvailable = simCardAirtimeDailyUsage && simCardAirtimeDailyUsage.length > 0;
  const dataDailyUsageAvailable = simCardDataDailyUsage && simCardDataDailyUsage.length > 0;
  //const smsOutAvailable = deviceSmsOut && deviceSmsOut.length > 0;
  //const smsAlertsAvailable = deviceSmsAlerts && deviceSmsAlerts.length > 0;
  //const cameraFootageAvailable = deviceCameraFeeds && deviceCameraFeeds.length > 0;
  //const tripPointsAvailable = deviceTripPoints && deviceTripPoints.length > 0 ;

  const breadcrumbItems = () => {
    const items = [];
    if (fromLocation && fromLocation.name) {
      items.push({name: fromLocation.name, path: fromLocation.path});
    } else {
      items.push({name: "Sim Cards", path: "/sim-cards"});
    }
    const itemName = `Sim Card ${simCardId} ${currentTab}`;
    items.push({name: itemName, path: `/sim-cards/${simCardId}`});

    return items;
  };

  const onTabItemClick = e => {
    const tab = e.target.id;

    console.log(tab, e);
    setCurrentTab(tab);
  };

  const tabItems = () => {
    const onClick = onTabItemClick;

    const items = [
      {name: SimCardTabs.Overview, icon: TabIconTypes.Home, onClick, currentTab},
      {name: SimCardTabs.UsageView, icon: TabIconTypes.Chart, onClick, currentTab, enabled: usageLogsAvailable},
      {name: SimCardTabs.AirtimeDailyUsageView, icon: TabIconTypes.Chart, onClick, currentTab, enabled: airtimeDailyUsageAvailable},
      {name: SimCardTabs.DataDailyUsageView, icon: TabIconTypes.Chart, onClick, currentTab, enabled: dataDailyUsageAvailable}
      // { name: SimCardTabs.SmsAlerts, icon: TabIconTypes.Sms, onClick, currentTab, enabled: smsAlertsAvailable },
      // { name: SimCardTabs.SmsSentOut, icon: TabIconTypes.Sms, onClick, currentTab, enabled: smsOutAvailable },
      // { name: SimCardTabs.TripPoints, icon: TabIconTypes.LocationDot, onClick, currentTab, enabled: tripPointsAvailable },
    ];

    //  if (camerasInstalled) {
    //     items.push({ name: SimCardTabs.CameraFootage, icon: TabIconTypes.CameraFootage, onClick, currentTab, enabled: cameraFootageAvailable });
    //   }

    return items;
  };
  //console.log({camerasInstalled, device, deviceCameraFeeds, deviceTripPoints });
  return (
    <Layout>
      <header className="flex flex-row justify-between p-2 rounded-sm shadow-lg bg-other col-span-full xl:col-span">
        <Breadcrumb items={breadcrumbItems()} />
        <div className="flex flex-row items-center gap-2 px-5 py-4 font-semibold text-primary"></div>
        {/* buttons */}
        <div className="flex flex-col gap-4 md:items-center md:flex-row"></div>
      </header>
      <Tabs items={tabItems()} />
      {
        //     currentTab === SimCardTabs.CameraFootage ? (
        //      <DeviceCameraFootagesView cameraFeeds={deviceCameraFeeds} />
        //   ) :
        //    currentTab === SimCardTabs.Events ? (
        //     <DeviceEventsView deviceEvents={deviceEvents} />
        // ) :
        currentTab === SimCardTabs.Overview ? (
          <SimCardOverview simCard={simCard} />
        ) : currentTab === SimCardTabs.UsageView ? (
          <SimCardUsageView usageLogs={simCardUsageLogs} />
        ) : currentTab === SimCardTabs.AirtimeDailyUsageView ? (
          <SimCardAirtimeDailyUsageView airtimeDailyUsage={simCardAirtimeDailyUsage} />
        ) : currentTab === SimCardTabs.DataDailyUsageView ? (
          <SimCardDataDailyUsageView dataDailyUsage={simCardDataDailyUsage} />
        ) : // : currentTab === SimCardTabs.SmsSentOut ? (
        //   <DeviceSmsOutView deviceSmsOut={deviceSmsOut} />
        // ) : currentTab === SimCardTabs.TripPoints ? (<DeviceTripPointsView tripPoints={deviceTripPoints} />)
        null
      }
    </Layout>
  );
}
export default SimCardDetails;
