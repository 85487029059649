import React, {useState} from "react";
import convertCsvToJson from "../core/ConvertCsvToJson";

export default function CsvToJsonInput({colParser = null, matchHeadingTo = null, headers = null, onStarted, onComplete, onError, noHeaders = false, ignoreColumns, displayMessage}) {
  const [importStarted, setImportStarted] = useState(false);
  const hiddenFileInput = React.useRef(null);

  const handleButtonClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileUpload = event => {
    const csvFile = event.target.files[0];

    if (csvFile) {
      setImportStarted(true);
      onStarted();
      convertCsvToJson({csvFile, matchHeadingTo, headers, colParser, noHeaders, columnsToIgnore: ignoreColumns})
        .then(jsonData => {
          onComplete(jsonData);
        })
        .catch(error => {
          onError(error);
        });
    }
  };

  return (
    <div>
      {!importStarted ? (
        <>
          <p className="p-4 text-primary">{displayMessage}</p>
          <button onClick={handleButtonClick} type="button" className="header-button">
            Upload Standard Bank Import File
          </button>
          <input type="file" ref={hiddenFileInput} onChange={handleFileUpload} accept={".csv"} style={{display: "none"}} />
        </>
      ) : null}
    </div>
  );
}
