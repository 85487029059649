import React, {useState} from "react";
import {Link} from "react-router-dom";
import Api from "../../api";
import {useResendUserRegistrationEmailMutation, useResetUserRegistrationKeyExpiryMutation} from "../../api/rtk/userRegistrationsApi";
import {Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader} from "../../components";
import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import ProcessFlowDialog, {UserProcessFlowInit} from "../../components/flow/ProcessFlowDialog";
import {SystemTables, Table} from "../../components/table";
import {ProcessFlowStage} from "../../constant";
import UserRoleAccess from "../../constant/userRoleAccess";
import {isValidArrayWithData} from "../../core/utilities";
import FilterOptions from "../../filter/FilterOptions";
import useHasAccessToFeature from "../../hooks/useHasAccessToFeature";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

export default function UserRegistrations() {
  const {data: userRegistrations, isLoading, isError, error} = Api.userRegistrations.useGetAllUserRegistrationsQuery();
  const {hasAccess: hasAccessToCreateUser} = useHasAccessToFeature(UserRoleAccess.userCreate);
  const userRegistrationsFound = isValidArrayWithData(userRegistrations);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} title="User Registrations" />
  ) : userRegistrationsFound ? (
    <DisplayUserRegistrations userRegistrations={userRegistrations} canCreateUser={hasAccessToCreateUser} />
  ) : (
    <NoRecords title="User Registrations" message="No User Registrations found.">
      <NewUserButton canCreateUser={hasAccessToCreateUser} />
    </NoRecords>
  );
}

function DisplayUserRegistrations({userRegistrations, canCreateUser}) {
  const [displayData, setDisplayData] = useState(userRegistrations);
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [isLoadingInfo, setIsLoadingInfo] = useState({status: false});
  const [userProcessFlow, setUserProcessFlow] = useState(UserProcessFlowInit);
  const [resetUserRegistrationKeyExpiry] = useResetUserRegistrationKeyExpiryMutation();
  const [resendUserRegistrationEmail] = useResendUserRegistrationEmailMutation();

  // used with the pagination
  const {numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick} = usePageFilter({displayData});

  const handleFilterDataChange = filteredData => {
    setIsFiltered(filteredData.length !== userRegistrations.length);
    setDisplayData(filteredData);
  };

  const filterOptions = () => {
    const option = new FilterOptions();
    return option.getUserRegistrations();
  };

  const handleTableButtonClick = async clickedRow => {
    const {buttonKey, id} = clickedRow;

    const confirmMessage = `Are you sure you want to ${buttonKey === "resend_email" ? `resend the confirmation email to ${id}?` : `reset the registration expiry date for email: ${id}?`}`;

    setUserProcessFlow({stage: ProcessFlowStage.Confirm, confirmMessage, processId: buttonKey, processData: {id}});
  };

  const onUserProcessConfirm = async ({confirm, stage, processId}) => {
    const isResendEmail = processId === "resend_email";

    if (confirm.yes) {
      const {id} = userProcessFlow.processData;
      const processMessage = `Processing Registration ${isResendEmail ? "Email" : "Expiry"} Resend for ${id}`;
      setUserProcessFlow({stage: ProcessFlowStage.Processing, processMessage});

      if (isResendEmail) {
        const response = await resendUserRegistrationEmail(id).unwrap();

        const message = response.success ? "Registration Email Successfully Resent" : "Registration Email could not Resent!";
        console.log({response});
        setUserProcessFlow({stage: response.success ? ProcessFlowStage.Info : ProcessFlowStage.Alert, alertMessage: message, infoMessage: message});
      } else {
        const response = await resetUserRegistrationKeyExpiry(id).unwrap();
        const message = response.success ? "Registration Expiry Date Successfully Reset" : "Registration Expiry Date could not Reset!";
        setUserProcessFlow({stage: response.success ? ProcessFlowStage.Info : ProcessFlowStage.Alert, alertMessage: message, infoMessage: message});
        console.log({response});
      }
    } else {
      console.log({confirm, stage, processId});
      setUserProcessFlow({stage: ProcessFlowStage.NotStarted});
    }
  };

  // console.log({ displayData, userRegistrations });
  return (
    <Layout isLoading={isLoadingInfo.status} loadingText={isLoadingInfo.text}>
      <PrimaryHeader title="User Registrations">
        <div className="flex">
          <NewUserButton canCreateUser={canCreateUser} />
          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>
      <ProcessFlowDialog processId={userProcessFlow.processId} processStage={userProcessFlow.stage} alertMessage={userProcessFlow.alertMessage} confirmMessage={userProcessFlow.confirmMessage} infoMessage={userProcessFlow.infoMessage} processingMessage={userProcessFlow.processMessage} onConfirm={onUserProcessConfirm} />
      <Filter enabled={isFilter} data={userRegistrations} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} />
      <section className="detail-section">
        <PageFilter itemsPerPage={itemsPerPage} setItemsPerPage={handleItemsPerPageChange} numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage} recordsName={"User Registrations"} isFiltered={isFiltered} totalNumberOfItems={totalNumberOfItems} />
        <Table data={displayData} pagingInfo={{dataPerPage: itemsPerPage, currentPage}} tableColumns={SystemTables.userRegistrations} initialSort={{key: "email_address", order: "asc"}} onButtonClick={handleTableButtonClick} includeSequentialId={true} />

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} currentPage={currentPage} />
      </section>
    </Layout>
  );
}

function NewUserButton({canCreateUser}) {
  return (
    <>
      {canCreateUser ? (
        <Link to="/users/registrations/new-user">
          <button type="button" className="header-button">
            Create User
          </button>
        </Link>
      ) : null}
    </>
  );
}
