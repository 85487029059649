import React, { useEffect, useRef, useState  } from "react";
import { useSelector } from "react-redux";

import { MenuIcons } from "./SidebarMenuIcon";

import SidebarMultipleOptionsMenu from "./SidebarMultipleOptionsMenu";
import SidebarSingleOptionMenu from "./SidebarSingleOptionMenu";

import {defaultUserAccess} from "../../core/userAccess";
import SidebarMenuOptions from "./SideBarMenuOptions";


function Sidebar({ sidebarExpanded  }) {

  const userAccess = useSelector((state) => state.auth.user.access) || defaultUserAccess();
  const trigger = useRef(null);
  const sidebar = useRef(null);
  const [menuOptions, setMenuOptions] = useState(new SidebarMenuOptions(userAccess));


  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarExpanded || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      //setSidebarObject(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [sidebarExpanded]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarExpanded || keyCode !== 27) return;
      //setSidebarObject(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [sidebarExpanded]);

  // console.log({    userAccess,    uu: userAccess.users?.list,    usc: userAccess.simCards?.list,    tst: JSON.stringify(userAccess)  });

  useEffect(() => {
    
    const options = new SidebarMenuOptions(userAccess);
    setMenuOptions(options);
    
    }, [userAccess])
  

  return (
    <>
      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col  h-full py-4 pb-32 transition-all duration-200 ease-in-out overflow-y-auto  shrink-0 bg-other   ${sidebarExpanded ? "w-60" : "w-10"}`}
      >
        {/* Pages group */}
        <div>
           {/* <h3 className="px-4 text-xs font-semibold uppercase text-sidebar-link opacity-70">Options</h3> */}
          {/* Links */}
          <ul className="flex flex-col gap-3 mt-3">
            
            <SidebarMultipleOptionsMenu menuOptions={menuOptions.dashboard()} sidebarExpanded={sidebarExpanded} />
            <SidebarMultipleOptionsMenu menuOptions={menuOptions.insights()} sidebarExpanded={sidebarExpanded} />
            {userAccess?.devices?.list ? <SidebarMultipleOptionsMenu menuOptions={menuOptions.devices()} sidebarExpanded={sidebarExpanded} /> : null}
            {userAccess?.simCards?.list ? <SidebarSingleOptionMenu rootPath={"sim-cards"} menuIcon={MenuIcons.SIM_CARD} menuName={"Sim Cards"} url={"/sim-cards"} sidebarExpanded={sidebarExpanded} /> : null}
            <SidebarSingleOptionMenu rootPath={"drivers"} menuIcon={MenuIcons.DRIVER} menuName={"Drivers"} url={"/drivers"} sidebarExpanded={sidebarExpanded} />
            <SidebarMultipleOptionsMenu menuOptions={menuOptions.vehicles()} sidebarExpanded={sidebarExpanded} />
            <SidebarMultipleOptionsMenu menuOptions={menuOptions.services()} sidebarExpanded={sidebarExpanded} />
            {userAccess?.users?.list ? <SidebarMultipleOptionsMenu menuOptions={menuOptions.users()} sidebarExpanded={sidebarExpanded} /> : null}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
