/* eslint-disable no-unused-vars */
 enum TableDataTypes {
  Boolean = "boolean",
  EditableBoolean = "editableBoolean",
  Button = "button",
  DateTime = "dateTime",
  DateTimeOrMinimum = "dateTimeOrMinimum",
  Date = "date",
  Duration = "duration",
  Hundredth = "hundredth",
  ImageVideoModal = "image.video.modal",
  Percentage = "percentage",
  VehicleRegistration = "vehicleRegistration"
}
/* eslint-enable no-unused-vars */
export type TableDataType = typeof TableDataTypes[keyof typeof TableDataTypes];

export default TableDataTypes;




